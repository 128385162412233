import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Paper, 
  Grid, 
  Button, 
  Avatar, 
  Divider,
  useTheme,
  Card,
  CardContent,
  CardActions,
  Chip,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Skeleton
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChatIcon from '@mui/icons-material/Chat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { signOut } from '../services/auth';
import { getOrganizerEvents, Event } from '../services/event';
import { 
  getPerformerNegotiations, 
  getOrganizerNegotiations, 
  Negotiation 
} from '../services/negotiations';
import {
  Task,
  getTasksAssignedToUser,
  TaskStatus
} from '../services/tasks';
import { format, isAfter, parseISO, differenceInDays } from 'date-fns';
import { ru } from 'date-fns/locale';

const eventTypeLabels: Record<string, string> = {
  'wedding': 'Свадьба',
  'corporate': 'Корпоратив',
  'concert': 'Концерт',
  'conference': 'Конференция',
  'party': 'Вечеринка',
  'other': 'Другое'
};

const statusLabels: Record<string, string> = {
  'draft': 'Черновик',
  'published': 'Опубликовано',
  'completed': 'Завершено',
  'cancelled': 'Отменено'
};

// Helper function for Russian plural forms
const russianPluralize = (count: number, one: string, few: string, many: string): string => {
  if (count % 10 === 1 && count % 100 !== 11) {
    return one;
  } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
    return few;
  } else {
    return many;
  }
};

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [negotiations, setNegotiations] = useState<Negotiation[]>([]);
  const [pendingNegotiations, setPendingNegotiations] = useState<Negotiation[]>([]);
  const [loadingNegotiations, setLoadingNegotiations] = useState(true);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [upcomingTasks, setUpcomingTasks] = useState<Task[]>([]);
  const [overdueTasks, setOverdueTasks] = useState<Task[]>([]);
  const [loadingTasks, setLoadingTasks] = useState(true);
  
  useEffect(() => {
    const fetchEvents = async () => {
      if (!currentUser) return;
      
      if (userData?.userType === 'organizer') {
        try {
          setLoading(true);
          const organizerEvents = await getOrganizerEvents(currentUser.uid);
          setEvents(organizerEvents);
        } catch (err: any) {
          console.error(err);
          setError('Ошибка при загрузке мероприятий');
        } finally {
          setLoading(false);
        }
      }
    };
    
    // Fetch negotiations
    const fetchNegotiations = async () => {
      if (!userData) return;
      
      try {
        setLoadingNegotiations(true);
        let fetchedNegotiations: Negotiation[] = [];
        
        if (userData.userType === 'performer') {
          fetchedNegotiations = await getPerformerNegotiations(userData.uid);
        } else {
          fetchedNegotiations = await getOrganizerNegotiations(userData.uid);
        }
        
        setNegotiations(fetchedNegotiations);
        
        // Filter pending negotiations that require action
        const pending = fetchedNegotiations.filter(n => 
          (userData.userType === 'performer' && n.status === 'pending_performer') ||
          (userData.userType === 'organizer' && n.status === 'pending_organizer')
        );
        
        setPendingNegotiations(pending);
      } catch (err) {
        console.error('Error fetching negotiations:', err);
      } finally {
        setLoadingNegotiations(false);
      }
    };
    
    // Fetch user tasks
    const fetchTasks = async () => {
      if (!currentUser) return;
      
      try {
        setLoadingTasks(true);
        const userTasks = await getTasksAssignedToUser(currentUser.uid);
        setTasks(userTasks);
        
        // Filter tasks
        const now = new Date();
        const upcoming: Task[] = [];
        const overdue: Task[] = [];
        
        userTasks.forEach(task => {
          const deadlineDate = parseISO(task.deadline);
          
          // Skip completed tasks
          if (task.status === 'completed') return;
          
          if (isAfter(now, deadlineDate)) {
            // Task is overdue
            overdue.push(task);
          } else if (differenceInDays(deadlineDate, now) <= 3) {
            // Task is due within 3 days
            upcoming.push(task);
          }
        });
        
        setUpcomingTasks(upcoming);
        setOverdueTasks(overdue);
      } catch (err) {
        console.error('Error fetching tasks:', err);
      } finally {
        setLoadingTasks(false);
      }
    };
    
    fetchEvents();
    fetchNegotiations();
    fetchTasks();
  }, [currentUser, userData]);
  
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Ошибка при выходе из системы:', error);
    }
  };
  
  const handleCreateEvent = () => {
    navigate('/events/create');
  };

  const handleBrowsePerformers = () => {
    navigate('/performers');
  };
  
  const handleBrowseOrders = () => {
    navigate('/orders');
  };
  
  const handleEventClick = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };
  
  const handleGoToNegotiations = () => {
    navigate('/negotiations');
  };
  
  const handleGoToCalendar = () => {
    navigate('/calendar');
  };
  
  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch {
      return dateString;
    }
  };
  
  const getTaskStatusColor = (status: TaskStatus): "default" | "primary" | "secondary" | "success" | "error" | "info" | "warning" => {
    switch (status) {
      case 'pending': return 'warning';
      case 'in_progress': return 'info';
      case 'completed': return 'success';
      case 'overdue': return 'error';
      default: return 'default';
    }
  };
  
  const formatTaskDeadline = (deadline: string) => {
    return format(parseISO(deadline), 'dd MMMM', { locale: ru });
  };
  
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Сайдбар с информацией о пользователе */}
          <Grid item xs={12} md={3}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3, 
                borderRadius: 2,
                height: '100%'
              }}
            >
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Avatar 
                  src={userData?.profileImageUrl} 
                  sx={{ 
                    width: 100, 
                    height: 100, 
                    mx: 'auto', 
                    mb: 2,
                    border: `4px solid ${theme.palette.primary.main}20`,
                  }}
                >
                  {!userData?.profileImageUrl && <PersonIcon fontSize="large" />}
                </Avatar>
                <Typography variant="h6" fontWeight={600}>
                  {userData?.fullName || 'Пользователь'}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                  {userData?.userType === 'organizer' ? 'Организатор' : 'Исполнитель'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userData?.location || ''}
                </Typography>
              </Box>
              
              <Divider sx={{ my: 2 }} />
              
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" fontWeight={500} sx={{ mb: 0.5 }}>
                  Email:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {currentUser?.email}
                </Typography>
              </Box>
              
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" fontWeight={500} sx={{ mb: 0.5 }}>
                  Телефон:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userData?.phone || 'Не указан'}
                </Typography>
              </Box>
              
              {userData?.userType === 'performer' && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" fontWeight={500} sx={{ mb: 0.5 }}>
                    Категория услуг:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {userData?.serviceCategory || 'Не указана'}
                  </Typography>
                </Box>
              )}
              
              <Button
                variant="outlined"
                color="error"
                fullWidth
                startIcon={<LogoutIcon />}
                onClick={handleSignOut}
                sx={{ mt: 2 }}
              >
                Выйти
              </Button>
            </Paper>
          </Grid>
          
          {/* Основной контент дашборда */}
          <Grid item xs={12} md={9}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h4" component="h1" fontWeight={700} sx={{ mb: 1 }}>
                Добро пожаловать, {userData?.fullName?.split(' ')[0] || 'Пользователь'}!
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {userData?.userType === 'organizer' 
                  ? 'Управляйте вашими мероприятиями и находите исполнителей'
                  : 'Управляйте запросами и следите за предстоящими мероприятиями'
                }
              </Typography>
            </Box>
            
            {/* Dashboard actions */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {userData?.userType === 'organizer' && (
                <>
                  <Grid item xs={12} md={6} lg={3}>
                    <Paper
                      sx={{
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        borderRadius: 2,
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)'
                        }
                      }}
                    >
                      <Box
                        sx={{
                          width: 60,
                          height: 60,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText',
                          mb: 2
                        }}
                      >
                        <AddIcon fontSize="large" />
                      </Box>
                      <Typography variant="h6" gutterBottom>
                        Создать мероприятие
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                        Создайте новое мероприятие и найдите исполнителей
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateEvent}
                        fullWidth
                      >
                        Создать
                      </Button>
                    </Paper>
                  </Grid>
                  
                  <Grid item xs={12} md={6} lg={3}>
                    <Paper
                      sx={{
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        borderRadius: 2,
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)'
                        }
                      }}
                    >
                      <Box
                        sx={{
                          width: 60,
                          height: 60,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                          backgroundColor: 'secondary.main',
                          color: 'secondary.contrastText',
                          mb: 2
                        }}
                      >
                        <SearchIcon fontSize="large" />
                      </Box>
                      <Typography variant="h6" gutterBottom>
                        Исполнители
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                        Просмотрите всех исполнителей и их услуги
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleBrowsePerformers}
                        fullWidth
                      >
                        Просмотреть
                      </Button>
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
            
            {userData?.userType === 'organizer' ? (
              // Контент для организатора
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight={600} sx={{ mt: 2, mb: 3 }}>
                    Ваши мероприятия
                  </Typography>
                  
                  {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                      <CircularProgress />
                    </Box>
                  ) : error ? (
                    <Alert severity="error" sx={{ mb: 3 }}>
                      {error}
                    </Alert>
                  ) : events.length === 0 ? (
                    <Typography color="textSecondary" sx={{ textAlign: 'center', py: 4 }}>
                      У вас пока нет созданных мероприятий
                    </Typography>
                  ) : (
                    <Grid container spacing={3}>
                      {events.map((event) => (
                        <Grid item xs={12} sm={6} key={event.id}>
                          <Card 
                            sx={{ 
                              borderRadius: 2, 
                              boxShadow: 1,
                              transition: 'all 0.2s ease-in-out',
                              '&:hover': {
                                boxShadow: 4,
                                transform: 'translateY(-4px)'
                              },
                              cursor: 'pointer'
                            }}
                            onClick={() => event.id && handleEventClick(event.id)}
                          >
                            <CardContent sx={{ p: 3 }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                                <Typography variant="h6" component="h3" fontWeight={600}>
                                  {event.title}
                                </Typography>
                                <Chip 
                                  label={statusLabels[event.status] || event.status} 
                                  color={
                                    event.status === 'published' ? 'primary' : 
                                    event.status === 'completed' ? 'success' : 
                                    event.status === 'cancelled' ? 'error' : 'default'
                                  }
                                  size="small"
                                />
                              </Box>
                              
                              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                {eventTypeLabels[event.eventType] || event.eventType}
                              </Typography>
                              
                              <Divider sx={{ my: 2 }} />
                              
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color="textSecondary">
                                    Дата:
                                  </Typography>
                                  <Typography variant="body2" fontWeight={500}>
                                    {formatDate(event.date)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" color="textSecondary">
                                    Бюджет:
                                  </Typography>
                                  <Typography variant="body2" fontWeight={500} color="primary">
                                    {event.totalBudget.toLocaleString()} ₽
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                            <CardActions sx={{ px: 3, pb: 3, pt: 0 }}>
                              <Button size="small" color="primary">
                                Подробнее
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              // Контент для исполнителя
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card sx={{ borderRadius: 2, boxShadow: 2 }}>
                    <CardContent sx={{ p: 3 }}>
                      <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                        <EventIcon color="primary" sx={{ mr: 2, fontSize: 36 }} />
                        <Typography variant="h5" component="h2" fontWeight={600}>
                          Доступные мероприятия
                        </Typography>
                      </Box>
                      <Typography sx={{ mb: 3 }}>
                        Просмотрите доступные мероприятия и отправьте заявку на участие.
                      </Typography>
                      <Button 
                        variant="contained" 
                        color="primary"
                        sx={{ px: 3, py: 1 }}
                        onClick={handleBrowseOrders}
                      >
                        Найти мероприятия
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight={600} sx={{ mt: 2, mb: 3 }}>
                    Мои заявки
                  </Typography>
                  
                  <Typography color="textSecondary" sx={{ textAlign: 'center', py: 4 }}>
                    У вас пока нет активных заявок
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        
        <Grid container spacing={3} sx={{ mt: 4 }}>
          {/* Negotiations Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)'
                }
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Typography variant="h5" component="h2" gutterBottom>
                    Переговоры
                  </Typography>
                  {pendingNegotiations.length > 0 && (
                    <Chip 
                      color="error" 
                      size="small" 
                      label={`${pendingNegotiations.length} новых`}
                    />
                  )}
                </Box>
                <Typography variant="body1" color="text.secondary" paragraph>
                  {userData?.userType === 'organizer'
                    ? 'Просмотр и управление переговорами с исполнителями'
                    : 'Просмотр и управление предложениями от организаторов'}
                </Typography>
                <List>
                  {loadingNegotiations ? (
                    <Skeleton animation="wave" height={35} />
                  ) : negotiations.length === 0 ? (
                    <Typography variant="body2" color="text.secondary" fontStyle="italic">
                      У вас пока нет активных переговоров
                    </Typography>
                  ) : (
                    <>
                      <ListItem disablePadding>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                          <Badge 
                            color="error" 
                            badgeContent={pendingNegotiations.length} 
                            showZero={false}
                          >
                            <ChatIcon fontSize="small" />
                          </Badge>
                        </ListItemIcon>
                        <ListItemText 
                          primary={`${negotiations.length} ${russianPluralize(negotiations.length, 'переговор', 'переговора', 'переговоров')}`} 
                        />
                      </ListItem>
                    </>
                  )}
                </List>
              </CardContent>
              <CardActions>
                <Button 
                  fullWidth 
                  color="primary" 
                  onClick={handleGoToNegotiations}
                  startIcon={<ArrowForwardIcon />}
                >
                  К переговорам
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Calendar Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)'
                }
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Typography variant="h5" component="h2" gutterBottom>
                    Календарь
                  </Typography>
                </Box>
                <Typography variant="body1" color="text.secondary" paragraph>
                  {userData?.userType === 'organizer'
                    ? 'Планируйте этапы подготовки мероприятий и важные даты'
                    : 'Управляйте расписанием и напоминаниями'}
                </Typography>
                <List>
                  <ListItem disablePadding>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <CalendarMonthIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Важные даты и напоминания" 
                    />
                  </ListItem>
                </List>
              </CardContent>
              <CardActions>
                <Button 
                  fullWidth 
                  color="primary" 
                  onClick={handleGoToCalendar}
                  startIcon={<ArrowForwardIcon />}
                >
                  К календарю
                </Button>
              </CardActions>
            </Card>
          </Grid>
          
          {/* Tasks Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)'
                }
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Typography variant="h5" component="h2" gutterBottom>
                    Задачи
                  </Typography>
                  {overdueTasks.length > 0 && (
                    <Chip 
                      color="error" 
                      size="small" 
                      label={`${overdueTasks.length} просрочено`}
                    />
                  )}
                </Box>
                <Typography variant="body1" color="text.secondary" paragraph>
                  {userData?.userType === 'organizer'
                    ? 'Управляйте задачами и дедлайнами по мероприятиям'
                    : 'Отслеживайте задачи и дедлайны от организаторов'}
                </Typography>
                
                {loadingTasks ? (
                  <Skeleton animation="wave" height={120} />
                ) : (overdueTasks.length === 0 && upcomingTasks.length === 0) ? (
                  <Typography variant="body2" color="text.secondary" fontStyle="italic">
                    У вас нет срочных задач на ближайшее время
                  </Typography>
                ) : (
                  <List>
                    {overdueTasks.slice(0, 2).map(task => (
                      <ListItem 
                        key={task.id} 
                        disablePadding 
                        sx={{ 
                          mb: 1, 
                          backgroundColor: 'error.light',
                          borderRadius: 1,
                          px: 1
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 30 }}>
                          <AssignmentLateIcon color="error" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            <Typography variant="body2" fontWeight={500}>
                              {task.title}
                            </Typography>
                          }
                          secondary={`Просрочено: ${formatTaskDeadline(task.deadline)}`}
                        />
                      </ListItem>
                    ))}
                    
                    {upcomingTasks.slice(0, 2 - Math.min(overdueTasks.length, 2)).map(task => (
                      <ListItem 
                        key={task.id} 
                        disablePadding 
                        sx={{ 
                          mb: 1,
                          backgroundColor: 'warning.light',
                          borderRadius: 1,
                          px: 1
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 30 }}>
                          <AssignmentIcon color="warning" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            <Typography variant="body2" fontWeight={500}>
                              {task.title}
                            </Typography>
                          }
                          secondary={`Дедлайн: ${formatTaskDeadline(task.deadline)}`}
                        />
                      </ListItem>
                    ))}
                    
                    {(overdueTasks.length > 2 || upcomingTasks.length > (2 - Math.min(overdueTasks.length, 2))) && (
                      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                        +{overdueTasks.length + upcomingTasks.length - Math.min(overdueTasks.length + upcomingTasks.length, 2)} еще
                      </Typography>
                    )}
                  </List>
                )}
              </CardContent>
              <CardActions>
                <Button 
                  fullWidth 
                  color="primary" 
                  onClick={handleGoToCalendar}
                  startIcon={<ArrowForwardIcon />}
                >
                  К задачам и календарю
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard; 