import { UserData } from '../services/auth';

// Sample performers for the "videography" category
export const videographyPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "cinema.pro@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Алексей Киномастер",
    phone: "+7 (903) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1601506521937-0121a7fc2a6b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный видеограф с 15-летним опытом работы в кино и рекламе. Снимаю свадьбы, корпоративные события и рекламные ролики. Использую профессиональное оборудование и креативный подход для создания уникальных видео.",
    location: "Москва",
    website: "https://cinemapro.ru",
    serviceCategory: "videography",
    priceRange: "50000-150000",
    skills: ["Художественная видеосъемка", "Монтаж", "Аэросъемка", "Цветокоррекция", "Свадебное видео", "Рекламные ролики"],
    portfolioLinks: [
      "https://vimeo.com/cinemapro",
      "https://www.youtube.com/channel/cinemapro"
    ],
    certificateUrls: [
      "https://example.com/certificates/film-academy.jpg"
    ]
  },
  {
    email: "wedding.video@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Екатерина и Михаил Свадебные",
    phone: "+7 (916) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1534957753291-40e68c147bfd?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Команда свадебных видеографов с опытом более 100 свадеб. Создаем чувственные, эмоциональные фильмы о вашем дне. Работаем в паре, что позволяет запечатлеть все важные моменты с разных ракурсов. Индивидуальный подход к каждой паре.",
    location: "Москва",
    website: "https://wedding-video.ru",
    serviceCategory: "videography",
    priceRange: "80000-180000",
    skills: ["Свадебное видео", "Двухкамерная съемка", "Love story", "Монтаж", "Съемка с воздуха", "SDE (Same Day Edit)"],
    portfolioLinks: [
      "https://vimeo.com/weddingvideoru",
      "https://www.instagram.com/wedding.video.ru"
    ],
    certificateUrls: []
  },
  {
    email: "drone.master@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Сергей Высотин",
    phone: "+7 (925) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1533000971552-6a962ff0b9f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специалист по аэровидеосъемке с использованием профессиональных дронов. Создаю потрясающие виды с высоты для мероприятий, рекламы недвижимости и туристических локаций. Наличие всех необходимых лицензий и разрешений.",
    location: "Москва",
    website: "https://drone-master.ru",
    serviceCategory: "videography",
    priceRange: "20000-60000",
    skills: ["Аэросъемка", "Съемка с дрона", "4K видео", "Реклама недвижимости", "Съемка мероприятий с воздуха", "Монтаж"],
    portfolioLinks: [
      "https://www.youtube.com/channel/drone-master",
      "https://drone-master.ru/portfolio"
    ],
    certificateUrls: [
      "https://example.com/certificates/drone-operator-license.jpg",
      "https://example.com/certificates/aerial-filming.jpg"
    ]
  },
  {
    email: "corporate.video@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Бизнес Видео Продакшн",
    phone: "+7 (903) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1484755560615-a4c64e778a6c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональная студия корпоративного видеопроизводства. Создаем презентационные, обучающие, имиджевые фильмы и рекламные ролики для бизнеса. Полный цикл от разработки сценария до постпродакшна.",
    location: "Москва",
    website: "https://business-video.ru",
    serviceCategory: "videography",
    priceRange: "100000-500000",
    skills: ["Корпоративное видео", "Рекламные ролики", "Презентационные фильмы", "Интервью", "Обучающее видео", "Постпродакшн"],
    portfolioLinks: [
      "https://business-video.ru/cases",
      "https://vimeo.com/businessvideo"
    ],
    certificateUrls: []
  },
  {
    email: "event.video@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Игорь Событийный",
    phone: "+7 (915) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1570098693164-4cdcd6c8b4a1?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Видеограф с 10-летним опытом съемки событий. Специализируюсь на корпоративных мероприятиях, конференциях, концертах и презентациях. Многокамерная съемка, прямые трансляции, быстрый монтаж и выдача материала.",
    location: "Москва",
    website: "https://event-video.ru",
    serviceCategory: "videography",
    priceRange: "30000-90000",
    skills: ["Событийная съемка", "Многокамерная съемка", "Прямые трансляции", "Конференции", "Концерты", "Корпоративные мероприятия"],
    portfolioLinks: [
      "https://event-video.ru/works",
      "https://www.youtube.com/channel/eventvideo"
    ],
    certificateUrls: [
      "https://example.com/certificates/live-streaming.jpg"
    ]
  },
  {
    email: "art.film@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Мария Артфильм",
    phone: "+7 (926) 678-9012",
    profileImageUrl: "https://images.unsplash.com/photo-1485846234645-a62644f84728?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Режиссер и видеограф, создающий авторское художественное видео. Специализируюсь на концептуальных и стилизованных съемках. Работаю с модельными агентствами, дизайнерами и брендами для создания уникального визуального контента.",
    location: "Санкт-Петербург",
    website: "https://art-film.ru",
    serviceCategory: "videography",
    priceRange: "70000-200000",
    skills: ["Художественное видео", "Модная съемка", "Видеоарт", "Режиссура", "Концептуальные клипы", "Стилизация"],
    portfolioLinks: [
      "https://vimeo.com/artfilm",
      "https://www.instagram.com/maria.artfilm"
    ],
    certificateUrls: [
      "https://example.com/certificates/film-directing.jpg"
    ]
  },
  {
    email: "music.video@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Дмитрий Клипмейкер",
    phone: "+7 (917) 789-0123",
    profileImageUrl: "https://images.unsplash.com/photo-1587614381634-68e6136c95b5?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Режиссер и оператор музыкальных клипов. Работаю с исполнителями всех жанров от инди до поп-музыки. Создал более 50 клипов для известных артистов. Полный цикл производства от идеи до финального монтажа и цветокоррекции.",
    location: "Москва",
    website: "https://music-video.ru",
    serviceCategory: "videography",
    priceRange: "100000-350000",
    skills: ["Музыкальные клипы", "Режиссура", "Операторская работа", "Постпродакшн", "Работа с артистами", "Визуальные эффекты"],
    portfolioLinks: [
      "https://music-video.ru/works",
      "https://vimeo.com/musicvideoru"
    ],
    certificateUrls: []
  },
  {
    email: "travel.film@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Николай Путешественник",
    phone: "+7 (919) 890-1234",
    profileImageUrl: "https://images.unsplash.com/photo-1531817681549-536ce9338382?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Создаю вдохновляющие travel-видео, документальные фильмы о путешествиях и культурах. Работаю с туристическими компаниями, отелями и курортами для продвижения направлений. Снимал более чем в 30 странах мира.",
    location: "Москва",
    website: "https://travel-film.ru",
    serviceCategory: "videography",
    priceRange: "50000-150000",
    skills: ["Travel-видео", "Документальная съемка", "Туристические ролики", "Аэросъемка", "Приключенческие фильмы", "Таймлапс"],
    portfolioLinks: [
      "https://travel-film.ru/destinations",
      "https://www.youtube.com/channel/travelfilm"
    ],
    certificateUrls: [
      "https://example.com/certificates/documentary-filming.jpg"
    ]
  },
  {
    email: "vfx.studio@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "VFX Студия 'Эффект'",
    phone: "+7 (920) 901-2345",
    profileImageUrl: "https://images.unsplash.com/photo-1578022761797-b8636ac1773c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Студия визуальных эффектов и компьютерной графики. Создаем 3D-анимацию, CGI, визуальные эффекты для видео, рекламы и презентаций. Работаем с компаниями от стартапов до крупных брендов.",
    location: "Москва",
    website: "https://vfx-studio.ru",
    serviceCategory: "videography",
    priceRange: "70000-300000",
    skills: ["Визуальные эффекты", "3D-анимация", "CGI", "Моушн-дизайн", "Постпродакшн", "Композитинг"],
    portfolioLinks: [
      "https://vfx-studio.ru/portfolio",
      "https://vimeo.com/vfxstudio"
    ],
    certificateUrls: []
  },
  {
    email: "kids.video@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Марина Детская",
    phone: "+7 (921) 012-3456",
    profileImageUrl: "https://images.unsplash.com/photo-1516627145497-ae6968895b40?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специализируюсь на видеосъемке детских мероприятий: выпускные, утренники, дни рождения, семейные праздники. Имею педагогическое образование и опыт работы с детьми, что помогает найти подход к каждому ребенку во время съемки.",
    location: "Санкт-Петербург",
    website: "https://kids-video.ru",
    serviceCategory: "videography",
    priceRange: "15000-40000",
    skills: ["Детская видеосъемка", "Выпускные", "Дни рождения", "Утренники", "Семейное видео", "Детские интервью"],
    portfolioLinks: [
      "https://kids-video.ru/gallery",
      "https://www.youtube.com/channel/kidsvideo"
    ],
    certificateUrls: [
      "https://example.com/certificates/pedagogy-diploma.jpg"
    ]
  }
]; 