import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Paper, 
  Stepper,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Divider,
  InputAdornment,
  Alert,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { createEvent, EventType, ServiceType, EventService } from '../services/event';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const eventTypes: { value: EventType; label: string }[] = [
  { value: 'wedding', label: 'Свадьба' },
  { value: 'corporate', label: 'Корпоратив' },
  { value: 'concert', label: 'Концерт' },
  { value: 'conference', label: 'Конференция' },
  { value: 'party', label: 'Вечеринка' },
  { value: 'other', label: 'Другое' }
];

const serviceTypes: { value: ServiceType; label: string }[] = [
  { value: 'venue', label: 'Площадки и помещения' },
  { value: 'catering', label: 'Кейтеринг и обслуживание' },
  { value: 'photography', label: 'Фото' },
  { value: 'videography', label: 'Видеосъемка' },
  { value: 'music', label: 'Музыка/DJ' },
  { value: 'decoration', label: 'Декор и оформление' },
  { value: 'entertainment', label: 'Ведущие и аниматоры' },
  { value: 'transport', label: 'Транспорт' },
  { value: 'technical', label: 'Техническое обеспечение' },
  { value: 'other', label: 'Другое' }
];

const CreateEvent: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  // Step 1: Basic event details
  const [title, setTitle] = useState('');
  const [eventType, setEventType] = useState<EventType | ''>('');
  const [description, setDescription] = useState('');
  
  // Step 2: Date, time, location
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [location, setLocation] = useState('');
  
  // Step 3: Budget and services
  const [totalBudget, setTotalBudget] = useState<number>(0);
  const [services, setServices] = useState<EventService[]>([
    { type: 'venue', description: '', budget: 0, isBooked: false }
  ]);
  
  const steps = [
    'Основная информация',
    'Дата и место',
    'Бюджет и услуги'
  ];

  const validateStep = () => {
    switch (activeStep) {
      case 0:
        return title && eventType && description;
      case 1:
        return date && time && location;
      case 2:
        return totalBudget > 0 && services.every(service => service.type && service.description && service.budget > 0);
      default:
        return false;
    }
  };

  const handleNext = () => {
    if (validateStep()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError('');
    } else {
      setError('Пожалуйста, заполните все обязательные поля');
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError('');
  };

  const handleAddService = () => {
    setServices([...services, { type: 'venue', description: '', budget: 0, isBooked: false }]);
  };

  const handleRemoveService = (index: number) => {
    setServices(services.filter((_, i) => i !== index));
  };

  const handleServiceChange = (index: number, field: keyof EventService, value: any) => {
    const newServices = [...services];
    newServices[index] = { ...newServices[index], [field]: value };
    setServices(newServices);
    
    // Recalculate total budget when a service budget changes
    if (field === 'budget') {
      const total = newServices.reduce((sum, service) => sum + service.budget, 0);
      setTotalBudget(total);
    }
  };

  const handleSubmit = async () => {
    if (!currentUser) {
      setError('Вы должны быть авторизованы для создания мероприятия');
      return;
    }
    
    try {
      setLoading(true);
      setError('');
      
      const eventData = {
        organizerId: currentUser.uid,
        title,
        eventType: eventType as EventType,
        description,
        date,
        time,
        location,
        totalBudget,
        services,
        status: 'draft' as const
      };
      
      const eventId = await createEvent(eventData);
      navigate(`/events/${eventId}`);
    } catch (err: any) {
      console.error(err);
      setError('Ошибка при создании мероприятия. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        py: 6,
        backgroundColor: theme.palette.background.default
      }}
    >
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <Typography 
              variant="h4" 
              component="h1"
              sx={{ 
                fontWeight: 700,
                mb: 1
              }}
            >
              Создание мероприятия
            </Typography>
            <Typography color="textSecondary">
              Заполните данные о вашем мероприятии по шагам
            </Typography>
          </Box>

          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {error && (
            <Alert severity="error" sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}

          <Box>
            {activeStep === 0 && (
              // Шаг 1: Основная информация
              <Box>
                <TextField
                  label="Название мероприятия *"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  sx={{ mb: 3 }}
                />
                
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel id="event-type-label">Тип мероприятия *</InputLabel>
                  <Select
                    labelId="event-type-label"
                    value={eventType}
                    label="Тип мероприятия *"
                    onChange={(e) => setEventType(e.target.value as EventType)}
                    required
                  >
                    {eventTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
                <TextField
                  label="Описание мероприятия *"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  sx={{ mb: 3 }}
                  placeholder="Опишите ваше мероприятие, цели, масштаб и другие важные детали..."
                />
              </Box>
            )}

            {activeStep === 1 && (
              // Шаг 2: Дата, время, местоположение
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Дата мероприятия *"
                      variant="outlined"
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Время начала *"
                      variant="outlined"
                      fullWidth
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      required
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                </Grid>
                
                <TextField
                  label="Место проведения *"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required
                  sx={{ mb: 3 }}
                  placeholder="Адрес или название площадки"
                />
              </Box>
            )}

            {activeStep === 2 && (
              // Шаг 3: Бюджет и услуги
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Услуги для мероприятия
                </Typography>
                
                {services.map((service, index) => (
                  <Paper 
                    key={index} 
                    sx={{ 
                      p: 2, 
                      mb: 3, 
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                      <Typography variant="subtitle1" fontWeight={500}>
                        Услуга {index + 1}
                      </Typography>
                      {index > 0 && (
                        <IconButton 
                          size="small" 
                          color="error" 
                          onClick={() => handleRemoveService(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel id={`service-type-label-${index}`}>Тип услуги *</InputLabel>
                          <Select
                            labelId={`service-type-label-${index}`}
                            value={service.type}
                            label="Тип услуги *"
                            onChange={(e) => handleServiceChange(index, 'type', e.target.value as ServiceType)}
                            required
                          >
                            {serviceTypes.map((type) => (
                              <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Бюджет (руб.) *"
                          variant="outlined"
                          fullWidth
                          type="number"
                          value={service.budget || ''}
                          onChange={(e) => handleServiceChange(index, 'budget', Number(e.target.value))}
                          required
                          sx={{ mb: 2 }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid>
                    
                    <TextField
                      label="Описание услуги *"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                      value={service.description}
                      onChange={(e) => handleServiceChange(index, 'description', e.target.value)}
                      required
                      placeholder="Опишите требования к услуге..."
                    />
                  </Paper>
                ))}
                
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddService}
                  sx={{ mb: 3 }}
                >
                  Добавить еще услугу
                </Button>
                
                <Divider sx={{ my: 3 }} />
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">
                    Общий бюджет:
                  </Typography>
                  <Typography variant="h6" color="primary" fontWeight={700}>
                    {totalBudget.toLocaleString()} ₽
                  </Typography>
                </Box>
                
                <Typography variant="body2" color="textSecondary" paragraph sx={{ mt: 2 }}>
                  После создания мероприятия вы сможете увидеть рекомендуемых исполнителей для каждой услуги.
                </Typography>
              </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              <Button
                onClick={handleBack}
                disabled={activeStep === 0}
                variant="outlined"
              >
                Назад
              </Button>
              <Box>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading || !validateStep()}
                  >
                    {loading ? "Создание..." : "Создать мероприятие"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    Далее
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default CreateEvent; 