import { UserData } from '../services/auth';

// Sample performers for the "other" category
export const otherPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "transport.service@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Транспортная Компания 'ВИП Трансфер'",
    phone: "+7 (495) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1550355291-bbee04a92027?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Предоставляем транспортные услуги для мероприятий любого масштаба. Премиальные автомобили, микроавтобусы, автобусы для гостей. Свадебный кортеж, трансфер из аэропорта, шаттлы между площадками. Профессиональные водители, современный автопарк.",
    location: "Москва",
    website: "https://vip-transfer.ru",
    serviceCategory: "other",
    priceRange: "5000-50000",
    skills: ["Транспортные услуги", "Премиум-автомобили", "Свадебный кортеж", "Трансфер гостей", "Аренда автобусов", "VIP-сервис"],
    portfolioLinks: [
      "https://vip-transfer.ru/fleet",
      "https://www.instagram.com/vip.transfer"
    ],
    certificateUrls: [
      "https://example.com/certificates/transportation-license.jpg"
    ]
  },
  {
    email: "security.service@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Охранное Агентство 'Щит'",
    phone: "+7 (495) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1541199249251-f713e6145474?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Обеспечиваем безопасность мероприятий любого уровня. Профессиональные охранники, контроль доступа, видеонаблюдение, охрана VIP-персон. Работаем на мероприятиях от 50 до 10 000 человек. Необходимые разрешения и лицензии имеются.",
    location: "Москва",
    website: "https://shield-security.ru",
    serviceCategory: "other",
    priceRange: "10000-100000",
    skills: ["Охрана мероприятий", "Контроль доступа", "VIP-охрана", "Видеонаблюдение", "Безопасность массовых мероприятий", "Антитеррористическая защита"],
    portfolioLinks: [
      "https://shield-security.ru/services",
      "https://shield-security.ru/events"
    ],
    certificateUrls: [
      "https://example.com/certificates/security-license.jpg",
      "https://example.com/certificates/emergency-training.jpg"
    ]
  },
  {
    email: "cleaning.service@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Клининговая Компания 'Чистый Мир'",
    phone: "+7 (495) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1581578731548-c64695cc6952?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Предоставляем клининговые услуги до, во время и после мероприятий. Подготовка площадки, поддержание чистоты во время события, генеральная уборка после завершения. Работаем с площадками любого размера - от небольших залов до стадионов.",
    location: "Москва",
    website: "https://clean-world.ru",
    serviceCategory: "other",
    priceRange: "5000-100000",
    skills: ["Клининговые услуги", "Подготовка площадки", "Уборка после мероприятия", "Экспресс-уборка", "Химчистка", "Мытье фасадов и окон"],
    portfolioLinks: [
      "https://clean-world.ru/services",
      "https://clean-world.ru/clients"
    ],
    certificateUrls: [
      "https://example.com/certificates/eco-cleaning.jpg"
    ]
  },
  {
    email: "tech.support@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Техническая Поддержка 'ТехноСервис'",
    phone: "+7 (495) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Техническое обеспечение мероприятий: звуковое и световое оборудование, видеопроекция, LED-экраны, спецэффекты. Профессиональные инженеры и техники, современное оборудование. Опыт работы с мероприятиями любого масштаба.",
    location: "Москва",
    website: "https://techno-service.ru",
    serviceCategory: "other",
    priceRange: "20000-300000",
    skills: ["Техническое обеспечение", "Звуковое оборудование", "Световое оборудование", "Видеопроекция", "LED-экраны", "Спецэффекты"],
    portfolioLinks: [
      "https://techno-service.ru/equipment",
      "https://techno-service.ru/projects"
    ],
    certificateUrls: [
      "https://example.com/certificates/sound-engineer.jpg",
      "https://example.com/certificates/lighting-technician.jpg"
    ]
  },
  {
    email: "makeup.hair@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Студия Красоты 'Имидж'",
    phone: "+7 (495) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1487412947147-5cebf100ffc2?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Команда профессиональных визажистов и стилистов по волосам. Подготовка к фотосессиям, свадебный макияж и прически, создание образов для тематических мероприятий. Работаем как на площадке нашей студии, так и с выездом на мероприятие.",
    location: "Москва",
    website: "https://image-studio.ru",
    serviceCategory: "other",
    priceRange: "3000-20000",
    skills: ["Макияж", "Прически", "Свадебный образ", "Подготовка к фотосессии", "Тематические образы", "Консультации по стилю"],
    portfolioLinks: [
      "https://image-studio.ru/gallery",
      "https://www.instagram.com/image.studio"
    ],
    certificateUrls: [
      "https://example.com/certificates/makeup-artist.jpg",
      "https://example.com/certificates/hair-stylist.jpg"
    ]
  },
  {
    email: "gifting.service@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Подарочный Сервис 'Сюрприз'",
    phone: "+7 (495) 678-9012",
    profileImageUrl: "https://images.unsplash.com/photo-1513885535751-8b9238bd345a?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Создаем корпоративные подарки, презенты для гостей мероприятий, свадебные бонбоньерки. Индивидуальный дизайн, брендирование, необычные решения. Подберем или создадим подарки под любой бюджет и концепцию мероприятия.",
    location: "Москва",
    website: "https://surprise-gifts.ru",
    serviceCategory: "other",
    priceRange: "10000-200000",
    skills: ["Корпоративные подарки", "Сувенирная продукция", "Брендирование", "Подарки гостям", "Свадебные бонбоньерки", "Индивидуальный дизайн"],
    portfolioLinks: [
      "https://surprise-gifts.ru/catalog",
      "https://www.instagram.com/surprise.gifts"
    ],
    certificateUrls: []
  },
  {
    email: "interpreters@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Бюро Переводов 'ЛингваПро'",
    phone: "+7 (495) 789-0123",
    profileImageUrl: "https://images.unsplash.com/photo-1502980426475-b83966705988?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Предоставляем услуги синхронного и последовательного перевода на мероприятиях. Профессиональные переводчики с опытом работы на международных конференциях, деловых встречах, выставках. Более 30 языков, включая редкие.",
    location: "Москва",
    website: "https://lingva-pro.ru",
    serviceCategory: "other",
    priceRange: "10000-50000",
    skills: ["Синхронный перевод", "Последовательный перевод", "Перевод документации", "Международные мероприятия", "Деловые переговоры", "Многоязычные конференции"],
    portfolioLinks: [
      "https://lingva-pro.ru/services",
      "https://lingva-pro.ru/clients"
    ],
    certificateUrls: [
      "https://example.com/certificates/translation-license.jpg"
    ]
  },
  {
    email: "photo.booth@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Фотобудка 'Моментальное Фото'",
    phone: "+7 (495) 890-1234",
    profileImageUrl: "https://images.unsplash.com/photo-1530999811698-287737f18947?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Предоставляем аренду фотобудок, инстапринтеров, селфи-зеркал на мероприятия. Брендирование фотозоны и снимков, реквизит для фото, создание анимаций и GIF. Моментальная печать, отправка на email, интеграция с соцсетями.",
    location: "Москва",
    website: "https://instant-photo.ru",
    serviceCategory: "other",
    priceRange: "15000-50000",
    skills: ["Фотобудка", "Инстапринтер", "Селфи-зеркало", "Брендирование", "Печать фотографий", "Фото-реквизит"],
    portfolioLinks: [
      "https://instant-photo.ru/equipment",
      "https://www.instagram.com/instant.photo"
    ],
    certificateUrls: []
  },
  {
    email: "drone.filming@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Аэросъемка 'Высокий Полет'",
    phone: "+7 (495) 901-2345",
    profileImageUrl: "https://images.unsplash.com/photo-1591489378430-ef2f4c626b35?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специализируемся на аэросъемке мероприятий с использованием профессиональных дронов. Потрясающие обзорные планы, динамичные пролеты, съемка масштабных событий. Все необходимые разрешения для полетов в городской черте имеются.",
    location: "Москва",
    website: "https://high-flight.ru",
    serviceCategory: "other",
    priceRange: "15000-70000",
    skills: ["Аэросъемка", "Дрон", "Видео с воздуха", "Обзорные планы", "Панорамные съемки", "Фотографии с высоты"],
    portfolioLinks: [
      "https://high-flight.ru/portfolio",
      "https://www.youtube.com/channel/highflight"
    ],
    certificateUrls: [
      "https://example.com/certificates/drone-pilot.jpg",
      "https://example.com/certificates/aviation-permit.jpg"
    ]
  },
  {
    email: "childcare@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Детская Комната 'Счастливый Малыш'",
    phone: "+7 (495) 012-3456",
    profileImageUrl: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Организуем детские комнаты и присмотр за детьми на мероприятиях. Профессиональные педагоги и аниматоры, развивающие игры, творческие мастер-классы, мультфильмы. Создаем безопасное и интересное пространство для детей разных возрастов.",
    location: "Москва",
    website: "https://happy-child.ru",
    serviceCategory: "other",
    priceRange: "10000-50000",
    skills: ["Детская комната", "Присмотр за детьми", "Развивающие игры", "Мастер-классы для детей", "Анимация", "Безопасность детей"],
    portfolioLinks: [
      "https://happy-child.ru/services",
      "https://www.instagram.com/happy.child.room"
    ],
    certificateUrls: [
      "https://example.com/certificates/pedagogy.jpg",
      "https://example.com/certificates/child-psychology.jpg",
      "https://example.com/certificates/first-aid.jpg"
    ]
  }
]; 