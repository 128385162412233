import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Paper, 
  Link as MuiLink,
  Alert,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  Divider
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser, UserType } from '../services/auth';
import BusinessIcon from '@mui/icons-material/Business';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';

const Register: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState<UserType | null>(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUserTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    newUserType: UserType | null,
  ) => {
    setUserType(newUserType);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || !password || !confirmPassword) {
      setError('Пожалуйста, заполните все поля');
      return;
    }
    
    if (password !== confirmPassword) {
      setError('Пароли не совпадают');
      return;
    }
    
    if (!userType) {
      setError('Пожалуйста, выберите тип аккаунта');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      
      await registerUser(email, password, userType);
      
      // Перенаправляем на соответствующую страницу настройки профиля
      const redirectPath = userType === 'organizer' 
        ? '/profile-setup/organizer'
        : '/profile-setup/performer';
      
      navigate(redirectPath);
    } catch (err: any) {
      console.error(err);
      setError('Ошибка при регистрации. Возможно, этот email уже используется.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 6,
        backgroundImage: 'url("https://images.unsplash.com/photo-1492684223066-81342ee5ff30?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 0
        }
      }}
    >
      <Container maxWidth="sm" sx={{ position: 'relative', zIndex: 1 }}>
        <Paper
          elevation={6}
          sx={{
            p: 4,
            borderRadius: 3,
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)'
          }}
        >
          <Box sx={{ mb: 3, textAlign: 'center' }}>
            <Typography 
              variant="h4" 
              component="h1"
              sx={{ 
                fontWeight: 700,
                background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Регистрация
            </Typography>
            <Typography color="textSecondary" sx={{ mt: 1 }}>
              Создайте новый аккаунт
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ mb: 4 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                Выберите тип аккаунта:
              </Typography>
              
              <ToggleButtonGroup
                color="primary"
                value={userType}
                exclusive
                onChange={handleUserTypeChange}
                fullWidth
                sx={{ mb: 2 }}
              >
                <ToggleButton 
                  value="organizer" 
                  sx={{ 
                    py: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    '&.Mui-selected': {
                      backgroundColor: `${theme.palette.primary.main}15`,
                    }
                  }}
                >
                  <BusinessIcon />
                  <Typography variant="body2">Организатор</Typography>
                </ToggleButton>
                <ToggleButton 
                  value="performer" 
                  sx={{ 
                    py: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    '&.Mui-selected': {
                      backgroundColor: `${theme.palette.primary.main}15`,
                    }
                  }}
                >
                  <MicExternalOnIcon />
                  <Typography variant="body2">Исполнитель</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
              
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1, fontStyle: 'italic' }}>
                {userType === 'organizer' 
                  ? 'Организаторы создают мероприятия и нанимают исполнителей'
                  : userType === 'performer'
                    ? 'Исполнители предоставляют услуги и получают заказы от организаторов'
                    : 'Выберите тип аккаунта в зависимости от ваших целей'}
              </Typography>
            </Box>
            
            <Divider sx={{ my: 3 }} />
            
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            
            <TextField
              label="Пароль"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ mb: 2 }}
              helperText="Минимум 6 символов"
            />
            
            <TextField
              label="Подтвердите пароль"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              sx={{ mb: 3 }}
            />
            
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              disabled={loading}
              sx={{ 
                py: 1.5,
                mb: 2,
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)'
              }}
            >
              {loading ? 'Регистрация...' : 'Зарегистрироваться'}
            </Button>
            
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Typography variant="body2" color="textSecondary">
                Уже есть аккаунт?{' '}
                <MuiLink 
                  component={Link} 
                  to="/login"
                  sx={{ 
                    color: theme.palette.primary.main,
                    fontWeight: 500
                  }}
                >
                  Войти
                </MuiLink>
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                <MuiLink 
                  component={Link} 
                  to="/"
                  sx={{ 
                    color: theme.palette.text.secondary,
                    fontWeight: 500
                  }}
                >
                  Вернуться на главную
                </MuiLink>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Register; 