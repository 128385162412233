import React from 'react';
import { Box } from '@mui/material';

// Import all sections
import Header from '../components/landing/Header';
import HeroSection from '../components/landing/HeroSection';
import ProductSection from '../components/landing/ProductSection';
import JourneySection from '../components/landing/JourneySection';
import MotivationSection from '../components/landing/MotivationSection';
import TestimonialsSection from '../components/landing/TestimonialsSection';
import FaqSection from '../components/landing/FaqSection';
import Footer from '../components/landing/Footer';

const LandingPage: React.FC = () => {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Header */}
      <Header />
      
      {/* Main content */}
      <Box component="main">
        <HeroSection />
        <ProductSection />
        <JourneySection />
        <MotivationSection />
        <TestimonialsSection />
        <FaqSection />
      </Box>
      
      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default LandingPage; 