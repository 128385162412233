import React, { useState } from 'react';
import { Button, Typography, Paper, Box, CircularProgress, Alert } from '@mui/material';
import { seedPerformers } from '../../scripts/seedPerformers';

/**
 * Component for seeding the database with sample performers
 * This should only be used in development/testing
 */
const SeedDatabase: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleSeed = async () => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    
    try {
      await seedPerformers();
      setSuccess(true);
      setShowConfirm(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 3, maxWidth: 600, mx: 'auto', my: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Database Seeding Tool
      </Typography>
      
      <Typography variant="body1" sx={{ mb: 3 }}>
        This tool will populate the database with sample performers for testing purposes.
        Only use this in development environments!
      </Typography>
      
      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          Successfully seeded the database with sample performers!
        </Alert>
      )}
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          Error: {error}
        </Alert>
      )}
      
      {!showConfirm ? (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setShowConfirm(true)}
          disabled={loading}
        >
          Seed Database with Sample Performers
        </Button>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Alert severity="warning" sx={{ mb: 2 }}>
            This will add ~80 sample performers to your database. 
            Are you sure you want to proceed?
          </Alert>
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button 
              variant="contained" 
              color="error" 
              onClick={handleSeed}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Yes, Seed Database'}
            </Button>
            
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => setShowConfirm(false)}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default SeedDatabase; 