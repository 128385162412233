import { UserData } from '../services/auth';

// Sample performers for the "transport" category
export const transportPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "premium.cars@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Премиум Авто",
    phone: "+7 (495) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Компания премиального класса, предоставляющая автомобили с водителем для любых мероприятий. В нашем автопарке представлены лучшие марки Mercedes-Benz, BMW, Audi. Профессиональные водители, безупречный сервис, гибкие условия аренды.",
    location: "Москва",
    website: "https://premium-cars.ru",
    serviceCategory: "transport",
    priceRange: "5000-15000",
    skills: ["Трансфер гостей", "VIP-обслуживание", "Свадебный кортеж", "Деловые поездки", "Экскурсии"],
    portfolioLinks: [
      "https://premium-cars.ru/gallery",
      "https://www.instagram.com/premium.cars"
    ],
    certificateUrls: [
      "https://example.com/certificates/transport-license.jpg"
    ]
  },
  {
    email: "luxe.limo@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Люкс Лимузин",
    phone: "+7 (495) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1590066026028-200b966daf9e?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Аренда лимузинов для особых случаев. Роскошные автомобили, профессиональные водители, безупречный сервис. Организация свадебных кортежей, встречи и проводы гостей, VIP-трансферы.",
    location: "Москва",
    website: "https://luxe-limo.ru",
    serviceCategory: "transport",
    priceRange: "10000-30000",
    skills: ["Лимузины", "Свадебные кортежи", "VIP-обслуживание", "Праздничное оформление автомобилей"],
    portfolioLinks: [
      "https://luxe-limo.ru/gallery",
      "https://www.instagram.com/luxe.limo"
    ],
    certificateUrls: [
      "https://example.com/certificates/luxury-service.jpg"
    ]
  },
  {
    email: "retro.cars@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Ретро Стиль",
    phone: "+7 (495) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1544620347-c4fd4a3d5957?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Коллекция раритетных автомобилей для особых случаев. Предоставляем ретро-автомобили для свадеб, фотосессий, киносъемок и мероприятий в стиле ретро. Профессиональные водители, техническое обслуживание на высшем уровне.",
    location: "Москва",
    website: "https://retro-cars.ru",
    serviceCategory: "transport",
    priceRange: "15000-50000",
    skills: ["Ретро-автомобили", "Свадебные кортежи", "Фотосессии", "Киносъемки", "Тематические мероприятия"],
    portfolioLinks: [
      "https://retro-cars.ru/collection",
      "https://www.instagram.com/retro.cars"
    ],
    certificateUrls: [
      "https://example.com/certificates/vintage-cars.jpg"
    ]
  },
  {
    email: "helicopter.tours@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Вертолетные Туры",
    phone: "+7 (495) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1608236467029-ff0b68458658?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Эксклюзивные полеты на вертолете для особых случаев. Предлагаем уникальный способ сделать ваше мероприятие незабываемым: полеты над городом, романтические прогулки, встреча гостей, трансфер на отдаленные площадки.",
    location: "Москва",
    website: "https://helicopter-tours.ru",
    serviceCategory: "transport",
    priceRange: "50000-200000",
    skills: ["Вертолетные экскурсии", "VIP-трансфер", "Романтические полеты", "Свадебные сюрпризы", "Корпоративные мероприятия"],
    portfolioLinks: [
      "https://helicopter-tours.ru/gallery",
      "https://www.instagram.com/helicopter.tours"
    ],
    certificateUrls: [
      "https://example.com/certificates/aviation-license.jpg",
      "https://example.com/certificates/flight-safety.jpg"
    ]
  },
  {
    email: "yacht.club@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Яхт-Клуб Маяк",
    phone: "+7 (495) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1566288421673-dbc3373680ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Аренда яхт и катеров для мероприятий на воде. Предлагаем премиальные суда различного класса для проведения торжеств, корпоративных встреч, свадебных церемоний и фотосессий.",
    location: "Москва",
    website: "https://yacht-club-mayak.ru",
    serviceCategory: "transport",
    priceRange: "30000-150000",
    skills: ["Прогулки на яхте", "Свадебные церемонии", "Корпоративные мероприятия", "VIP-обслуживание", "Фотосессии на воде"],
    portfolioLinks: [
      "https://yacht-club-mayak.ru/fleet",
      "https://www.instagram.com/yacht.club.mayak"
    ],
    certificateUrls: [
      "https://example.com/certificates/marine-license.jpg"
    ]
  }
]; 