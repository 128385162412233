import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Paper, 
  Alert,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Avatar,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { updateUserProfile } from '../services/auth';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { styled } from '@mui/material/styles';
import BusinessIcon from '@mui/icons-material/Business';

// Компонент для загрузки файлов
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ProfileSetupOrganizer: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser, refreshUserData } = useAuth();
  const storage = getStorage();
  
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  // Информация о пользователе
  const [fullName, setFullName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [location, setLocation] = useState('');
  const [website, setWebsite] = useState('');
  
  const steps = [
    'Основная информация',
    'Дополнительная информация'
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setAvatar(file);
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!fullName || !phone) {
      setError('Пожалуйста, заполните все обязательные поля');
      return;
    }
    
    if (!currentUser) {
      setError('Пользователь не авторизован');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      
      let profileImageUrl = '';
      
      // Загружаем аватар, если он был выбран
      if (avatar) {
        const storageRef = ref(storage, `avatars/${currentUser.uid}`);
        await uploadBytes(storageRef, avatar);
        profileImageUrl = await getDownloadURL(storageRef);
      }
      
      // Создаем объект с данными профиля
      const profileData = {
        fullName,
        companyName,
        phone,
        profileImageUrl,
        aboutMe,
        location,
        website,
        userType: 'organizer',
        profileCompleted: true,
        createdAt: new Date().toISOString(),
      };
      
      console.log("Updating organizer profile:", profileData);
      
      // Обновляем профиль пользователя в Firestore
      await updateUserProfile(currentUser.uid, profileData);
      
      // Refresh user data in context
      await refreshUserData();
      
      // Перенаправляем на дашборд
      navigate('/dashboard');
    } catch (err: any) {
      console.error(err);
      setError('Ошибка при сохранении профиля. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        py: 6,
        backgroundColor: theme.palette.background.default
      }}
    >
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <Typography 
              variant="h4" 
              component="h1"
              sx={{ 
                fontWeight: 700,
                mb: 1
              }}
            >
              Настройка профиля организатора
            </Typography>
            <Typography color="textSecondary">
              Заполните информацию о себе, чтобы начать создавать мероприятия
            </Typography>
          </Box>

          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {error && (
            <Alert severity="error" sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit}>
            {activeStep === 0 ? (
              // Шаг 1: Основная информация
              <Box>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                    <Box sx={{ mb: 2 }}>
                      <Avatar
                        src={avatarUrl}
                        sx={{
                          width: 120,
                          height: 120,
                          mx: 'auto',
                          mb: 2,
                          border: `4px solid ${theme.palette.primary.main}20`,
                          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                          bgcolor: theme.palette.primary.main
                        }}
                      >
                        {!avatarUrl && <BusinessIcon sx={{ fontSize: 60 }} />}
                      </Avatar>
                      
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<CloudUploadIcon />}
                        size="small"
                      >
                        Загрузить фото
                        <VisuallyHiddenInput 
                          type="file" 
                          accept="image/*"
                          onChange={handleAvatarChange}
                        />
                      </Button>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} md={8}>
                    <TextField
                      label="ФИО *"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                      sx={{ mb: 2 }}
                    />
                    
                    <TextField
                      label="Название компании"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      sx={{ mb: 2 }}
                    />
                    
                    <TextField
                      label="Телефон *"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      sx={{ mb: 2 }}
                      placeholder="+7 (999) 123-45-67"
                    />
                  </Grid>
                </Grid>
                
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ py: 1.5, px: 4 }}
                  >
                    Далее
                  </Button>
                </Box>
              </Box>
            ) : (
              // Шаг 2: Дополнительная информация
              <Box>
                <TextField
                  label="О себе / компании"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  value={aboutMe}
                  onChange={(e) => setAboutMe(e.target.value)}
                  placeholder="Расскажите о себе или своей компании, опыте организации мероприятий и т.д."
                  sx={{ mb: 2 }}
                />
                
                <TextField
                  label="Местоположение"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="Например: Москва"
                  sx={{ mb: 2 }}
                />
                
                <TextField
                  label="Веб-сайт"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  placeholder="https://example.com"
                  sx={{ mb: 4 }}
                />
                
                <Divider sx={{ my: 3 }} />
                
                <Typography variant="body2" color="textSecondary" sx={{ mb: 4 }}>
                  * Обязательные поля отмечены звездочкой
                </Typography>
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    onClick={handleBack}
                    sx={{ py: 1.5, px: 4 }}
                  >
                    Назад
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    sx={{ py: 1.5, px: 4 }}
                  >
                    {loading ? 'Сохранение...' : 'Сохранить профиль'}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ProfileSetupOrganizer; 