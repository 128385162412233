import { UserData } from '../services/auth';

// Sample performers for the "entertainment" category
export const entertainmentPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "master.ceremony@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Алексей Ведущий",
    phone: "+7 (495) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1553267751-1c148a7280a1?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный ведущий с 10-летним опытом проведения мероприятий различного формата. Интеллигентный конферанс, искрометный юмор, умение импровизировать. Индивидуальный подход к каждому мероприятию, авторские программы.",
    location: "Москва",
    website: "https://master-ceremony.ru",
    serviceCategory: "entertainment",
    priceRange: "50000-150000",
    skills: ["Ведущий", "Конферансье", "Интерактивные программы", "Корпоративы", "Свадьбы", "Юбилеи"],
    portfolioLinks: [
      "https://master-ceremony.ru/videos",
      "https://www.instagram.com/alexey.mc"
    ],
    certificateUrls: [
      "https://example.com/certificates/public-speaking.jpg"
    ]
  },
  {
    email: "comedy.show@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Студия Юмора 'Смешно'",
    phone: "+7 (495) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1527224857830-43a7acc85260?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Команда профессиональных комиков, стендап-артистов и импровизаторов. Проводим комедийные шоу для корпоративных клиентов. Юмор для любой аудитории, интерактивные выступления, кастомизированные программы под ваше мероприятие.",
    location: "Москва",
    website: "https://comedy-show.ru",
    serviceCategory: "entertainment",
    priceRange: "60000-200000",
    skills: ["Стендап", "Импровизация", "Комедийное шоу", "Корпоративный юмор", "Интерактив", "Кастомизированные программы"],
    portfolioLinks: [
      "https://comedy-show.ru/performances",
      "https://www.youtube.com/channel/comedyshow"
    ],
    certificateUrls: []
  },
  {
    email: "magician@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Максим Волшебник",
    phone: "+7 (495) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1571173339275-92ae84d8c136?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Иллюзионист международного класса. Выступаю на мероприятиях разного формата с программами микро-магии (фокусы вблизи), сценическими иллюзиями и ментальной магией. Создаю незабываемые впечатления и атмосферу чуда.",
    location: "Москва",
    website: "https://maxim-magician.ru",
    serviceCategory: "entertainment",
    priceRange: "40000-120000",
    skills: ["Иллюзионист", "Микро-магия", "Сценические иллюзии", "Ментальная магия", "Интерактивные фокусы", "Карточные трюки"],
    portfolioLinks: [
      "https://maxim-magician.ru/shows",
      "https://www.instagram.com/maxim.magic"
    ],
    certificateUrls: [
      "https://example.com/certificates/magic-academy.jpg"
    ]
  },
  {
    email: "dance.show@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Шоу-балет 'Движение'",
    phone: "+7 (495) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1508700115892-45ecd05ae2ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный шоу-балет для мероприятий. Предлагаем тематические танцевальные номера, интерактивные программы с вовлечением гостей, постановку свадебного танца. Костюмы, хореография и музыка подбираются под концепцию вашего мероприятия.",
    location: "Москва",
    website: "https://dance-show.ru",
    serviceCategory: "entertainment",
    priceRange: "30000-100000",
    skills: ["Шоу-балет", "Танцевальные номера", "Тематические шоу", "Постановка свадебного танца", "Интерактивные программы", "Костюмированные выступления"],
    portfolioLinks: [
      "https://dance-show.ru/videos",
      "https://www.instagram.com/dance.show"
    ],
    certificateUrls: [
      "https://example.com/certificates/choreography.jpg"
    ]
  },
  {
    email: "fire.show@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Огненное Шоу 'Феникс'",
    phone: "+7 (495) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1566737236500-c8ac43014a67?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональное фаер-шоу для мероприятий на открытом воздухе. Выступления с огнем, пиротехникой, световыми элементами. Зрелищные номера, синхронные выступления, интерактив со зрителями. Безопасность гарантируем.",
    location: "Москва",
    website: "https://fire-show.ru",
    serviceCategory: "entertainment",
    priceRange: "20000-80000",
    skills: ["Фаер-шоу", "Выступления с огнем", "Пиротехника", "Световое шоу", "Огненная хореография", "LED-шоу"],
    portfolioLinks: [
      "https://fire-show.ru/gallery",
      "https://www.instagram.com/fire.show"
    ],
    certificateUrls: [
      "https://example.com/certificates/fire-safety.jpg"
    ]
  },
  {
    email: "bubble.show@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Шоу Мыльных Пузырей 'Радуга'",
    phone: "+7 (495) 678-9012",
    profileImageUrl: "https://images.unsplash.com/photo-1531262951893-950f0f5cec5f?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Уникальное шоу мыльных пузырей для детских и взрослых мероприятий. Создаем гигантские пузыри, пузыри с дымом, светящиеся пузыри, помещаем людей в пузырь. Интерактивные программы с участием гостей. Идеально для детских праздников и необычных свадебных фотосессий.",
    location: "Москва",
    website: "https://bubble-show.ru",
    serviceCategory: "entertainment",
    priceRange: "15000-50000",
    skills: ["Шоу мыльных пузырей", "Гигантские пузыри", "Пузыри с дымом", "Светящиеся пузыри", "Человек в пузыре", "Интерактивные программы"],
    portfolioLinks: [
      "https://bubble-show.ru/performance",
      "https://www.instagram.com/bubble.show"
    ],
    certificateUrls: []
  },
  {
    email: "circus.performers@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Цирковая Студия 'Арена'",
    phone: "+7 (495) 789-0123",
    profileImageUrl: "https://images.unsplash.com/photo-1519834089823-2a6457957415?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональные цирковые артисты для ваших мероприятий. Акробаты, жонглеры, эквилибристы, воздушные гимнасты, клоуны. Создаем яркие, запоминающиеся выступления, адаптированные под любое пространство и концепцию мероприятия.",
    location: "Москва",
    website: "https://circus-performers.ru",
    serviceCategory: "entertainment",
    priceRange: "30000-150000",
    skills: ["Цирковые артисты", "Акробаты", "Жонглеры", "Эквилибристы", "Воздушные гимнасты", "Клоуны"],
    portfolioLinks: [
      "https://circus-performers.ru/artists",
      "https://www.instagram.com/circus.performers"
    ],
    certificateUrls: [
      "https://example.com/certificates/circus-academy.jpg"
    ]
  },
  {
    email: "interactive.games@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Студия Интерактивных Развлечений 'Игра'",
    phone: "+7 (495) 890-1234",
    profileImageUrl: "https://images.unsplash.com/photo-1543269865-cbf427effbad?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Организуем интерактивные игровые зоны и развлечения на мероприятиях. Квесты, викторины, командные игры, интеллектуальные состязания, мастер-классы. Адаптируем формат под любое количество участников и концепцию мероприятия.",
    location: "Москва",
    website: "https://interactive-games.ru",
    serviceCategory: "entertainment",
    priceRange: "20000-100000",
    skills: ["Интерактивные развлечения", "Квесты", "Командные игры", "Викторины", "Мастер-классы", "Тимбилдинг"],
    portfolioLinks: [
      "https://interactive-games.ru/activities",
      "https://www.instagram.com/interactive.games"
    ],
    certificateUrls: []
  },
  {
    email: "children.entertainment@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Детская Студия 'Веселый Праздник'",
    phone: "+7 (495) 901-2345",
    profileImageUrl: "https://images.unsplash.com/photo-1541417904950-b855846fe074?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Организуем развлечения для детей на мероприятиях любого формата. Аниматоры в костюмах любимых персонажей, мастер-классы, аквагрим, игровые зоны, шоу-программы. Работаем с детьми разных возрастов, учитываем их интересы и предпочтения.",
    location: "Москва",
    website: "https://children-entertainment.ru",
    serviceCategory: "entertainment",
    priceRange: "10000-50000",
    skills: ["Детские развлечения", "Аниматоры", "Аквагрим", "Мастер-классы для детей", "Игровые зоны", "Детские шоу-программы"],
    portfolioLinks: [
      "https://children-entertainment.ru/gallery",
      "https://www.instagram.com/children.entertainment"
    ],
    certificateUrls: [
      "https://example.com/certificates/child-psychology.jpg"
    ]
  },
  {
    email: "vr.entertainment@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "VR Развлечения 'Виртуальная Реальность'",
    phone: "+7 (495) 012-3456",
    profileImageUrl: "https://images.unsplash.com/photo-1498661705887-3778d9ecb4ff?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Организуем зоны виртуальной реальности на мероприятиях. Предоставляем современное VR-оборудование, игры и симуляторы. Создаем уникальный опыт для гостей вашего мероприятия. Идеально для корпоративов, презентаций и инновационных событий.",
    location: "Москва",
    website: "https://vr-entertainment.ru",
    serviceCategory: "entertainment",
    priceRange: "30000-150000",
    skills: ["VR развлечения", "Виртуальная реальность", "Интерактивные технологии", "VR игры", "Симуляторы", "Инновационные развлечения"],
    portfolioLinks: [
      "https://vr-entertainment.ru/experiences",
      "https://www.instagram.com/vr.entertainment"
    ],
    certificateUrls: [
      "https://example.com/certificates/vr-technology.jpg"
    ]
  }
]; 