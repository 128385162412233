import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Link,
  Divider,
  IconButton,
  useTheme,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const Footer: React.FC = () => {
  const theme = useTheme();
  
  const footerLinks = [
    {
      title: 'О компании',
      links: [
        { name: 'О нас', url: '#' },
        { name: 'Наша команда', url: '#' },
        { name: 'Карьера', url: '#' },
        { name: 'Новости', url: '#' },
        { name: 'Блог', url: '#' }
      ]
    },
    {
      title: 'Для организаторов',
      links: [
        { name: 'Как это работает', url: '#' },
        { name: 'Создать мероприятие', url: '#' },
        { name: 'Найти исполнителей', url: '#' },
        { name: 'Тарифы', url: '#' },
        { name: 'Успешные кейсы', url: '#' }
      ]
    },
    {
      title: 'Для исполнителей',
      links: [
        { name: 'Как начать работу', url: '#' },
        { name: 'Найти заказы', url: '#' },
        { name: 'Продвижение профиля', url: '#' },
        { name: 'Комиссии и выплаты', url: '#' },
        { name: 'Истории успеха', url: '#' }
      ]
    },
    {
      title: 'Поддержка',
      links: [
        { name: 'Центр помощи', url: '#' },
        { name: 'Связаться с нами', url: '#' },
        { name: 'Правила платформы', url: '#' },
        { name: 'Политика конфиденциальности', url: '#' },
        { name: 'Условия использования', url: '#' }
      ]
    }
  ];

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        pt: 8,
        pb: 4,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '30%',
          height: '40%',
          background: `radial-gradient(circle, ${theme.palette.secondary.main}30 0%, transparent 70%)`,
          filter: 'blur(60px)',
          opacity: 0.3,
          zIndex: 0,
        }
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography 
              variant="h4" 
              component="div" 
              sx={{ 
                fontWeight: 700, 
                mb: 2,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img 
                src="/logo_w.svg" 
                alt="MakeEvent" 
                style={{ 
                  height: '40px',
                  filter: 'brightness(0) invert(1)' // Makes the logo white
                }} 
              />
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, opacity: 0.8 }}>
              Платформа, соединяющая организаторов мероприятий с профессиональными исполнителями для создания незабываемых событий.
            </Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <LocationOnIcon sx={{ mr: 1, opacity: 0.8 }} />
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                Москва, ул. Примерная, д. 123
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <EmailIcon sx={{ mr: 1, opacity: 0.8 }} />
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                info@eventpro.ru
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <PhoneIcon sx={{ mr: 1, opacity: 0.8 }} />
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                +7 (999) 123-45-67
              </Typography>
            </Box>
            
            <Box sx={{ mb: 3 }}>
              <IconButton 
                sx={{ 
                  color: 'white', 
                  mr: 1,
                  '&:hover': {
                    backgroundColor: `${theme.palette.secondary.main}40`
                  }
                }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton 
                sx={{ 
                  color: 'white', 
                  mr: 1,
                  '&:hover': {
                    backgroundColor: `${theme.palette.secondary.main}40`
                  }
                }}
              >
                <TwitterIcon />
              </IconButton>
              <IconButton 
                sx={{ 
                  color: 'white', 
                  mr: 1,
                  '&:hover': {
                    backgroundColor: `${theme.palette.secondary.main}40`
                  }
                }}
              >
                <InstagramIcon />
              </IconButton>
              <IconButton 
                sx={{ 
                  color: 'white', 
                  mr: 1,
                  '&:hover': {
                    backgroundColor: `${theme.palette.secondary.main}40`
                  }
                }}
              >
                <TelegramIcon />
              </IconButton>
              <IconButton 
                sx={{ 
                  color: 'white',
                  '&:hover': {
                    backgroundColor: `${theme.palette.secondary.main}40`
                  }
                }}
              >
                <YouTubeIcon />
              </IconButton>
            </Box>
          </Grid>
          
          {footerLinks.map((section, index) => (
            <Grid item xs={6} sm={3} md={2} key={index}>
              <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                {section.title}
              </Typography>
              <List dense disablePadding>
                {section.links.map((link, linkIndex) => (
                  <ListItem 
                    key={linkIndex} 
                    disablePadding 
                    sx={{ 
                      mb: 1,
                      '&:hover': {
                        color: theme.palette.secondary.main
                      }
                    }}
                  >
                    <Link 
                      href={link.url} 
                      underline="none" 
                      color="inherit"
                      sx={{ 
                        opacity: 0.8,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          opacity: 1,
                          color: theme.palette.secondary.main
                        }
                      }}
                    >
                      {link.name}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
        
        <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
        
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography 
            className="pencil-text" 
            variant="body2" 
            sx={{ 
              opacity: 0.7,
              lineHeight: 1.6,
              '& a': {
                color: theme.palette.secondary.main,
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            }}
          >
            Проект создан при поддержке <Link href="https://fasie.ru" target="_blank" rel="noopener noreferrer">Федерального государственного бюджетного учреждения Фонд содействия развитию малых форм предприятий в научно-технической сфере</Link> в рамках программы <Link href="https://univertechpred.ru/" target="_blank" rel="noopener noreferrer">«Студенческий стартап» федерального проекта «Платформа университетского технологического предпринимательства»</Link>
          </Typography>
        </Box>
        
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ opacity: 0.7 }}>
            © {new Date().getFullYear()} MakeEvent. Все права защищены.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer; 