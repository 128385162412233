import { UserData } from '../services/auth';

// Sample performers for the "technical" category
export const technicalPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "sound.masters@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Звуковые Мастера",
    phone: "+7 (495) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональное звуковое оборудование для мероприятий любого масштаба. Настройка звука, техническое сопровождение, работа с артистами. Опыт организации концертов, конференций и фестивалей более 15 лет.",
    location: "Москва",
    website: "https://sound-masters.ru",
    serviceCategory: "technical",
    priceRange: "30000-100000",
    skills: ["Звуковое оборудование", "Техническое сопровождение", "Концертный звук", "Конференции", "Фестивали"],
    portfolioLinks: [
      "https://sound-masters.ru/projects",
      "https://www.instagram.com/sound.masters"
    ],
    certificateUrls: [
      "https://example.com/certificates/sound-engineer.jpg"
    ]
  },
  {
    email: "light.show@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Световое Шоу",
    phone: "+7 (495) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1519414442781-fbd745c5b497?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Световое оформление мероприятий любой сложности. Профессиональное оборудование, креативные световые решения, лазерные шоу. Работаем с концертами, корпоративными мероприятиями, свадьбами и частными вечеринками.",
    location: "Москва",
    website: "https://light-show.ru",
    serviceCategory: "technical",
    priceRange: "25000-80000",
    skills: ["Световое оборудование", "Лазерные шоу", "Сценическое освещение", "3D-мэппинг", "Световые эффекты"],
    portfolioLinks: [
      "https://light-show.ru/portfolio",
      "https://www.instagram.com/light.show"
    ],
    certificateUrls: [
      "https://example.com/certificates/lighting-design.jpg"
    ]
  },
  {
    email: "projection.pro@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Проекционные Технологии",
    phone: "+7 (495) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1521157797935-87c48d870f55?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Проекционное оборудование и мультимедийные решения для мероприятий. Предлагаем 3D-мэппинг, голографические проекции, интерактивные инсталляции. Техническое сопровождение от подготовки до реализации.",
    location: "Москва",
    website: "https://projection-pro.ru",
    serviceCategory: "technical",
    priceRange: "40000-150000",
    skills: ["3D-мэппинг", "Голографические проекции", "Интерактивные инсталляции", "Технический продакшн", "Презентации"],
    portfolioLinks: [
      "https://projection-pro.ru/projects",
      "https://www.instagram.com/projection.pro"
    ],
    certificateUrls: [
      "https://example.com/certificates/multimedia-expert.jpg"
    ]
  },
  {
    email: "stage.tech@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Сценические Технологии",
    phone: "+7 (495) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Комплексное техническое обеспечение мероприятий. Монтаж сценических конструкций, световое и звуковое оборудование, LED-экраны, спецэффекты. Полный цикл работ от проектирования до демонтажа.",
    location: "Москва",
    website: "https://stage-tech.ru",
    serviceCategory: "technical",
    priceRange: "50000-300000",
    skills: ["Сценические конструкции", "Техническое обеспечение", "Световое и звуковое оборудование", "LED-экраны", "Спецэффекты"],
    portfolioLinks: [
      "https://stage-tech.ru/projects",
      "https://www.instagram.com/stage.tech"
    ],
    certificateUrls: [
      "https://example.com/certificates/stage-safety.jpg",
      "https://example.com/certificates/technical-director.jpg"
    ]
  },
  {
    email: "pyrotechnic.show@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Пиротехническое Шоу",
    phone: "+7 (495) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1498931299472-f7a63a5a1cfa?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональные пиротехнические шоу для мероприятий. Фейерверки любой сложности, огненные эффекты, холодные фонтаны для помещений. Безопасность и зрелищность гарантированы.",
    location: "Москва",
    website: "https://pyrotechnic-show.ru",
    serviceCategory: "technical",
    priceRange: "30000-200000",
    skills: ["Фейерверки", "Огненные эффекты", "Холодные фонтаны", "Пиромузыкальные шоу", "Сценические спецэффекты"],
    portfolioLinks: [
      "https://pyrotechnic-show.ru/gallery",
      "https://www.instagram.com/pyrotechnic.show"
    ],
    certificateUrls: [
      "https://example.com/certificates/pyrotechnic-license.jpg",
      "https://example.com/certificates/safety-certificate.jpg"
    ]
  }
]; 