import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Stepper, 
  Step, 
  StepLabel, 
  StepContent,
  Button,
  Paper,
  Tabs,
  Tab,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Avatar
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import SearchIcon from '@mui/icons-material/Search';
import MessageIcon from '@mui/icons-material/Message';
import PaymentIcon from '@mui/icons-material/Payment';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WorkIcon from '@mui/icons-material/Work';
import StarIcon from '@mui/icons-material/Star';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`journey-tabpanel-${index}`}
      aria-labelledby={`journey-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const JourneySection: React.FC = () => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const organizerSteps = [
    {
      label: 'Регистрация',
      description: 'Создайте аккаунт организатора, указав основную информацию о себе или своей компании.',
      icon: <PersonAddIcon />,
      image: 'https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Создание мероприятия',
      description: 'Опишите ваше мероприятие, укажите дату, место проведения, бюджет и требуемые услуги.',
      icon: <CreateIcon />,
      image: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Поиск исполнителей',
      description: 'Просмотрите каталог проверенных исполнителей или получите автоматические рекомендации на основе ваших требований.',
      icon: <SearchIcon />,
      image: 'https://images.unsplash.com/photo-1551836022-d5d88e9218df?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Обсуждение деталей',
      description: 'Свяжитесь с выбранными исполнителями через встроенный чат для обсуждения деталей сотрудничества.',
      icon: <MessageIcon />,
      image: 'https://images.unsplash.com/photo-1573497491765-dccce02b29df?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Бронирование и оплата',
      description: 'Забронируйте услуги исполнителей и произведите безопасную оплату через нашу платформу.',
      icon: <PaymentIcon />,
      image: 'https://images.unsplash.com/photo-1556741533-6e6a62bd8b49?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Управление мероприятием',
      description: 'Используйте инструменты управления проектом для координации всех аспектов вашего мероприятия.',
      icon: <EventAvailableIcon />,
      image: 'https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    }
  ];

  const performerSteps = [
    {
      label: 'Регистрация',
      description: 'Создайте профессиональный профиль исполнителя, указав ваши услуги, опыт работы и портфолио.',
      icon: <PersonAddIcon />,
      image: 'https://images.unsplash.com/photo-1554774853-aae0a22c8aa4?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Настройка профиля',
      description: 'Добавьте фотографии, видео, описание услуг и установите цены для привлечения потенциальных клиентов.',
      icon: <CreateIcon />,
      image: 'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Поиск заказов',
      description: 'Просматривайте доступные мероприятия, фильтруя их по дате, местоположению и типу услуг.',
      icon: <WorkIcon />,
      image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Отклик на заказы',
      description: 'Отправляйте предложения организаторам мероприятий, указывая условия сотрудничества и стоимость услуг.',
      icon: <MessageIcon />,
      image: 'https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-4.0.3&auto=format&fit=crop&w=1171&q=80'
    },
    {
      label: 'Подтверждение заказа',
      description: 'Получайте подтверждение бронирования и предоплату через безопасную платежную систему.',
      icon: <PaymentIcon />,
      image: 'https://images.unsplash.com/photo-1579389083078-4e7018379f7e?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    },
    {
      label: 'Выполнение и отзывы',
      description: 'Выполняйте заказы и получайте отзывы от клиентов, повышая свой рейтинг на платформе.',
      icon: <StarIcon />,
      image: 'https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
    }
  ];

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              position: 'relative',
              display: 'inline-block',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 4,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 2,
              }
            }}
          >
            Как это работает
          </Typography>
          
          <Typography 
            variant="h5" 
            sx={{ 
              maxWidth: 800, 
              mx: 'auto', 
              color: theme.palette.text.secondary,
              mb: 4
            }}
          >
            Простой путь к успешному сотрудничеству для организаторов и исполнителей
          </Typography>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              centered
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: theme.palette.secondary.main,
                  height: 3,
                },
                '& .Mui-selected': {
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                },
              }}
            >
              <Tab 
                label="Для организаторов" 
                sx={{ 
                  fontSize: '1.1rem', 
                  textTransform: 'none',
                  fontWeight: 500,
                  py: 2
                }} 
              />
              <Tab 
                label="Для исполнителей" 
                sx={{ 
                  fontSize: '1.1rem', 
                  textTransform: 'none',
                  fontWeight: 500,
                  py: 2
                }} 
              />
            </Tabs>
          </Box>
          
          <TabPanel value={tabValue} index={0}>
            <Box sx={{ maxWidth: 900, mx: 'auto' }}>
              <Stepper orientation="vertical" sx={{ mt: 4 }}>
                {organizerSteps.map((step, index) => (
                  <Step key={index} active={true}>
                    <StepLabel 
                      StepIconProps={{ 
                        icon: <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{step.icon}</Avatar> 
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {step.label}
                      </Typography>
                    </StepLabel>
                    <StepContent>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={7}>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {step.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Card 
                            sx={{ 
                              borderRadius: 2,
                              overflow: 'hidden',
                              boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="160"
                              image={step.image}
                              alt={step.label}
                            />
                          </Card>
                        </Grid>
                      </Grid>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </TabPanel>
          
          <TabPanel value={tabValue} index={1}>
            <Box sx={{ maxWidth: 900, mx: 'auto' }}>
              <Stepper orientation="vertical" sx={{ mt: 4 }}>
                {performerSteps.map((step, index) => (
                  <Step key={index} active={true}>
                    <StepLabel 
                      StepIconProps={{ 
                        icon: <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{step.icon}</Avatar> 
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {step.label}
                      </Typography>
                    </StepLabel>
                    <StepContent>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={7}>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {step.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Card 
                            sx={{ 
                              borderRadius: 2,
                              overflow: 'hidden',
                              boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="160"
                              image={step.image}
                              alt={step.label}
                            />
                          </Card>
                        </Grid>
                      </Grid>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </TabPanel>
        </Box>
      </Container>
      
      {/* Декоративный элемент */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          width: '25%',
          height: '50%',
          background: `radial-gradient(circle, ${theme.palette.secondary.main}30 0%, transparent 70%)`,
          filter: 'blur(60px)',
          zIndex: 0,
          transform: 'translateY(-50%)',
        }}
      />
    </Box>
  );
};

export default JourneySection; 