import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Card, 
  CardContent,
  Avatar,
  Grid,
  useTheme,
  IconButton,
  Rating,
  Tabs,
  Tab
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`testimonial-tabpanel-${index}`}
      aria-labelledby={`testimonial-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const TestimonialsSection: React.FC = () => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setCurrentSlide(0);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 
        ? (tabValue === 0 ? organizerTestimonials.length - 1 : performerTestimonials.length - 1) 
        : prev - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => 
      prev === (tabValue === 0 ? organizerTestimonials.length - 1 : performerTestimonials.length - 1) 
        ? 0 
        : prev + 1
    );
  };

  const organizerTestimonials = [
    {
      name: 'Анна Петрова',
      role: 'Организатор свадеб',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
      rating: 5,
      text: 'Благодаря этой платформе я смогла организовать идеальную свадьбу для моих клиентов. Быстрый поиск исполнителей и удобная система коммуникации значительно упростили мою работу. Теперь я использую только эту платформу для всех мероприятий!'
    },
    {
      name: 'Сергей Иванов',
      role: 'Директор по маркетингу',
      avatar: 'https://randomuser.me/api/portraits/men/32.jpg',
      rating: 5,
      text: 'Организовывали корпоративное мероприятие на 200 человек. Платформа помогла найти всех необходимых исполнителей в кратчайшие сроки. Особенно понравилась возможность сравнивать предложения и читать отзывы. Всё прошло идеально!'
    },
    {
      name: 'Елена Смирнова',
      role: 'Организатор фестивалей',
      avatar: 'https://randomuser.me/api/portraits/women/68.jpg',
      rating: 4,
      text: 'Использую платформу для организации музыкальных фестивалей. Огромный выбор артистов и технических специалистов. Удобная система управления помогает держать под контролем все аспекты мероприятия. Рекомендую всем организаторам!'
    }
  ];

  const performerTestimonials = [
    {
      name: 'Михаил Козлов',
      role: 'Фотограф',
      avatar: 'https://randomuser.me/api/portraits/men/75.jpg',
      rating: 5,
      text: 'С момента регистрации на платформе мой календарь заполнен заказами. Удобный интерфейс, прозрачная система оплаты и отличная поддержка. За полгода работы мой доход вырос в два раза. Очень доволен сотрудничеством!'
    },
    {
      name: 'Ольга Соколова',
      role: 'Ведущая мероприятий',
      avatar: 'https://randomuser.me/api/portraits/women/90.jpg',
      rating: 5,
      text: 'Платформа стала для меня основным источником клиентов. Больше не трачу деньги на рекламу, клиенты находят меня сами. Система рейтингов помогает выделиться среди конкурентов. Спасибо за такой удобный сервис!'
    },
    {
      name: 'Дмитрий Новиков',
      role: 'Музыкант',
      avatar: 'https://randomuser.me/api/portraits/men/41.jpg',
      rating: 4,
      text: 'Наша группа получает регулярные заказы на корпоративные мероприятия и частные вечеринки. Платформа помогает управлять расписанием и вести переговоры с клиентами. Очень удобно, что все документы и оплаты проходят через сервис.'
    }
  ];

  const testimonials = tabValue === 0 ? organizerTestimonials : performerTestimonials;
  const currentTestimonial = testimonials[currentSlide];

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '40%',
          height: '40%',
          background: `radial-gradient(circle, ${theme.palette.secondary.main}20 0%, transparent 70%)`,
          filter: 'blur(60px)',
          zIndex: 0,
        }
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              position: 'relative',
              display: 'inline-block',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 4,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 2,
              }
            }}
          >
            Отзывы пользователей
          </Typography>
          
          <Typography 
            variant="h5" 
            sx={{ 
              maxWidth: 800, 
              mx: 'auto', 
              color: theme.palette.text.secondary,
              mb: 4
            }}
          >
            Узнайте, что говорят о нас наши клиенты
          </Typography>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              centered
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: theme.palette.secondary.main,
                  height: 3,
                },
                '& .Mui-selected': {
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                },
              }}
            >
              <Tab 
                label="Организаторы" 
                sx={{ 
                  fontSize: '1.1rem', 
                  textTransform: 'none',
                  fontWeight: 500,
                  py: 2
                }} 
              />
              <Tab 
                label="Исполнители" 
                sx={{ 
                  fontSize: '1.1rem', 
                  textTransform: 'none',
                  fontWeight: 500,
                  py: 2
                }} 
              />
            </Tabs>
          </Box>
          
          <Box sx={{ position: 'relative', mt: 6, mb: 4 }}>
            <Card
              sx={{
                maxWidth: 900,
                mx: 'auto',
                borderRadius: 4,
                boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
                position: 'relative',
                overflow: 'visible',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: -20,
                  left: 40,
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.secondary.main,
                  opacity: 0.2,
                  zIndex: 0,
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -30,
                  right: 60,
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.primary.main,
                  opacity: 0.1,
                  zIndex: 0,
                }
              }}
            >
              <CardContent sx={{ p: { xs: 3, md: 5 } }}>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                    <Avatar
                      src={currentTestimonial.avatar}
                      alt={currentTestimonial.name}
                      sx={{ 
                        width: 120, 
                        height: 120, 
                        mx: 'auto',
                        border: `4px solid ${theme.palette.secondary.main}`,
                        boxShadow: '0 8px 20px rgba(0,0,0,0.1)'
                      }}
                    />
                    <Typography variant="h5" sx={{ mt: 2, fontWeight: 600 }}>
                      {currentTestimonial.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
                      {currentTestimonial.role}
                    </Typography>
                    <Rating value={currentTestimonial.rating} readOnly sx={{ mb: 2 }} />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box sx={{ position: 'relative' }}>
                      <FormatQuoteIcon 
                        sx={{ 
                          fontSize: 60, 
                          color: `${theme.palette.secondary.main}40`,
                          position: 'absolute',
                          top: -20,
                          left: -10
                        }} 
                      />
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontSize: '1.1rem', 
                          lineHeight: 1.7,
                          fontStyle: 'italic',
                          pl: 4,
                          position: 'relative',
                          zIndex: 1
                        }}
                      >
                        {currentTestimonial.text}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mt: 4,
                gap: 2
              }}
            >
              <IconButton 
                onClick={handlePrevSlide}
                sx={{ 
                  backgroundColor: theme.palette.background.default,
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText
                  }
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton 
                onClick={handleNextSlide}
                sx={{ 
                  backgroundColor: theme.palette.background.default,
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText
                  }
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
            
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mt: 2,
                gap: 1
              }}
            >
              {testimonials.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: index === currentSlide 
                      ? theme.palette.secondary.main 
                      : theme.palette.divider,
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                  }}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TestimonialsSection; 