import { collection, addDoc, updateDoc, doc, getDoc, getDocs, query, where, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Типы для мероприятий
export type EventType = 'wedding' | 'corporate' | 'concert' | 'conference' | 'party' | 'other';

// Типы сервисов, которые могут предоставлять исполнители
export type ServiceType = 
  'music' |             // Музыка/DJ
  'venue' |             // Площадки и помещения
  'photography' |       // Фото
  'catering' |          // Кейтеринг и обслуживание
  'decoration' |        // Декор и оформление
  'entertainment' |     // Ведущие и аниматоры
  'transport' |         // Транспорт
  'technical' |         // Техническое обеспечение
  'videography' |       // Видеосъемка
  'other';              // Другое

export interface EventService {
  type: ServiceType;
  description: string;
  budget: number; // in rubles
  isBooked: boolean;
  performerId?: string;
}

export interface Event {
  id?: string;
  organizerId: string;
  title: string;
  eventType: EventType;
  description: string;
  date: string; // ISO string
  time: string;
  location: string;
  totalBudget: number;
  services: EventService[];
  createdAt: string;
  updatedAt: string;
  status: 'draft' | 'published' | 'completed' | 'cancelled';
  attendees?: number;
  image?: string;
}

// Создание нового мероприятия
export const createEvent = async (eventData: Omit<Event, 'id' | 'createdAt' | 'updatedAt'>): Promise<string> => {
  try {
    const timestamp = new Date().toISOString();
    const newEvent = {
      ...eventData,
      createdAt: timestamp,
      updatedAt: timestamp
    };
    
    const docRef = await addDoc(collection(db, 'events'), newEvent);
    return docRef.id;
  } catch (error: any) {
    console.error("Error creating event:", error);
    throw new Error(error.message);
  }
};

// Получение мероприятия по ID
export const getEventById = async (eventId: string): Promise<Event | null> => {
  try {
    const eventDoc = await getDoc(doc(db, 'events', eventId));
    if (eventDoc.exists()) {
      return { id: eventDoc.id, ...eventDoc.data() } as Event;
    }
    return null;
  } catch (error: any) {
    console.error("Error getting event:", error);
    throw new Error(error.message);
  }
};

// Обновление мероприятия
export const updateEvent = async (eventId: string, eventData: Partial<Event>): Promise<void> => {
  try {
    const eventRef = doc(db, 'events', eventId);
    const updatedData = {
      ...eventData,
      updatedAt: new Date().toISOString()
    };
    await updateDoc(eventRef, updatedData);
  } catch (error: any) {
    console.error("Error updating event:", error);
    throw new Error(error.message);
  }
};

// Получение мероприятий организатора
export const getOrganizerEvents = async (organizerId: string): Promise<Event[]> => {
  try {
    const eventsQuery = query(
      collection(db, 'events'),
      where('organizerId', '==', organizerId)
    );
    
    const querySnapshot = await getDocs(eventsQuery);
    const events: Event[] = [];
    
    querySnapshot.forEach((doc) => {
      events.push({ id: doc.id, ...doc.data() } as Event);
    });
    
    return events.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  } catch (error: any) {
    console.error("Error getting organizer events:", error);
    throw new Error(error.message);
  }
};

// Получение мероприятий для исполнителя
export const getPerformerEvents = async (performerCategory: ServiceType): Promise<Event[]> => {
  try {
    console.log('Fetching events for performer category:', performerCategory);
    const eventsRef = collection(db, 'events');
    const querySnapshot = await getDocs(eventsRef);
    const events: Event[] = [];
    
    console.log('Total events found:', querySnapshot.size);
    
    querySnapshot.forEach((doc) => {
      const event = { id: doc.id, ...doc.data() } as Event;
      console.log('Checking event:', {
        id: event.id,
        title: event.title,
        status: event.status,
        services: event.services
      });
      
      // Check if event has services matching performer's category
      const hasMatchingService = event.services.some(
        service => {
          const matches = service.type === performerCategory && !service.isBooked;
          console.log('Service check:', {
            serviceType: service.type,
            performerCategory,
            isBooked: service.isBooked,
            matches
          });
          return matches;
        }
      );
      
      if (hasMatchingService && event.status === 'published') {
        console.log('Adding event to results:', event.title);
        events.push(event);
      } else {
        console.log('Skipping event. Reason:', !hasMatchingService ? 'No matching service' : 'Not published');
      }
    });
    
    const sortedEvents = events.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    console.log('Final events count:', sortedEvents.length);
    return sortedEvents;
  } catch (error: any) {
    console.error("Error getting performer events:", error);
    throw new Error(error.message);
  }
};

// Удаление мероприятия
export const deleteEvent = async (eventId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'events', eventId));
  } catch (error: any) {
    console.error("Error deleting event:", error);
    throw new Error(error.message);
  }
}; 