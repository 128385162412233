import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Paper, 
  Alert,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Avatar,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  FormHelperText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { updateUserProfile } from '../services/auth';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { styled } from '@mui/material/styles';
import AddLinkIcon from '@mui/icons-material/AddLink';
import DeleteIcon from '@mui/icons-material/Delete';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';

// Компонент для загрузки файлов
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Список типов услуг с соответствием внутренним значениям
interface ServiceCategoryOption {
  display: string;  // Отображаемое название
  value: string;    // Значение в базе данных
}

const serviceCategories: ServiceCategoryOption[] = [
  { display: 'Музыка/DJ', value: 'music' },
  { display: 'Площадки и помещения', value: 'venue' },
  { display: 'Фото', value: 'photography' },
  { display: 'Видеосъемка', value: 'videography' },
  { display: 'Кейтеринг и обслуживание', value: 'catering' },
  { display: 'Декор и оформление', value: 'decoration' },
  { display: 'Ведущие и аниматоры', value: 'entertainment' },
  { display: 'Транспорт', value: 'transport' },
  { display: 'Техническое обеспечение', value: 'technical' },
  { display: 'Другое', value: 'other' }
];

const ProfileSetupPerformer: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser, refreshUserData } = useAuth();
  const storage = getStorage();
  
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  // Информация о пользователе
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [location, setLocation] = useState('');
  const [website, setWebsite] = useState('');
  
  // Дополнительная информация для исполнителя
  const [serviceCategory, setServiceCategory] = useState('');
  const [priceRange, setPriceRange] = useState('');
  const [skills, setSkills] = useState<string[]>([]);
  const [portfolioLinks, setPortfolioLinks] = useState<string[]>(['']);
  const [certificates, setCertificates] = useState<File[]>([]);
  const [certificateUrls, setCertificateUrls] = useState<string[]>([]);
  
  const steps = [
    'Основная информация',
    'Профессиональная информация',
    'Портфолио и документы'
  ];

  const handleNext = () => {
    if (activeStep === 0 && (!fullName || !phone)) {
      setError('Пожалуйста, заполните все обязательные поля');
      return;
    }
    
    if (activeStep === 1 && !serviceCategory) {
      setError('Пожалуйста, выберите категорию услуг');
      return;
    }
    
    setError('');
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setAvatar(file);
      setAvatarUrl(URL.createObjectURL(file));
    }
  };
  
  const handleCertificateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setCertificates([...certificates, file]);
      setCertificateUrls([...certificateUrls, URL.createObjectURL(file)]);
    }
  };
  
  const handleRemoveCertificate = (index: number) => {
    const newCertificates = [...certificates];
    const newCertificateUrls = [...certificateUrls];
    
    newCertificates.splice(index, 1);
    newCertificateUrls.splice(index, 1);
    
    setCertificates(newCertificates);
    setCertificateUrls(newCertificateUrls);
  };
  
  const handleAddPortfolioLink = () => {
    setPortfolioLinks([...portfolioLinks, '']);
  };
  
  const handleChangePortfolioLink = (index: number, value: string) => {
    const newLinks = [...portfolioLinks];
    newLinks[index] = value;
    setPortfolioLinks(newLinks);
  };
  
  const handleRemovePortfolioLink = (index: number) => {
    const newLinks = [...portfolioLinks];
    newLinks.splice(index, 1);
    setPortfolioLinks(newLinks);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!fullName || !phone || !serviceCategory) {
      setError('Пожалуйста, заполните все обязательные поля');
      return;
    }
    
    if (!currentUser) {
      setError('Пользователь не авторизован');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      
      let profileImageUrl = '';
      let certificateFileUrls: string[] = [];
      
      // Загружаем аватар, если он был выбран
      if (avatar) {
        const storageRef = ref(storage, `avatars/${currentUser.uid}`);
        await uploadBytes(storageRef, avatar);
        profileImageUrl = await getDownloadURL(storageRef);
      }
      
      // Загружаем сертификаты
      for (let i = 0; i < certificates.length; i++) {
        const storageRef = ref(storage, `certificates/${currentUser.uid}/${i}-${certificates[i].name}`);
        await uploadBytes(storageRef, certificates[i]);
        const url = await getDownloadURL(storageRef);
        certificateFileUrls.push(url);
      }
      
      // Фильтруем пустые ссылки на портфолио
      const filteredPortfolioLinks = portfolioLinks.filter(link => link.trim() !== '');
      
      // Создаем объект с данными профиля
      const profileData = {
        fullName,
        phone,
        profileImageUrl,
        aboutMe,
        location,
        website,
        userType: 'performer',
        serviceCategory,
        priceRange,
        skills,
        portfolioLinks: filteredPortfolioLinks,
        certificateUrls: certificateFileUrls,
        profileCompleted: true,
        createdAt: new Date().toISOString(),
      };
      
      console.log("Updating performer profile:", profileData);
      
      // Обновляем профиль пользователя в Firestore
      await updateUserProfile(currentUser.uid, profileData);
      
      // Refresh user data in context
      await refreshUserData();
      
      // Перенаправляем на дашборд
      navigate('/dashboard');
    } catch (err: any) {
      console.error(err);
      setError('Ошибка при сохранении профиля. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        py: 6,
        backgroundColor: theme.palette.background.default
      }}
    >
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <Typography 
              variant="h4" 
              component="h1"
              sx={{ 
                fontWeight: 700,
                mb: 1
              }}
            >
              Настройка профиля исполнителя
            </Typography>
            <Typography color="textSecondary">
              Заполните информацию о себе, чтобы клиенты могли находить вас для своих мероприятий
            </Typography>
          </Box>

          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {error && (
            <Alert severity="error" sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit}>
            {activeStep === 0 ? (
              // Шаг 1: Основная информация
              <Box>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                    <Box sx={{ mb: 2 }}>
                      <Avatar
                        src={avatarUrl}
                        sx={{
                          width: 120,
                          height: 120,
                          mx: 'auto',
                          mb: 2,
                          border: `4px solid ${theme.palette.primary.main}20`,
                          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                          bgcolor: theme.palette.primary.main
                        }}
                      >
                        {!avatarUrl && <MicExternalOnIcon sx={{ fontSize: 60 }} />}
                      </Avatar>
                      
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<CloudUploadIcon />}
                        size="small"
                      >
                        Загрузить фото
                        <VisuallyHiddenInput 
                          type="file" 
                          accept="image/*"
                          onChange={handleAvatarChange}
                        />
                      </Button>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} md={8}>
                    <TextField
                      label="ФИО *"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                      sx={{ mb: 2 }}
                    />
                    
                    <TextField
                      label="Телефон *"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      sx={{ mb: 2 }}
                      placeholder="+7 (999) 123-45-67"
                    />
                    
                    <TextField
                      label="О себе"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      multiline
                      rows={4}
                      value={aboutMe}
                      onChange={(e) => setAboutMe(e.target.value)}
                      placeholder="Расскажите о себе, своем опыте и квалификации"
                      sx={{ mb: 2 }}
                    />
                  </Grid>
                </Grid>
                
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ py: 1.5, px: 4 }}
                  >
                    Далее
                  </Button>
                </Box>
              </Box>
            ) : activeStep === 1 ? (
              // Шаг 2: Профессиональная информация
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth required sx={{ mb: 3 }}>
                      <InputLabel>Категория услуг</InputLabel>
                      <Select
                        value={serviceCategory}
                        label="Категория услуг"
                        onChange={(e) => setServiceCategory(e.target.value)}
                      >
                        {serviceCategories.map((category) => (
                          <MenuItem key={category.value} value={category.value}>
                            {category.display}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Выберите основную категорию ваших услуг</FormHelperText>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField
                      label="Ценовой диапазон"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={priceRange}
                      onChange={(e) => setPriceRange(e.target.value)}
                      placeholder="Например: от 5000 руб./час или от 50000 руб. за мероприятие"
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="tags-skills"
                      options={[]}
                      freeSolo
                      value={skills}
                      onChange={(_, newValue) => {
                        setSkills(newValue);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            sx={{ 
                              backgroundColor: `${theme.palette.primary.main}15`,
                              '& .MuiChip-deleteIcon': {
                                color: theme.palette.primary.main
                              }
                            }}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Навыки и специализации"
                          placeholder="Добавьте навык и нажмите Enter"
                          helperText="Добавьте ключевые навыки и специализации, которые помогут клиентам найти вас"
                        />
                      )}
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField
                      label="Местоположение"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      placeholder="Например: Москва"
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                </Grid>
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                  <Button
                    onClick={handleBack}
                    sx={{ py: 1.5, px: 4 }}
                  >
                    Назад
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ py: 1.5, px: 4 }}
                  >
                    Далее
                  </Button>
                </Box>
              </Box>
            ) : (
              // Шаг 3: Портфолио и документы
              <Box>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Ссылки на портфолио
                </Typography>
                
                {portfolioLinks.map((link, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                      label={`Ссылка ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      value={link}
                      onChange={(e) => handleChangePortfolioLink(index, e.target.value)}
                      placeholder="https://example.com"
                      sx={{ mr: 1 }}
                    />
                    {index === portfolioLinks.length - 1 ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleAddPortfolioLink}
                        sx={{ minWidth: 40, width: 40, height: 40, p: 0 }}
                      >
                        <AddLinkIcon />
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemovePortfolioLink(index)}
                        sx={{ minWidth: 40, width: 40, height: 40, p: 0 }}
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                  </Box>
                ))}
                
                <Typography 
                  variant="body2" 
                  color="textSecondary" 
                  sx={{ mb: 4, mt: 1, fontStyle: 'italic' }}
                >
                  Добавьте ссылки на ваши работы, социальные сети, личный сайт и т.д.
                </Typography>
                
                <Divider sx={{ my: 4 }} />
                
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Документы и сертификаты
                </Typography>
                
                <Box sx={{ mb: 3 }}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                  >
                    Загрузить документ
                    <VisuallyHiddenInput 
                      type="file" 
                      onChange={handleCertificateChange}
                    />
                  </Button>
                  
                  <Typography 
                    variant="body2" 
                    color="textSecondary" 
                    sx={{ mt: 1, fontStyle: 'italic' }}
                  >
                    Загрузите документы, подтверждающие вашу квалификацию (дипломы, сертификаты и т.д.)
                  </Typography>
                </Box>
                
                {certificateUrls.length > 0 && (
                  <Grid container spacing={2} sx={{ mb: 4 }}>
                    {certificateUrls.map((url, index) => (
                      <Grid item xs={6} sm={4} md={3} key={index}>
                        <Box
                          sx={{
                            position: 'relative',
                            height: 120,
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: 1,
                            overflow: 'hidden',
                            '&:hover .delete-button': {
                              opacity: 1,
                            },
                          }}
                        >
                          <Box
                            component="img"
                            src={url}
                            alt={`Сертификат ${index + 1}`}
                            sx={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          <Button
                            className="delete-button"
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => handleRemoveCertificate(index)}
                            sx={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              minWidth: 30,
                              width: 30,
                              height: 30,
                              p: 0,
                              opacity: 0,
                              transition: 'opacity 0.2s',
                            }}
                          >
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}
                
                <Divider sx={{ my: 4 }} />
                
                <TextField
                  label="Веб-сайт"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  placeholder="https://example.com"
                  sx={{ mb: 4 }}
                />
                
                <Typography variant="body2" color="textSecondary" sx={{ mb: 4 }}>
                  * Обязательные поля отмечены звездочкой
                </Typography>
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    onClick={handleBack}
                    sx={{ py: 1.5, px: 4 }}
                  >
                    Назад
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    sx={{ py: 1.5, px: 4 }}
                  >
                    {loading ? 'Сохранение...' : 'Сохранить профиль'}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ProfileSetupPerformer; 