import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Paper, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField, 
  Slider, 
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Chip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  InputAdornment,
  Alert,
  CircularProgress,
  useTheme,
  SelectChangeEvent
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserData, getAllPerformers } from '../services/auth';
import { ServiceType } from '../services/event';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import InfoIcon from '@mui/icons-material/Info';

// Map service types to readable Russian names
const serviceTypeLabels: Record<string, string> = {
  'venue': 'Площадки и помещения',
  'catering': 'Кейтеринг и обслуживание',
  'photography': 'Фото',
  'videography': 'Видеосъемка',
  'music': 'Музыка/DJ',
  'decoration': 'Декор и оформление',
  'entertainment': 'Ведущие и аниматоры',
  'transport': 'Транспорт',
  'technical': 'Техническое обеспечение',
  'other': 'Другое'
};

interface PerformersListProps {
  onSelectPerformer?: (performer: UserData) => void;
}

const PerformersList: React.FC<PerformersListProps> = ({ onSelectPerformer }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  // State for performers and loading
  const [performers, setPerformers] = useState<UserData[]>([]);
  const [filteredPerformers, setFilteredPerformers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  // State for filters
  const [serviceCategory, setServiceCategory] = useState<string>('');
  const [location, setLocation] = useState('');
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 500000]);
  const [searchQuery, setSearchQuery] = useState('');
  
  // State for performer details modal
  const [selectedPerformer, setSelectedPerformer] = useState<UserData | null>(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  
  // Fetch performers on component mount
  useEffect(() => {
    const fetchPerformers = async () => {
      try {
        setLoading(true);
        const performersData = await getAllPerformers();
        setPerformers(performersData);
        setFilteredPerformers(performersData);
      } catch (err: any) {
        console.error(err);
        setError('Ошибка при загрузке исполнителей');
      } finally {
        setLoading(false);
      }
    };
    
    fetchPerformers();
  }, []);
  
  // Apply filters when filter values change
  useEffect(() => {
    applyFilters();
  }, [serviceCategory, location, priceRange, searchQuery, performers]);
  
  // Apply all filters
  const applyFilters = () => {
    let result = [...performers];
    
    // Filter by service category
    if (serviceCategory) {
      result = result.filter(performer => performer.serviceCategory === serviceCategory);
    }
    
    // Filter by location
    if (location) {
      result = result.filter(performer => 
        performer.location && performer.location.toLowerCase().includes(location.toLowerCase())
      );
    }
    
    // Filter by price range
    result = result.filter(performer => {
      if (!performer.priceRange) return true;
      
      const [minStr, maxStr] = performer.priceRange.split('-');
      const performerMin = parseInt(minStr, 10);
      const performerMax = parseInt(maxStr, 10);
      
      if (isNaN(performerMin) || isNaN(performerMax)) return true;
      
      // Check if ranges overlap
      return performerMax >= priceRange[0] && performerMin <= priceRange[1];
    });
    
    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(performer => 
        (performer.fullName && performer.fullName.toLowerCase().includes(query)) ||
        (performer.aboutMe && performer.aboutMe.toLowerCase().includes(query)) ||
        (performer.skills && performer.skills.some(skill => skill.toLowerCase().includes(query)))
      );
    }
    
    setFilteredPerformers(result);
  };
  
  // Handle filter changes
  const handleServiceCategoryChange = (event: SelectChangeEvent) => {
    setServiceCategory(event.target.value);
  };
  
  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation(event.target.value);
  };
  
  const handlePriceRangeChange = (event: Event, newValue: number | number[]) => {
    setPriceRange(newValue as [number, number]);
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  
  const resetFilters = () => {
    setServiceCategory('');
    setLocation('');
    setPriceRange([0, 500000]);
    setSearchQuery('');
  };
  
  // Handle details dialog
  const openDetails = (performer: UserData) => {
    setSelectedPerformer(performer);
    setDetailsOpen(true);
  };
  
  const closeDetails = () => {
    setDetailsOpen(false);
  };
  
  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };
  
  // Format price range for display
  const formatPrice = (price: string | undefined) => {
    if (!price) return 'Цена не указана';
    
    return `${price} ₽`;
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ minHeight: '100vh', py: 4, backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBackToDashboard}
            sx={{ mr: 2 }}
          >
            Вернуться
          </Button>
          <Typography variant="h4" component="h1" fontWeight={700}>
            Исполнители
          </Typography>
        </Box>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {/* Filters */}
        <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h6" fontWeight={600}>
              Фильтры
            </Typography>
            <Button 
              startIcon={<FilterAltOffIcon />} 
              onClick={resetFilters}
              size="small"
            >
              Сбросить
            </Button>
          </Box>
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Поиск по имени, описанию или навыкам"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="service-category-label">Категория услуг</InputLabel>
                <Select
                  labelId="service-category-label"
                  id="service-category"
                  value={serviceCategory}
                  label="Категория услуг"
                  onChange={handleServiceCategoryChange}
                >
                  <MenuItem value="">Все категории</MenuItem>
                  {Object.entries(serviceTypeLabels).map(([value, label]) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Местоположение"
                variant="outlined"
                value={location}
                onChange={handleLocationChange}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Typography id="price-range-slider" gutterBottom>
                Ценовой диапазон: {priceRange[0].toLocaleString()} ₽ - {priceRange[1].toLocaleString()} ₽
              </Typography>
              <Slider
                value={priceRange}
                onChange={handlePriceRangeChange}
                valueLabelDisplay="auto"
                min={0}
                max={500000}
                step={5000}
                valueLabelFormat={(value) => `${value.toLocaleString()} ₽`}
                aria-labelledby="price-range-slider"
              />
            </Grid>
          </Grid>
        </Paper>
        
        {/* Results */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" fontWeight={600} gutterBottom>
            Результаты ({filteredPerformers.length})
          </Typography>
          
          {filteredPerformers.length === 0 ? (
            <Paper 
              elevation={0} 
              sx={{ 
                p: 4, 
                textAlign: 'center',
                border: '1px dashed',
                borderColor: 'divider',
                borderRadius: 2
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Исполнители не найдены. Попробуйте изменить параметры поиска.
              </Typography>
            </Paper>
          ) : (
            <Grid container spacing={3}>
              {filteredPerformers.map((performer) => (
                <Grid item xs={12} sm={6} md={4} key={performer.uid}>
                  <Card sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    borderRadius: 2,
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)'
                    }
                  }}>
                    <CardMedia
                      component="img"
                      height={200}
                      image={performer.profileImageUrl || 'https://via.placeholder.com/300x200?text=No+Image'}
                      alt={performer.fullName || 'Исполнитель'}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" component="h3" gutterBottom noWrap>
                        {performer.fullName || 'Имя не указано'}
                      </Typography>
                      
                      <Chip 
                        label={serviceTypeLabels[performer.serviceCategory as ServiceType] || performer.serviceCategory || 'Категория не указана'} 
                        size="small" 
                        color="primary" 
                        sx={{ mb: 2 }} 
                      />
                      
                      <Typography variant="body2" color="primary" fontWeight={600} gutterBottom>
                        {formatPrice(performer.priceRange)}
                      </Typography>
                      
                      <Typography variant="body2" color="textSecondary" sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        minHeight: '4.5em'
                      }}>
                        {performer.aboutMe || 'Информация отсутствует'}
                      </Typography>
                      
                      {performer.location && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <LocationOnIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
                          <Typography variant="body2" color="textSecondary" noWrap>
                            {performer.location}
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                    
                    <Divider />
                    
                    <CardActions>
                      <Button 
                        fullWidth 
                        startIcon={<InfoIcon />}
                        onClick={() => openDetails(performer)}
                      >
                        Подробная информация
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
      
      {/* Performer Details Dialog */}
      <Dialog 
        open={detailsOpen} 
        onClose={closeDetails}
        maxWidth="md"
        fullWidth
      >
        {selectedPerformer && (
          <>
            <DialogTitle>
              <Typography variant="h5" fontWeight={600}>
                {selectedPerformer.fullName || 'Исполнитель'}
              </Typography>
              <Chip 
                label={serviceTypeLabels[selectedPerformer.serviceCategory as ServiceType] || selectedPerformer.serviceCategory || 'Категория не указана'} 
                size="small" 
                color="primary" 
                sx={{ mt: 1 }} 
              />
            </DialogTitle>
            
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <img 
                    src={selectedPerformer.profileImageUrl || 'https://via.placeholder.com/300x300?text=No+Image'} 
                    alt={selectedPerformer.fullName || 'Исполнитель'} 
                    style={{ 
                      width: '100%', 
                      borderRadius: '8px', 
                      maxHeight: '300px',
                      objectFit: 'cover' 
                    }} 
                  />
                  
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" color="primary" gutterBottom>
                      {formatPrice(selectedPerformer.priceRange)}
                    </Typography>
                    
                    <List dense disablePadding>
                      {selectedPerformer.phone && (
                        <ListItem disablePadding sx={{ mt: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <LocalPhoneIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={selectedPerformer.phone} />
                        </ListItem>
                      )}
                      
                      {selectedPerformer.email && (
                        <ListItem disablePadding sx={{ mt: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <EmailIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={selectedPerformer.email} />
                        </ListItem>
                      )}
                      
                      {selectedPerformer.location && (
                        <ListItem disablePadding sx={{ mt: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <LocationOnIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={selectedPerformer.location} />
                        </ListItem>
                      )}
                      
                      {selectedPerformer.website && (
                        <ListItem disablePadding sx={{ mt: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <LanguageIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText 
                            primary={
                              <a 
                                href={selectedPerformer.website} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: theme.palette.primary.main }}
                              >
                                {selectedPerformer.website}
                              </a>
                            } 
                          />
                        </ListItem>
                      )}
                    </List>
                  </Box>
                </Grid>
                
                <Grid item xs={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    О исполнителе
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {selectedPerformer.aboutMe || 'Информация отсутствует'}
                  </Typography>
                  
                  {selectedPerformer.skills && selectedPerformer.skills.length > 0 && (
                    <>
                      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Навыки
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selectedPerformer.skills.map((skill, index) => (
                          <Chip key={index} label={skill} size="small" />
                        ))}
                      </Box>
                    </>
                  )}
                  
                  {selectedPerformer.portfolioLinks && selectedPerformer.portfolioLinks.length > 0 && (
                    <>
                      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Портфолио
                      </Typography>
                      <List dense>
                        {selectedPerformer.portfolioLinks.map((link, index) => (
                          <ListItem key={index} disablePadding>
                            <Button 
                              href={link} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              style={{ textTransform: 'none' }}
                            >
                              {link}
                            </Button>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                  
                  {selectedPerformer.certificateUrls && selectedPerformer.certificateUrls.length > 0 && (
                    <>
                      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Сертификаты
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {selectedPerformer.certificateUrls.map((url, index) => (
                          <a 
                            key={index} 
                            href={url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                          >
                            <img 
                              src={url} 
                              alt={`Сертификат ${index + 1}`}
                              style={{ 
                                width: '100px', 
                                height: '75px', 
                                objectFit: 'cover',
                                borderRadius: '4px'
                              }} 
                            />
                          </a>
                        ))}
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            
            <DialogActions>
              <Button onClick={closeDetails}>Закрыть</Button>
              {onSelectPerformer && (
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => {
                    if (selectedPerformer) {
                      onSelectPerformer(selectedPerformer);
                      closeDetails();
                    }
                  }}
                >
                  Выбрать исполнителя
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default PerformersList; 