import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface ProtectedRouteProps {
  children: React.ReactNode;
  redirectTo?: string;
}

// Компонент для защищенных маршрутов (только для авторизованных пользователей)
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
  children, 
  redirectTo = '/login' 
}) => {
  const { currentUser, userData, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Если пользователь авторизован, но не завершил настройку профиля
    if (currentUser && userData && !userData.profileCompleted) {
      const setupPath = userData.userType === 'organizer' 
        ? '/profile-setup/organizer'
        : '/profile-setup/performer';
      navigate(setupPath);
    }
  }, [currentUser, userData, navigate]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (!currentUser) {
    return <Navigate to={redirectTo} />;
  }

  return <>{children}</>;
};

// Компонент для маршрутов, доступных только НЕавторизованным пользователям
export const PublicRoute: React.FC<ProtectedRouteProps> = ({ 
  children, 
  redirectTo = '/dashboard' 
}) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (currentUser) {
    return <Navigate to={redirectTo} />;
  }

  return <>{children}</>;
};

// Компонент для маршрута профиля, который проверяет завершенность профиля
export const ProfileSetupRoute: React.FC<ProtectedRouteProps> = ({ 
  children, 
  redirectTo = '/dashboard' 
}) => {
  const { currentUser, userData, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Если профиль пользователя уже заполнен, перенаправляем на дашборд
    if (currentUser && userData && userData.profileCompleted) {
      navigate(redirectTo);
    }
  }, [currentUser, userData, navigate, redirectTo]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}; 