import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import ProfileSetupOrganizer from './pages/ProfileSetupOrganizer';
import ProfileSetupPerformer from './pages/ProfileSetupPerformer';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute, PublicRoute, ProfileSetupRoute } from './components/auth/ProtectedRoute';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CreateEvent from './pages/CreateEvent';
import EventDetails from './pages/EventDetails';
import AdminPage from './pages/AdminPage';
import PerformersList from './pages/PerformersList';
import PerformerOrders from './pages/PerformerOrders';
import Negotiations from './pages/Negotiations';
import NegotiationDetails from './pages/NegotiationDetails';
import Calendar from './pages/Calendar';
import { Toaster } from 'react-hot-toast';

// Создаем тему приложения
const theme = createTheme({
  palette: {
    primary: {
      main: '#f5c518', // Желтый цвет для бренда
      contrastText: '#000',
    },
    secondary: {
      main: '#2d3748', // Темно-синий для акцентов
    },
    background: {
      default: '#f8f9fa',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
        },
      },
    },
  },
});

// Компонент загрузки
const LoadingScreen = () => (
  <Box 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default
    }}
  >
    <CircularProgress color="primary" size={60} thickness={4} />
  </Box>
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <React.Suspense fallback={<LoadingScreen />}>
            <Routes>
              <Route path="/" element={<Home />} />
              
              {/* Маршруты для аутентификации */}
              <Route path="/login" element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              } />
              
              <Route path="/register" element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              } />
              
              {/* Маршруты для настройки профиля */}
              <Route path="/profile-setup/organizer" element={
                <ProtectedRoute>
                  <ProfileSetupRoute>
                    <ProfileSetupOrganizer />
                  </ProfileSetupRoute>
                </ProtectedRoute>
              } />
              
              <Route path="/profile-setup/performer" element={
                <ProtectedRoute>
                  <ProfileSetupRoute>
                    <ProfileSetupPerformer />
                  </ProfileSetupRoute>
                </ProtectedRoute>
              } />
              
              {/* Маршруты для мероприятий */}
              <Route path="/events/create" element={
                <ProtectedRoute>
                  <CreateEvent />
                </ProtectedRoute>
              } />
              
              <Route path="/events/:eventId" element={
                <ProtectedRoute>
                  <EventDetails />
                </ProtectedRoute>
              } />
              
              {/* Маршрут для дашборда */}
              <Route path="/dashboard" element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } />
              
              {/* Маршрут для списка исполнителей */}
              <Route path="/performers" element={
                <ProtectedRoute>
                  <PerformersList />
                </ProtectedRoute>
              } />
              
              {/* Маршрут для списка заказов исполнителя */}
              <Route path="/orders" element={
                <ProtectedRoute>
                  <PerformerOrders />
                </ProtectedRoute>
              } />
              
              {/* Маршрут для списка переговоров */}
              <Route path="/negotiations" element={
                <ProtectedRoute>
                  <Negotiations />
                </ProtectedRoute>
              } />
              
              {/* Маршрут для деталей переговоров */}
              <Route path="/negotiations/:negotiationId" element={
                <ProtectedRoute>
                  <NegotiationDetails />
                </ProtectedRoute>
              } />
              
              {/* Маршрут для календаря */}
              <Route path="/calendar" element={
                <ProtectedRoute>
                  <Calendar />
                </ProtectedRoute>
              } />
              
              {/* Маршрут для админки */}
              <Route path="/admin" element={
                <ProtectedRoute>
                  <AdminPage />
                </ProtectedRoute>
              } />
              
              {/* Если маршрут не найден */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </React.Suspense>
          <Toaster 
            position="top-right"
            toastOptions={{
              style: {
                background: '#333',
                color: '#fff',
                borderRadius: '8px',
                padding: '16px'
              },
              success: {
                iconTheme: {
                  primary: '#f5c518',
                  secondary: '#000'
                }
              }
            }}
          />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
