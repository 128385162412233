import { collection, query, where, getDocs, doc, addDoc, updateDoc, deleteDoc, getDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { Event, getEventById } from './event';
import { Negotiation, getNegotiationById } from './negotiations';
import { getUserData } from './auth';

// Types for task status
export type TaskStatus = 'pending' | 'in_progress' | 'completed' | 'overdue';

// Interface for task data
export interface Task {
  id?: string;
  title: string;
  description: string;
  deadline: string; // ISO date string
  createdAt: string;
  updatedAt: string;
  status: TaskStatus;
  eventId: string;
  negotiationId?: string;
  createdBy: string; // User ID of creator
  assignedTo: string; // User ID of assignee
  isForOrganizer: boolean; // Whether the task is for the organizer
  isForPerformer: boolean; // Whether the task is for the performer
  notificationSent: boolean; // Whether a deadline notification has been sent
  comments?: {
    authorId: string;
    text: string;
    timestamp: string;
  }[];
}

// Add a new task
export const addTask = async (taskData: Omit<Task, 'id' | 'createdAt' | 'updatedAt' | 'status' | 'notificationSent'>): Promise<string> => {
  try {
    // Validate if this event and negotiation combination exists
    if (taskData.negotiationId) {
      const negotiation = await getNegotiationById(taskData.negotiationId);
      if (!negotiation) {
        throw new Error('Negotiation not found');
      }
      if (negotiation.eventId !== taskData.eventId) {
        throw new Error('Negotiation is not associated with this event');
      }
    }

    const timestamp = new Date().toISOString();
    const task: Omit<Task, 'id'> = {
      ...taskData,
      createdAt: timestamp,
      updatedAt: timestamp,
      status: 'pending',
      notificationSent: false
    };

    const docRef = await addDoc(collection(db, 'tasks'), task);
    return docRef.id;
  } catch (error: any) {
    console.error('Error adding task:', error);
    throw new Error(error.message);
  }
};

// Update a task
export const updateTask = async (taskId: string, updates: Partial<Omit<Task, 'id' | 'createdAt'>>): Promise<void> => {
  try {
    const taskRef = doc(db, 'tasks', taskId);
    await updateDoc(taskRef, {
      ...updates,
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    console.error('Error updating task:', error);
    throw new Error(error.message);
  }
};

// Mark a task as completed
export const completeTask = async (taskId: string): Promise<void> => {
  try {
    const taskRef = doc(db, 'tasks', taskId);
    await updateDoc(taskRef, {
      status: 'completed',
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    console.error('Error completing task:', error);
    throw new Error(error.message);
  }
};

// Add a comment to a task
export const addCommentToTask = async (taskId: string, authorId: string, text: string): Promise<void> => {
  try {
    const taskRef = doc(db, 'tasks', taskId);
    const comment = {
      authorId,
      text,
      timestamp: new Date().toISOString()
    };
    
    await updateDoc(taskRef, {
      comments: arrayUnion(comment),
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    console.error('Error adding comment to task:', error);
    throw new Error(error.message);
  }
};

// Get a specific task
export const getTaskById = async (taskId: string): Promise<Task | null> => {
  try {
    const taskDoc = await getDoc(doc(db, 'tasks', taskId));
    if (!taskDoc.exists()) {
      return null;
    }
    
    return { id: taskDoc.id, ...taskDoc.data() } as Task;
  } catch (error: any) {
    console.error('Error getting task:', error);
    throw new Error(error.message);
  }
};

// Get all tasks for an event
export const getTasksByEventId = async (eventId: string): Promise<Task[]> => {
  try {
    const tasksQuery = query(
      collection(db, 'tasks'),
      where('eventId', '==', eventId)
    );
    
    const querySnapshot = await getDocs(tasksQuery);
    const tasks: Task[] = [];
    
    querySnapshot.forEach((doc) => {
      tasks.push({ id: doc.id, ...doc.data() } as Task);
    });
    
    return tasks.sort((a, b) => 
      new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
    );
  } catch (error: any) {
    console.error('Error getting tasks for event:', error);
    throw new Error(error.message);
  }
};

// Get all tasks for a negotiation
export const getTasksByNegotiationId = async (negotiationId: string): Promise<Task[]> => {
  try {
    const tasksQuery = query(
      collection(db, 'tasks'),
      where('negotiationId', '==', negotiationId)
    );
    
    const querySnapshot = await getDocs(tasksQuery);
    const tasks: Task[] = [];
    
    querySnapshot.forEach((doc) => {
      tasks.push({ id: doc.id, ...doc.data() } as Task);
    });
    
    return tasks.sort((a, b) => 
      new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
    );
  } catch (error: any) {
    console.error('Error getting tasks for negotiation:', error);
    throw new Error(error.message);
  }
};

// Get tasks assigned to a user
export const getTasksAssignedToUser = async (userId: string): Promise<Task[]> => {
  try {
    const tasksQuery = query(
      collection(db, 'tasks'),
      where('assignedTo', '==', userId)
    );
    
    const querySnapshot = await getDocs(tasksQuery);
    const tasks: Task[] = [];
    
    querySnapshot.forEach((doc) => {
      tasks.push({ id: doc.id, ...doc.data() } as Task);
    });
    
    return tasks.sort((a, b) => 
      new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
    );
  } catch (error: any) {
    console.error('Error getting tasks assigned to user:', error);
    throw new Error(error.message);
  }
};

// Get tasks created by a user
export const getTasksCreatedByUser = async (userId: string): Promise<Task[]> => {
  try {
    const tasksQuery = query(
      collection(db, 'tasks'),
      where('createdBy', '==', userId)
    );
    
    const querySnapshot = await getDocs(tasksQuery);
    const tasks: Task[] = [];
    
    querySnapshot.forEach((doc) => {
      tasks.push({ id: doc.id, ...doc.data() } as Task);
    });
    
    return tasks.sort((a, b) => 
      new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
    );
  } catch (error: any) {
    console.error('Error getting tasks created by user:', error);
    throw new Error(error.message);
  }
};

// Delete a task
export const deleteTask = async (taskId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'tasks', taskId));
  } catch (error: any) {
    console.error('Error deleting task:', error);
    throw new Error(error.message);
  }
};

// Check for due tasks and mark as sent notification
export const checkAndMarkDueTasks = async (): Promise<Task[]> => {
  try {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    // Query for tasks due tomorrow that haven't had notifications sent
    const tasksQuery = query(
      collection(db, 'tasks'),
      where('status', '!=', 'completed'),
      where('notificationSent', '==', false)
    );
    
    const querySnapshot = await getDocs(tasksQuery);
    const dueTasks: Task[] = [];
    
    querySnapshot.forEach((doc) => {
      const task = { id: doc.id, ...doc.data() } as Task;
      const deadline = new Date(task.deadline);
      
      // Check if task is due tomorrow
      if (deadline <= tomorrow && deadline >= now) {
        dueTasks.push(task);
        
        // Mark notification as sent
        updateDoc(doc.ref, {
          notificationSent: true,
          updatedAt: new Date().toISOString()
        });
      }
    });
    
    return dueTasks;
  } catch (error: any) {
    console.error('Error checking due tasks:', error);
    throw new Error(error.message);
  }
};

// Convert tasks to calendar items
export const convertTasksToCalendarItems = async (tasks: Task[]): Promise<any[]> => {
  try {
    return tasks.map(task => ({
      id: `task-${task.id}`,
      title: task.title,
      description: task.description,
      startDate: task.deadline,
      endDate: task.deadline,
      allDay: true,
      type: 'task' as any,
      status: task.status,
      relatedEventId: task.eventId,
      relatedNegotiationId: task.negotiationId,
      color: getTaskStatusColor(task.status),
      userId: task.assignedTo,
      completed: task.status === 'completed'
    }));
  } catch (error: any) {
    console.error('Error converting tasks to calendar items:', error);
    throw new Error(error.message);
  }
};

// Helper function to get color based on task status
const getTaskStatusColor = (status: TaskStatus): string => {
  switch (status) {
    case 'pending': return '#fd8d3c'; // Orange
    case 'in_progress': return '#3182bd'; // Blue
    case 'completed': return '#31a354'; // Green
    case 'overdue': return '#e6550d'; // Red
    default: return '#fd8d3c'; // Orange
  }
};

// Helper function to get label based on task status
export const getTaskStatusLabel = (status: TaskStatus): string => {
  switch (status) {
    case 'pending': return 'Ожидает';
    case 'in_progress': return 'В процессе';
    case 'completed': return 'Завершено';
    case 'overdue': return 'Просрочено';
    default: return status;
  }
}; 