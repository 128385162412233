import { UserData } from '../services/auth';

// Sample performers for the "photography" category
export const photographyPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "photo.master@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Максим Светлов",
    phone: "+7 (903) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1552168324-d612d77725e3?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный фотограф с опытом более 12 лет. Специализируюсь на свадебной и событийной фотографии. Создаю яркие, эмоциональные и живые снимки, которые сохранят ваши воспоминания надолго.",
    location: "Москва",
    website: "https://maxsvetlov.ru",
    serviceCategory: "photography",
    priceRange: "25000-50000",
    skills: ["Свадебная фотография", "Событийная фотография", "Портретная съемка", "Репортажная съемка", "Обработка фото"],
    portfolioLinks: [
      "https://www.instagram.com/max.svetlov",
      "https://500px.com/max_svetlov"
    ],
    certificateUrls: [
      "https://example.com/certificates/photography-master.jpg"
    ]
  },
  {
    email: "elena.photos@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Елена Кадрова",
    phone: "+7 (916) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Фотограф с художественным подходом к работе. Специализируюсь на создании уникальных концептуальных портретов и репортажной съемке. Каждая фотография – это история, которую я помогаю рассказать.",
    location: "Санкт-Петербург",
    website: "https://elena-photo.ru",
    serviceCategory: "photography",
    priceRange: "20000-40000",
    skills: ["Художественная фотография", "Концептуальные портреты", "Репортажная съемка", "Ретушь", "Фото-арт"],
    portfolioLinks: [
      "https://www.behance.net/elena_kadrova",
      "https://vk.com/elena.photos"
    ],
    certificateUrls: [
      "https://example.com/certificates/art-photography.jpg"
    ]
  },
  {
    email: "drone.view@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Сергей Высоцкий",
    phone: "+7 (925) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1568792923760-d6cc19ccdada?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специалист по аэрофотосъемке и видео с дронов. Снимаю масштабные мероприятия с воздуха, создавая впечатляющие панорамы и обзоры. Имею все необходимые разрешения и сертификаты для работы с дронами.",
    location: "Москва",
    website: "https://droneview.ru",
    serviceCategory: "photography",
    priceRange: "30000-60000",
    skills: ["Аэрофотосъемка", "Съемка с дрона", "Панорамные снимки", "Видео с воздуха", "Обработка аэрофото"],
    portfolioLinks: [
      "https://www.youtube.com/droneview",
      "https://www.instagram.com/drone.view"
    ],
    certificateUrls: [
      "https://example.com/certificates/drone-license.jpg",
      "https://example.com/certificates/aerial-photography.jpg"
    ]
  },
  {
    email: "wedding.moments@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Анна и Дмитрий Свадебные",
    phone: "+7 (903) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1537633552985-df8429e8048b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Семейная пара фотографов, специализирующаяся на свадебной фотографии. Более 200 успешных проектов. Работаем в паре, что позволяет не упустить ни одного важного момента вашего особенного дня.",
    location: "Москва",
    website: "https://wedding-moments.ru",
    serviceCategory: "photography",
    priceRange: "40000-80000",
    skills: ["Свадебная фотография", "Love story", "Семейная фотосессия", "Репортажная съемка", "Художественная обработка"],
    portfolioLinks: [
      "https://www.instagram.com/wedding.moments",
      "https://vk.com/wedding_moments"
    ],
    certificateUrls: []
  },
  {
    email: "event.reporter@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Игорь Репортажников",
    phone: "+7 (915) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1558981852-8a170e1d45c7?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный фотограф-репортажник с опытом работы в ведущих изданиях. Специализируюсь на событийной и репортажной съемке корпоративных мероприятий, конференций и деловых встреч.",
    location: "Москва",
    website: "https://event-reporter.ru",
    serviceCategory: "photography",
    priceRange: "15000-35000",
    skills: ["Репортажная съемка", "Корпоративная фотография", "Пресс-съемка", "Фоторепортаж", "Оперативная обработка"],
    portfolioLinks: [
      "https://event-reporter.ru/portfolio",
      "https://www.instagram.com/event.reporter"
    ],
    certificateUrls: [
      "https://example.com/certificates/press-pass.jpg"
    ]
  },
  {
    email: "portrait.studio@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Мария Портретова",
    phone: "+7 (926) 678-9012",
    profileImageUrl: "https://images.unsplash.com/photo-1590486803833-1c5dc8ddd4c8?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный портретный фотограф. Специализируюсь на создании художественных портретов, которые раскрывают индивидуальность и характер человека. Имею собственную студию с профессиональным оборудованием.",
    location: "Санкт-Петербург",
    website: "https://portrait-studio.ru",
    serviceCategory: "photography",
    priceRange: "10000-30000",
    skills: ["Портретная фотография", "Студийная съемка", "Художественный портрет", "Бизнес-портрет", "Ретушь"],
    portfolioLinks: [
      "https://portrait-studio.ru/gallery",
      "https://www.instagram.com/maria.portrait"
    ],
    certificateUrls: [
      "https://example.com/certificates/portrait-master.jpg"
    ]
  },
  {
    email: "product.photo@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Алексей Товаров",
    phone: "+7 (917) 789-0123",
    profileImageUrl: "https://images.unsplash.com/photo-1587614382346-4ec70e388b28?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специалист по предметной и продуктовой фотографии. Создаю привлекательные изображения товаров для каталогов, сайтов и рекламных материалов. Имею опыт работы с ведущими брендами и интернет-магазинами.",
    location: "Москва",
    website: "https://product-photo.ru",
    serviceCategory: "photography",
    priceRange: "5000-20000",
    skills: ["Предметная съемка", "Продуктовая фотография", "Каталожная съемка", "Рекламная фотография", "Обработка в Photoshop"],
    portfolioLinks: [
      "https://product-photo.ru/portfolio",
      "https://www.behance.net/alexey_product"
    ],
    certificateUrls: []
  },
  {
    email: "fashion.photo@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Виктория Стильная",
    phone: "+7 (919) 890-1234",
    profileImageUrl: "https://images.unsplash.com/photo-1581403341630-a6e0b9d2d257?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Фэшн-фотограф с опытом работы для глянцевых журналов и модных брендов. Создаю стильные и концептуальные образы, работаю с профессиональными моделями и стилистами. Специализируюсь на эдиториал и рекламной съемке.",
    location: "Москва",
    website: "https://fashion-photo.ru",
    serviceCategory: "photography",
    priceRange: "30000-70000",
    skills: ["Фэшн-фотография", "Эдиториал", "Рекламная съемка", "Работа с моделями", "Глянцевая ретушь"],
    portfolioLinks: [
      "https://www.instagram.com/victoria.fashion",
      "https://fashion-photo.ru/portfolio"
    ],
    certificateUrls: [
      "https://example.com/certificates/fashion-photography.jpg"
    ]
  },
  {
    email: "architecture.photo@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Павел Зданиев",
    phone: "+7 (920) 901-2345",
    profileImageUrl: "https://images.unsplash.com/photo-1539635278303-d4002c07eae3?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специалист по архитектурной и интерьерной фотографии. Создаю технически совершенные и художественно выразительные снимки зданий, интерьеров и объектов недвижимости для девелоперов, архитекторов и дизайнеров.",
    location: "Санкт-Петербург",
    website: "https://architecture-photo.ru",
    serviceCategory: "photography",
    priceRange: "20000-50000",
    skills: ["Архитектурная фотография", "Интерьерная съемка", "Съемка недвижимости", "Панорамная съемка", "HDR"],
    portfolioLinks: [
      "https://architecture-photo.ru/portfolio",
      "https://www.instagram.com/pavel.architecture"
    ],
    certificateUrls: []
  },
  {
    email: "concert.photo@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Денис Концертов",
    phone: "+7 (921) 012-3456",
    profileImageUrl: "https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Фотограф, специализирующийся на концертной и сценической съемке. Более 500 мероприятий в портфолио. Работаю с ведущими концертными площадками и музыкальными коллективами. Передаю энергию и атмосферу живых выступлений.",
    location: "Москва",
    website: "https://concert-photo.ru",
    serviceCategory: "photography",
    priceRange: "15000-40000",
    skills: ["Концертная фотография", "Сценическая съемка", "Музыкальная фотография", "Репортаж", "Работа в сложных условиях освещения"],
    portfolioLinks: [
      "https://concert-photo.ru/gallery",
      "https://www.instagram.com/denis.concert"
    ],
    certificateUrls: [
      "https://example.com/certificates/stage-photography.jpg"
    ]
  }
]; 