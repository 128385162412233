import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  Grid, 
  Paper,
  useTheme
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HeroSection: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        pt: { xs: 12, md: 16 },
        pb: { xs: 8, md: 12 },
        overflow: 'hidden',
        background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, #1A2238 100%)`,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'url("https://images.unsplash.com/photo-1511578314322-379afb476865?ixlib=rb-4.0.3&auto=format&fit=crop&w=2070&q=80")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.2,
          mixBlendMode: 'overlay',
        }
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box sx={{ color: 'white', position: 'relative', zIndex: 1 }}>
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontWeight: 800,
                  mb: 2,
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  textShadow: '0 2px 10px rgba(0,0,0,0.3)',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: -10,
                    left: 0,
                    width: 80,
                    height: 4,
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 2,
                  }
                }}
              >
                Организуйте идеальное мероприятие
              </Typography>
              
              <Typography 
                variant="h5" 
                sx={{ 
                  mb: 4, 
                  fontWeight: 400,
                  opacity: 0.9,
                  maxWidth: 600
                }}
              >
                Платформа, которая соединяет организаторов мероприятий с профессиональными исполнителями для создания незабываемых событий
              </Typography>
              
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Button 
                  variant="contained" 
                  color="secondary"
                  size="large"
                  endIcon={<ArrowForwardIcon />}
                  sx={{ 
                    color: 'black',
                    fontWeight: 600,
                    px: 4,
                    py: 1.5
                  }}
                >
                  Начать бесплатно
                </Button>
                <Button 
                  variant="outlined" 
                  sx={{ 
                    borderColor: 'white', 
                    color: 'white',
                    '&:hover': {
                      borderColor: theme.palette.secondary.main,
                      backgroundColor: 'rgba(255, 245, 157, 0.1)'
                    }
                  }}
                >
                  Узнать больше
                </Button>
              </Box>
            </Box>
          </Grid>
          
          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box
              sx={{
                position: 'relative',
                height: 500,
                width: '100%',
                perspective: 1000,
              }}
            >
              <Paper
                elevation={8}
                sx={{
                  position: 'absolute',
                  top: '10%',
                  left: '5%',
                  width: '70%',
                  height: '80%',
                  backgroundImage: 'url("https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80")',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 4,
                  transform: 'rotateY(-5deg) rotateX(5deg)',
                  boxShadow: '0 25px 50px rgba(0,0,0,0.3)',
                  transition: 'transform 0.5s ease',
                  '&:hover': {
                    transform: 'rotateY(-2deg) rotateX(2deg) scale(1.03)',
                  }
                }}
              />
              <Paper
                elevation={8}
                sx={{
                  position: 'absolute',
                  top: '20%',
                  right: '5%',
                  width: '50%',
                  height: '60%',
                  backgroundImage: 'url("https://images.unsplash.com/photo-1492684223066-81342ee5ff30?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80")',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 4,
                  transform: 'rotateY(5deg) rotateX(-5deg) translateZ(-50px)',
                  boxShadow: '0 15px 35px rgba(0,0,0,0.2)',
                  transition: 'transform 0.5s ease',
                  '&:hover': {
                    transform: 'rotateY(2deg) rotateX(-2deg) scale(1.05) translateZ(-30px)',
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      
      {/* Декоративные элементы */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '15%',
          background: `linear-gradient(to top, rgba(255,255,255,0.05), transparent)`,
          backdropFilter: 'blur(5px)',
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          right: '5%',
          width: 200,
          height: 200,
          borderRadius: '50%',
          background: `radial-gradient(circle, ${theme.palette.secondary.main}40 0%, transparent 70%)`,
          filter: 'blur(60px)',
          zIndex: 0,
        }}
      />
    </Box>
  );
};

export default HeroSection; 