import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';

const Home: React.FC = () => {
  const { currentUser, userData, loading } = useAuth();
  
  // Пока идет загрузка данных, ничего не отображаем
  if (loading) {
    return <div>Загрузка...</div>;
  }
  
  // Если пользователь авторизован и его профиль не заполнен, перенаправляем на страницу настройки профиля
  if (currentUser && userData && !userData.profileCompleted) {
    const redirectPath = userData.userType === 'organizer' 
      ? '/profile-setup/organizer' 
      : '/profile-setup/performer';
    
    return <Navigate to={redirectPath} />;
  }
  
  // Если пользователь авторизован и его профиль заполнен, перенаправляем на дашборд
  if (currentUser && userData && userData.profileCompleted) {
    return <Navigate to="/dashboard" />;
  }
  
  // Иначе показываем лендинг страницу
  return <LandingPage />;
};

export default Home; 