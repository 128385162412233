import { collection, query, where, getDocs, getDoc, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Event, getEventById, getOrganizerEvents } from './event';
import { Negotiation, getOrganizerNegotiations, getPerformerNegotiations } from './negotiations';
import { Task, getTasksAssignedToUser, convertTasksToCalendarItems } from './tasks';

// Types for calendar items
export type CalendarItemType = 'event' | 'preparation' | 'reminder' | 'negotiation' | 'task';

export interface CalendarItem {
  id: string;
  title: string;
  description?: string;
  startDate: string; // ISO string
  endDate?: string; // ISO string
  allDay: boolean;
  type: CalendarItemType;
  status?: string;
  relatedEventId?: string;
  relatedNegotiationId?: string;
  relatedTaskId?: string;
  color?: string;
  userId: string; // The user who created/owns this item
  completed?: boolean;
  assignedTo?: string; // For tasks
}

// Get all calendar items for a user
export const getUserCalendarItems = async (userId: string): Promise<CalendarItem[]> => {
  try {
    // Fetch custom calendar items
    const itemsQuery = query(
      collection(db, 'calendarItems'),
      where('userId', '==', userId)
    );
    
    const querySnapshot = await getDocs(itemsQuery);
    const items: CalendarItem[] = [];
    
    querySnapshot.forEach((doc) => {
      items.push({ id: doc.id, ...doc.data() } as CalendarItem);
    });
    
    return items;
  } catch (error: any) {
    console.error("Error getting calendar items:", error);
    throw new Error(error.message);
  }
};

// Add a new calendar item
export const addCalendarItem = async (item: Omit<CalendarItem, 'id'>): Promise<string> => {
  try {
    const docRef = await addDoc(collection(db, 'calendarItems'), item);
    return docRef.id;
  } catch (error: any) {
    console.error("Error adding calendar item:", error);
    throw new Error(error.message);
  }
};

// Update a calendar item
export const updateCalendarItem = async (id: string, updates: Partial<CalendarItem>): Promise<void> => {
  try {
    await updateDoc(doc(db, 'calendarItems', id), updates);
  } catch (error: any) {
    console.error("Error updating calendar item:", error);
    throw new Error(error.message);
  }
};

// Delete a calendar item
export const deleteCalendarItem = async (id: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'calendarItems', id));
  } catch (error: any) {
    console.error("Error deleting calendar item:", error);
    throw new Error(error.message);
  }
};

// Mark a calendar item as completed
export const completeCalendarItem = async (id: string): Promise<void> => {
  try {
    await updateDoc(doc(db, 'calendarItems', id), {
      completed: true
    });
  } catch (error: any) {
    console.error("Error completing calendar item:", error);
    throw new Error(error.message);
  }
};

// Get all events for a user and format them as calendar items
export const getEventsForCalendar = async (userId: string, userType: string): Promise<CalendarItem[]> => {
  try {
    let events: Event[] = [];
    
    if (userType === 'organizer') {
      events = await getOrganizerEvents(userId);
    }
    
    return events.map(event => ({
      id: `event-${event.id}`,
      title: event.title,
      description: event.description,
      startDate: event.date,
      allDay: true,
      type: 'event',
      status: event.status,
      relatedEventId: event.id,
      color: getEventStatusColor(event.status),
      userId
    }));
  } catch (error: any) {
    console.error("Error getting events for calendar:", error);
    throw new Error(error.message);
  }
};

// Get all negotiations for a user and format them as calendar items
export const getNegotiationsForCalendar = async (userId: string, userType: string): Promise<CalendarItem[]> => {
  try {
    let negotiations: Negotiation[] = [];
    
    if (userType === 'organizer') {
      negotiations = await getOrganizerNegotiations(userId);
    } else {
      negotiations = await getPerformerNegotiations(userId);
    }
    
    const calendarItems: CalendarItem[] = [];
    
    for (const negotiation of negotiations) {
      // Get the event details to get the date
      const event = await getEventById(negotiation.eventId);
      if (event) {
        calendarItems.push({
          id: `negotiation-${negotiation.id}`,
          title: `Переговоры: ${event.title}`,
          description: `Статус: ${getNegotiationStatusLabel(negotiation.status)}`,
          startDate: event.date,
          allDay: true,
          type: 'negotiation',
          status: negotiation.status,
          relatedEventId: event.id,
          relatedNegotiationId: negotiation.id,
          color: getNegotiationStatusColor(negotiation.status),
          userId
        });
      }
    }
    
    return calendarItems;
  } catch (error: any) {
    console.error("Error getting negotiations for calendar:", error);
    throw new Error(error.message);
  }
};

// Get all tasks for a user and format them as calendar items
export const getTasksForCalendar = async (userId: string): Promise<CalendarItem[]> => {
  try {
    const tasks = await getTasksAssignedToUser(userId);
    return await convertTasksToCalendarItems(tasks);
  } catch (error: any) {
    console.error("Error getting tasks for calendar:", error);
    throw new Error(error.message);
  }
};

// Combine all calendar items for a user
export const getAllCalendarItems = async (userId: string, userType: string): Promise<CalendarItem[]> => {
  try {
    const [customItems, events, negotiations, tasks] = await Promise.all([
      getUserCalendarItems(userId),
      getEventsForCalendar(userId, userType),
      getNegotiationsForCalendar(userId, userType),
      getTasksForCalendar(userId)
    ]);
    
    return [...customItems, ...events, ...negotiations, ...tasks];
  } catch (error: any) {
    console.error("Error getting all calendar items:", error);
    throw new Error(error.message);
  }
};

// Helper functions for colors and labels
const getEventStatusColor = (status: string): string => {
  switch (status) {
    case 'draft': return '#9e9e9e'; // Grey
    case 'published': return '#4caf50'; // Green
    case 'completed': return '#2196f3'; // Blue
    case 'cancelled': return '#f44336'; // Red
    default: return '#9e9e9e'; // Grey
  }
};

const getNegotiationStatusColor = (status: string): string => {
  switch (status) {
    case 'pending_organizer': return '#ff9800'; // Orange
    case 'pending_performer': return '#ff9800'; // Orange
    case 'approved': return '#4caf50'; // Green
    case 'rejected': return '#f44336'; // Red
    case 'completed': return '#2196f3'; // Blue
    case 'cancelled': return '#9e9e9e'; // Grey
    default: return '#9e9e9e'; // Grey
  }
};

const getNegotiationStatusLabel = (status: string): string => {
  switch (status) {
    case 'pending_organizer': return 'Ожидает ответа организатора';
    case 'pending_performer': return 'Ожидает ответа исполнителя';
    case 'approved': return 'Подтверждено';
    case 'rejected': return 'Отклонено';
    case 'completed': return 'Завершено';
    case 'cancelled': return 'Отменено';
    default: return status;
  }
}; 