import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Grid,
  Button,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const FaqSection: React.FC = () => {
  const theme = useTheme();

  const faqs = [
    {
      question: 'Как зарегистрироваться на платформе?',
      answer: 'Регистрация на платформе проста и занимает всего несколько минут. Нажмите кнопку "Регистрация" в верхнем меню, выберите тип аккаунта (организатор или исполнитель), заполните необходимые поля и подтвердите свой email. После этого вы сможете настроить свой профиль и начать пользоваться платформой.'
    },
    {
      question: 'Сколько стоит использование платформы?',
      answer: 'Регистрация и базовое использование платформы бесплатны. Мы взимаем комиссию только при успешном бронировании услуг исполнителей. Для организаторов комиссия составляет 5% от стоимости заказа, для исполнителей — 10%. Точные условия и возможные дополнительные услуги описаны в разделе "Тарифы".'
    },
    {
      question: 'Как происходит оплата услуг исполнителей?',
      answer: 'Платформа предлагает безопасную систему оплаты. После согласования всех деталей организатор вносит предоплату (обычно 30-50% от общей суммы), которая замораживается на счете до выполнения услуг. Оставшаяся сумма оплачивается после мероприятия. Исполнитель получает средства в течение 3-5 рабочих дней после подтверждения выполнения услуг.'
    },
    {
      question: 'Что делать, если возникли проблемы с исполнителем?',
      answer: 'В случае возникновения проблем с исполнителем, сначала попробуйте решить вопрос напрямую через встроенный чат. Если это не помогает, обратитесь в службу поддержки через раздел "Помощь". Наши специалисты рассмотрят ситуацию и помогут найти решение. При необходимости мы можем вернуть предоплату или предложить другого исполнителя.'
    },
    {
      question: 'Можно ли отменить бронирование?',
      answer: 'Да, бронирование можно отменить, но условия возврата средств зависят от времени до мероприятия и политики конкретного исполнителя. Обычно при отмене за 14 и более дней возвращается 100% предоплаты, за 7-13 дней — 50%, менее чем за 7 дней возврат может быть невозможен. Подробные условия отмены указаны в каждом конкретном заказе.'
    },
    {
      question: 'Как стать исполнителем на платформе?',
      answer: 'Чтобы стать исполнителем, зарегистрируйтесь, выбрав соответствующий тип аккаунта, заполните профиль, добавьте портфолио и укажите предоставляемые услуги с ценами. После модерации ваш профиль станет доступен для организаторов. Чем более полную информацию вы предоставите, тем больше шансов получить заказы.'
    },
    {
      question: 'Какие документы нужны для регистрации?',
      answer: 'Для базовой регистрации достаточно email и телефона. Однако для повышения уровня верификации и получения статуса "Проверенный исполнитель" потребуются документы, подтверждающие личность и профессиональную деятельность (паспорт, ИНН, документы о регистрации ИП или юридического лица, профессиональные сертификаты).'
    },
    {
      question: 'Как платформа гарантирует качество услуг?',
      answer: 'Мы обеспечиваем качество услуг через систему проверки исполнителей, рейтинги и отзывы клиентов. Каждый исполнитель проходит модерацию, а после выполнения заказов получает оценки и отзывы. Исполнители с низким рейтингом могут быть временно заблокированы или удалены с платформы. Кроме того, мы предлагаем гарантию возврата средств в случае некачественного выполнения услуг.'
    }
  ];

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: '20%',
          height: '30%',
          background: `radial-gradient(circle, ${theme.palette.secondary.main}20 0%, transparent 70%)`,
          filter: 'blur(60px)',
          zIndex: 0,
        }
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              position: 'relative',
              display: 'inline-block',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 4,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 2,
              }
            }}
          >
            Часто задаваемые вопросы
          </Typography>
          
          <Typography 
            variant="h5" 
            sx={{ 
              maxWidth: 800, 
              mx: 'auto', 
              color: theme.palette.text.secondary,
              mb: 2
            }}
          >
            Ответы на самые популярные вопросы о нашей платформе
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={8} sx={{ mx: 'auto' }}>
            {faqs.map((faq, index) => (
              <Accordion 
                key={index}
                sx={{
                  mb: 2,
                  borderRadius: '8px !important',
                  overflow: 'hidden',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                  '&:before': {
                    display: 'none',
                  },
                  '&.Mui-expanded': {
                    boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    '& .MuiAccordionSummary-content': {
                      display: 'flex',
                      alignItems: 'center',
                    }
                  }}
                >
                  <HelpOutlineIcon 
                    sx={{ 
                      color: theme.palette.secondary.main,
                      mr: 2,
                      fontSize: 24
                    }} 
                  />
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ py: 3 }}>
                  <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>

        <Box 
          sx={{ 
            mt: 8, 
            textAlign: 'center',
            p: 4,
            borderRadius: 4,
            backgroundColor: `${theme.palette.secondary.main}20`,
            maxWidth: 800,
            mx: 'auto'
          }}
        >
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
            Остались вопросы?
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Если вы не нашли ответ на свой вопрос, свяжитесь с нашей службой поддержки. 
            Мы готовы помочь вам в любое время.
          </Typography>
          <Button 
            variant="contained" 
            color="primary"
            size="large"
            sx={{ px: 4, py: 1.5 }}
          >
            Связаться с поддержкой
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default FaqSection; 