import { collection, doc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseNode';
import { UserData } from '../services/auth';

// Import all sample performer data
import { musicPerformers } from '../sample-performers/music-performers';
import { photographyPerformers } from '../sample-performers/photography-performers';
import { videographyPerformers } from '../sample-performers/videography-performers';
import { venuePerformers } from '../sample-performers/venue-performers';
import { cateringPerformers } from '../sample-performers/catering-performers';
import { decorationPerformers } from '../sample-performers/decoration-performers';
import { entertainmentPerformers } from '../sample-performers/entertainment-performers';
import { transportPerformers } from '../sample-performers/transport-performers';
import { technicalPerformers } from '../sample-performers/technical-performers';
import { otherPerformers } from '../sample-performers/other-performers';

// Combine all performers into one array
const allPerformers = [
  ...musicPerformers,
  ...photographyPerformers,
  ...videographyPerformers,
  ...venuePerformers,
  ...cateringPerformers,
  ...decorationPerformers,
  ...entertainmentPerformers,
  ...transportPerformers,
  ...technicalPerformers,
  ...otherPerformers
];

/**
 * Check if a user with the given email already exists in Firestore
 */
const userExists = async (email: string): Promise<boolean> => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  } catch (error) {
    console.error(`Error checking if user exists: ${email}`, error);
    return false;
  }
};

/**
 * Add a performer to Firestore with a generated UID
 */
const addPerformer = async (performer: Omit<UserData, 'uid'>): Promise<void> => {
  try {
    // Check if user already exists
    const exists = await userExists(performer.email);
    if (exists) {
      console.log(`User with email ${performer.email} already exists. Skipping.`);
      return;
    }

    // Generate a unique ID for the performer
    // In a real auth setup this would come from Firebase Auth
    const uid = `performer_${Math.random().toString(36).substr(2, 9)}`;
    
    // Create the full performer object
    const performerData: UserData = {
      ...performer,
      uid
    };
    
    // Save to Firestore
    await setDoc(doc(db, 'users', uid), performerData);
    console.log(`Added performer: ${performer.fullName} (${performer.email})`);
  } catch (error) {
    console.error(`Error adding performer: ${performer.fullName}`, error);
  }
};

/**
 * Seed the database with all sample performers
 */
export const seedPerformers = async (): Promise<void> => {
  console.log(`Starting to seed database with ${allPerformers.length} performers...`);
  
  // Use batches of 20 to avoid overwhelming Firestore
  const batchSize = 20;
  
  for (let i = 0; i < allPerformers.length; i += batchSize) {
    const batch = allPerformers.slice(i, i + batchSize);
    await Promise.all(batch.map(performer => addPerformer(performer)));
    console.log(`Processed ${Math.min(i + batchSize, allPerformers.length)} of ${allPerformers.length} performers`);
  }
  
  console.log('Finished seeding performers!');
};

// Execute the seeding function when this script is run directly
if (require.main === module) {
  seedPerformers()
    .then(() => {
      console.log('Seed script completed successfully');
      process.exit(0);
    })
    .catch((error) => {
      console.error('Error running seed script:', error);
      process.exit(1);
    });
}

export default seedPerformers; 