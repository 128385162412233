import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button, 
  Paper,
  Grid,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Avatar
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getEventById, Event, EventService, deleteEvent, updateEvent } from '../services/event';
import { getRecommendedPerformers, RecommendedPerformer } from '../services/recommendation';
import { getEventNegotiations, Negotiation, approveNegotiation } from '../services/negotiations';
import { getUserData, UserData } from '../services/auth';
import PerformerRecommendations from '../components/events/PerformerRecommendations';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-hot-toast';

const eventTypeLabels: Record<string, string> = {
  'wedding': 'Свадьба',
  'corporate': 'Корпоратив',
  'concert': 'Концерт',
  'conference': 'Конференция',
  'party': 'Вечеринка',
  'other': 'Другое'
};

const serviceTypeLabels: Record<string, string> = {
  'venue': 'Площадки и помещения',
  'catering': 'Кейтеринг и обслуживание',
  'photography': 'Фото',
  'videography': 'Видеосъемка',
  'music': 'Музыка/DJ',
  'decoration': 'Декор и оформление',
  'entertainment': 'Ведущие и аниматоры',
  'transport': 'Транспорт',
  'technical': 'Техническое обеспечение',
  'other': 'Другое'
};

const statusLabels: Record<string, string> = {
  'draft': 'Черновик',
  'published': 'Опубликовано',
  'completed': 'Завершено',
  'cancelled': 'Отменено'
};

const statusColors: Record<string, string> = {
  'draft': 'default',
  'published': 'primary',
  'completed': 'success',
  'cancelled': 'error'
};

const EventDetails: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [event, setEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);
  
  // Recommendations state
  const [recommendations, setRecommendations] = useState<Record<string, RecommendedPerformer[]>>({});
  const [loadingRecommendations, setLoadingRecommendations] = useState<Record<string, boolean>>({});
  
  // Add state for negotiations and interested performers
  const [negotiations, setNegotiations] = useState<Negotiation[]>([]);
  const [interestedPerformers, setInterestedPerformers] = useState<Record<string, UserData[]>>({});
  const [loadingNegotiations, setLoadingNegotiations] = useState(false);
  
  useEffect(() => {
    const fetchEvent = async () => {
      if (!eventId) return;
      
      try {
        setLoading(true);
        const eventData = await getEventById(eventId);
        setEvent(eventData);
      } catch (err: any) {
        console.error(err);
        setError('Ошибка при загрузке данных мероприятия');
      } finally {
        setLoading(false);
      }
    };
    
    fetchEvent();
  }, [eventId]);
  
  // Fetch recommendations when event is loaded
  useEffect(() => {
    const fetchRecommendations = async () => {
      if (!event || !event.services || !event.services.length) return;
      
      // Get recommendations for each service that needs a performer
      for (const service of event.services) {
        if (service.isBooked) continue; // Skip services that already have a performer
        
        try {
          // Set loading state for this service
          setLoadingRecommendations(prev => ({ ...prev, [service.type]: true }));
          
          // Get recommendations
          const serviceRecommendations = await getRecommendedPerformers(service, event.location);
          
          // Update recommendations state
          setRecommendations(prev => ({
            ...prev,
            [service.type]: serviceRecommendations
          }));
        } catch (error) {
          console.error(`Error fetching recommendations for ${service.type}:`, error);
        } finally {
          setLoadingRecommendations(prev => ({ ...prev, [service.type]: false }));
        }
      }
    };
    
    fetchRecommendations();
  }, [event]);
  
  // Add useEffect to fetch negotiations for this event
  useEffect(() => {
    const fetchNegotiations = async () => {
      if (!eventId || !event) return;
      
      try {
        setLoadingNegotiations(true);
        const eventNegotiations = await getEventNegotiations(eventId);
        setNegotiations(eventNegotiations);
        
        // Group interested performers by service type
        const performersByService: Record<string, UserData[]> = {};
        
        // Process each negotiation to get performer details
        for (const negotiation of eventNegotiations) {
          if (negotiation.status === 'pending_organizer') {
            try {
              const performerData = await getUserData(negotiation.performerId);
              if (performerData) {
                // Initialize the array for this service type if it doesn't exist
                if (!performersByService[negotiation.serviceType]) {
                  performersByService[negotiation.serviceType] = [];
                }
                
                // Add performer to the array for this service type
                performersByService[negotiation.serviceType].push(performerData);
              }
            } catch (error) {
              console.error('Error fetching performer data:', error);
            }
          }
        }
        
        setInterestedPerformers(performersByService);
      } catch (error) {
        console.error('Error fetching negotiations:', error);
      } finally {
        setLoadingNegotiations(false);
      }
    };
    
    fetchNegotiations();
  }, [eventId, event]);
  
  const handleSelectPerformer = async (service: EventService, performer: RecommendedPerformer) => {
    if (!event || !eventId) return;
    
    try {
      // Create updated services array with the selected performer
      const updatedServices = event.services.map(s => {
        // Simplify comparison to just type and description to make matching more robust
        if (s.type === service.type && s.description === service.description) {
          return {
            ...s,
            performerId: performer.uid,
            isBooked: true
          };
        }
        return s;
      });
      
      // Update the event in Firestore
      await updateEvent(eventId, { services: updatedServices });
      
      // Update local state
      setEvent({
        ...event,
        services: updatedServices
      });
      
      // Show success notification
      toast.success(`Исполнитель ${performer.fullName || 'выбран'} для услуги ${serviceTypeLabels[service.type] || service.type}`);
    } catch (error) {
      console.error('Error selecting performer:', error);
      setError('Не удалось выбрать исполнителя. Пожалуйста, попробуйте снова.');
    }
  };
  
  /**
   * Handle cancellation of a selected performer
   */
  const handleCancelPerformerSelection = async (service: EventService) => {
    if (!event || !eventId) return;
    
    try {
      // Create updated services array with the performer removed
      const updatedServices = event.services.map(s => {
        if (s.type === service.type && s.description === service.description) {
          // Remove performer data
          const { performerId, ...serviceWithoutPerformer } = s;
          return {
            ...serviceWithoutPerformer,
            isBooked: false
          };
        }
        return s;
      });
      
      // Update the event in Firestore
      await updateEvent(eventId, { services: updatedServices });
      
      // Update local state
      setEvent({
        ...event,
        services: updatedServices
      });
      
      // Show success notification
      toast.success(`Выбор исполнителя для услуги ${serviceTypeLabels[service.type] || service.type} отменен`);
      
      // Re-fetch recommendations for this service
      try {
        setLoadingRecommendations(prev => ({ ...prev, [service.type]: true }));
        const serviceRecommendations = await getRecommendedPerformers(service, event.location);
        setRecommendations(prev => ({
          ...prev,
          [service.type]: serviceRecommendations
        }));
      } catch (error) {
        console.error(`Error fetching recommendations for ${service.type}:`, error);
      } finally {
        setLoadingRecommendations(prev => ({ ...prev, [service.type]: false }));
      }
    } catch (error) {
      console.error('Error canceling performer selection:', error);
      setError('Не удалось отменить выбор исполнителя. Пожалуйста, попробуйте снова.');
    }
  };
  
  const handleEditEvent = () => {
    navigate(`/events/${eventId}/edit`);
  };
  
  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  
  const handleDeleteEvent = async () => {
    if (!eventId) return;
    
    try {
      await deleteEvent(eventId);
      navigate('/dashboard');
    } catch (err: any) {
      console.error(err);
      setError('Ошибка при удалении мероприятия');
    } finally {
      setDeleteDialogOpen(false);
    }
  };
  
  const handlePublishEvent = async () => {
    if (!eventId || !event) return;
    
    try {
      await updateEvent(eventId, { status: 'published' });
      setEvent({ ...event, status: 'published' });
      setPublishDialogOpen(false);
    } catch (err: any) {
      console.error(err);
      setError('Ошибка при публикации мероприятия');
    }
  };
  
  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };
  
  // Add handler for approving a performer who expressed interest
  const handleApprovePerformer = async (negotiation: Negotiation, performer: UserData) => {
    if (!event || !eventId) return;
    
    try {
      // Approve the negotiation
      await approveNegotiation(negotiation.id!, currentUser?.uid!);
      
      // Update the event service with the performer
      const updatedServices = event.services.map(s => {
        if (s.type === negotiation.serviceType) {
          return {
            ...s,
            performerId: performer.uid,
            isBooked: true
          };
        }
        return s;
      });
      
      // Update the event in Firestore
      await updateEvent(eventId, { services: updatedServices });
      
      // Update local state
      setEvent({
        ...event,
        services: updatedServices
      });
      
      // Remove from interested performers
      const updatedInterestedPerformers = { ...interestedPerformers };
      updatedInterestedPerformers[negotiation.serviceType] = 
        updatedInterestedPerformers[negotiation.serviceType].filter(p => p.uid !== performer.uid);
      setInterestedPerformers(updatedInterestedPerformers);
      
      // Show success notification
      toast.success(`Исполнитель ${performer.fullName || 'выбран'} одобрен для услуги ${serviceTypeLabels[negotiation.serviceType] || negotiation.serviceType}`);
    } catch (error) {
      console.error('Error approving performer:', error);
      toast.error('Не удалось одобрить исполнителя. Пожалуйста, попробуйте снова.');
    }
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
        <Button 
          startIcon={<ArrowBackIcon />} 
          onClick={handleBackToDashboard}
          sx={{ mt: 2 }}
        >
          Вернуться на дашборд
        </Button>
      </Container>
    );
  }
  
  if (!event) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="warning">Мероприятие не найдено</Alert>
        <Button 
          startIcon={<ArrowBackIcon />} 
          onClick={handleBackToDashboard}
          sx={{ mt: 2 }}
        >
          Вернуться на дашборд
        </Button>
      </Container>
    );
  }
  
  // Check if the current user is the organizer of this event
  const isOrganizer = currentUser && currentUser.uid === event.organizerId;
  
  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch {
      return dateString;
    }
  };
  
  return (
    <Box
      sx={{
        minHeight: '100vh',
        py: 6,
        backgroundColor: theme.palette.background.default
      }}
    >
      <Container maxWidth="lg">
        <Button 
          startIcon={<ArrowBackIcon />} 
          onClick={handleBackToDashboard}
          sx={{ mb: 3 }}
        >
          Вернуться на дашборд
        </Button>
        
        <Grid container spacing={4}>
          {/* Основная информация */}
          <Grid item xs={12} md={8}>
            <Paper
              elevation={3}
              sx={{
                p: 4,
                borderRadius: 3,
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                <Box>
                  <Typography variant="h4" component="h1" fontWeight={700} gutterBottom>
                    {event.title}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <Chip 
                      label={eventTypeLabels[event.eventType] || event.eventType} 
                      color="primary" 
                      variant="outlined" 
                      size="small" 
                    />
                    <Chip 
                      label={statusLabels[event.status] || event.status} 
                      color={statusColors[event.status] as any || 'default'} 
                      size="small" 
                    />
                  </Box>
                </Box>
                
                {isOrganizer && (
                  <Box>
                    {event.status === 'draft' && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setPublishDialogOpen(true)}
                        sx={{ mr: 1 }}
                      >
                        Опубликовать
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={handleEditEvent}
                      sx={{ mr: 1 }}
                    >
                      Редактировать
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={handleOpenDeleteDialog}
                    >
                      Удалить
                    </Button>
                  </Box>
                )}
              </Box>
              
              <Divider sx={{ my: 3 }} />
              
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                    Дата и время
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(event.date)}, {event.time}
                  </Typography>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                    Место проведения
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {event.location}
                  </Typography>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                    Описание
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {event.description}
                  </Typography>
                </Grid>
              </Grid>
              
              <Divider sx={{ my: 3 }} />
              
              <Typography variant="h5" fontWeight={600} gutterBottom>
                Услуги
              </Typography>
              
              <List>
                {event.services.map((service: EventService, index) => (
                  <Paper
                    key={index}
                    elevation={1}
                    sx={{
                      p: 2,
                      mb: 3,
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: service.isBooked ? 'success.light' : 'divider'
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {serviceTypeLabels[service.type] || service.type}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph>
                          {service.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} sx={{ textAlign: { sm: 'right' } }}>
                        <Typography variant="subtitle1" fontWeight={600} color="primary">
                          {service.budget.toLocaleString()} ₽
                        </Typography>
                        <Chip 
                          label={service.isBooked ? 'Исполнитель найден' : 'Требуется исполнитель'} 
                          color={service.isBooked ? 'success' : 'default'} 
                          size="small" 
                          sx={{ mt: 1 }}
                        />
                        
                        {/* Show cancel button for booked services */}
                        {isOrganizer && service.isBooked && (
                          <Button
                            size="small"
                            color="secondary"
                            onClick={() => handleCancelPerformerSelection(service)}
                            sx={{ mt: 1, display: 'block', ml: { xs: 0, sm: 'auto' } }}
                          >
                            Отменить выбор
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    
                    {/* Only add the interested performers section for services that have interested performers */}
                    {isOrganizer && !service.isBooked && interestedPerformers[service.type] && interestedPerformers[service.type].length > 0 && (
                      <Box sx={{ mt: 3 }}>
                        <Accordion 
                          disableGutters 
                          elevation={0}
                          defaultExpanded
                          sx={{ 
                            border: '1px solid',
                            borderColor: 'divider',
                            '&:before': { display: 'none' },
                            mb: 2
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ bgcolor: 'rgba(0, 128, 0, 0.08)' }}
                          >
                            <Typography fontWeight={500} color="success.main">
                              Заинтересованные исполнители ({interestedPerformers[service.type].length})
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {interestedPerformers[service.type].map((performer) => {
                              // Find the corresponding negotiation
                              const negotiation = negotiations.find(
                                n => n.performerId === performer.uid && n.serviceType === service.type
                              );
                              
                              return (
                                <Paper key={performer.uid} sx={{ p: 2, mb: 2, border: '1px solid', borderColor: 'divider' }}>
                                  <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={2}>
                                      <Avatar 
                                        src={performer.profileImageUrl} 
                                        sx={{ width: 60, height: 60, mx: { xs: 'auto', sm: 0 } }}
                                      >
                                        {!performer.profileImageUrl && performer.fullName?.charAt(0)}
                                      </Avatar>
                                    </Grid>
                                    <Grid item xs={12} sm={7}>
                                      <Typography variant="h6">{performer.fullName}</Typography>
                                      <Typography variant="body2" color="textSecondary">
                                        {serviceTypeLabels[performer.serviceCategory || ''] || performer.serviceCategory}
                                      </Typography>
                                      <Typography variant="body2">
                                        {performer.location}
                                      </Typography>
                                      {performer.priceRange && (
                                        <Typography variant="body2" color="primary.main" fontWeight={500}>
                                          {performer.priceRange.replace('-', ' - ')} ₽
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
                                      <Button 
                                        variant="contained" 
                                        color="success"
                                        size="small"
                                        sx={{ mb: 1 }}
                                        onClick={() => negotiation && handleApprovePerformer(negotiation, performer)}
                                      >
                                        Выбрать
                                      </Button>
                                      <Button 
                                        variant="outlined" 
                                        size="small"
                                        component="a"
                                        href={`/negotiations/${negotiation?.id}`}
                                        target="_blank"
                                        sx={{ ml: { xs: 2, sm: 0 } }}
                                      >
                                        Перейти к диалогу
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              );
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                    
                    {/* Then show recommendations section */}
                    {isOrganizer && !service.isBooked && (
                      <Box sx={{ mt: 3 }}>
                        <Accordion 
                          disableGutters 
                          elevation={0}
                          sx={{ 
                            border: '1px solid',
                            borderColor: 'divider',
                            '&:before': { display: 'none' }
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ bgcolor: 'action.hover' }}
                          >
                            <Typography fontWeight={500}>
                              Рекомендуемые исполнители
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <PerformerRecommendations
                              recommendations={recommendations[service.type] || []}
                              serviceType={service.type}
                              onSelectPerformer={(performer) => handleSelectPerformer(service, performer)}
                              loading={loadingRecommendations[service.type] || false}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                  </Paper>
                ))}
              </List>
            </Paper>
          </Grid>
          
          {/* Сайдбар с информацией */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                borderRadius: 3,
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)'
              }}
            >
              <Typography variant="h6" fontWeight={600} gutterBottom>
                Сводка мероприятия
              </Typography>
              
              <Divider sx={{ my: 2 }} />
              
              <List disablePadding>
                <ListItem disablePadding sx={{ py: 1 }}>
                  <ListItemText 
                    primary="Общий бюджет" 
                    secondary={`${event.totalBudget.toLocaleString()} ₽`}
                    primaryTypographyProps={{ color: 'textSecondary', variant: 'body2' }}
                    secondaryTypographyProps={{ color: 'primary', fontWeight: 600, variant: 'h6' }}
                  />
                </ListItem>
                
                <ListItem disablePadding sx={{ py: 1 }}>
                  <ListItemText 
                    primary="Количество услуг" 
                    secondary={event.services.length}
                    primaryTypographyProps={{ color: 'textSecondary', variant: 'body2' }}
                    secondaryTypographyProps={{ fontWeight: 600 }}
                  />
                </ListItem>
                
                <ListItem disablePadding sx={{ py: 1 }}>
                  <ListItemText 
                    primary="Статус" 
                    secondary={statusLabels[event.status] || event.status}
                    primaryTypographyProps={{ color: 'textSecondary', variant: 'body2' }}
                    secondaryTypographyProps={{ fontWeight: 600 }}
                  />
                </ListItem>
                
                <ListItem disablePadding sx={{ py: 1 }}>
                  <ListItemText 
                    primary="Создано" 
                    secondary={formatDate(event.createdAt)}
                    primaryTypographyProps={{ color: 'textSecondary', variant: 'body2' }}
                    secondaryTypographyProps={{ fontWeight: 600 }}
                  />
                </ListItem>
                
                <ListItem disablePadding sx={{ py: 1 }}>
                  <ListItemText 
                    primary="Последнее обновление" 
                    secondary={formatDate(event.updatedAt)}
                    primaryTypographyProps={{ color: 'textSecondary', variant: 'body2' }}
                    secondaryTypographyProps={{ fontWeight: 600 }}
                  />
                </ListItem>
              </List>
              
              {event.attendees && (
                <ListItem disablePadding sx={{ py: 1 }}>
                  <ListItemText 
                    primary="Ожидаемое количество гостей" 
                    secondary={event.attendees}
                    primaryTypographyProps={{ color: 'textSecondary', variant: 'body2' }}
                    secondaryTypographyProps={{ fontWeight: 600 }}
                  />
                </ListItem>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
      
      {/* Dialog for confirming event deletion */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>Удаление мероприятия</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены, что хотите удалить мероприятие "{event.title}"? Это действие невозможно отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Отмена</Button>
          <Button onClick={handleDeleteEvent} color="error" variant="contained">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialog for confirming event publication */}
      <Dialog
        open={publishDialogOpen}
        onClose={() => setPublishDialogOpen(false)}
      >
        <DialogTitle>Публикация мероприятия</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы хотите опубликовать мероприятие "{event.title}"? После публикации оно будет доступно для всех исполнителей.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPublishDialogOpen(false)}>Отмена</Button>
          <Button onClick={handlePublishEvent} color="primary" variant="contained">
            Опубликовать
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EventDetails; 