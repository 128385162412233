import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent,
  useTheme,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SecurityIcon from '@mui/icons-material/Security';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PeopleIcon from '@mui/icons-material/People';
import ScheduleIcon from '@mui/icons-material/Schedule';

const MotivationSection: React.FC = () => {
  const theme = useTheme();

  const organizerBenefits = [
    {
      icon: <AccessTimeIcon sx={{ color: theme.palette.primary.main }} />,
      title: 'Экономия времени',
      description: 'Быстрый поиск и бронирование всех необходимых услуг на одной платформе вместо обращения к разным поставщикам.'
    },
    {
      icon: <SecurityIcon sx={{ color: theme.palette.primary.main }} />,
      title: 'Надежность и безопасность',
      description: 'Все исполнители проходят проверку, имеют рейтинг и отзывы от предыдущих клиентов.'
    },
    {
      icon: <MonetizationOnIcon sx={{ color: theme.palette.primary.main }} />,
      title: 'Оптимизация бюджета',
      description: 'Прозрачное ценообразование и возможность сравнения предложений от разных исполнителей.'
    },
    {
      icon: <ScheduleIcon sx={{ color: theme.palette.primary.main }} />,
      title: 'Удобное управление',
      description: 'Инструменты для планирования, координации и контроля всех аспектов мероприятия в одном месте.'
    }
  ];

  const performerBenefits = [
    {
      icon: <PeopleIcon sx={{ color: theme.palette.primary.main }} />,
      title: 'Новые клиенты',
      description: 'Доступ к постоянному потоку потенциальных заказчиков без затрат на маркетинг и рекламу.'
    },
    {
      icon: <VerifiedUserIcon sx={{ color: theme.palette.primary.main }} />,
      title: 'Повышение репутации',
      description: 'Система рейтингов и отзывов помогает наработать положительную репутацию и привлечь больше клиентов.'
    },
    {
      icon: <MonetizationOnIcon sx={{ color: theme.palette.primary.main }} />,
      title: 'Стабильный доход',
      description: 'Регулярные заказы и безопасная система оплаты обеспечивают стабильный поток доходов.'
    },
    {
      icon: <TrendingUpIcon sx={{ color: theme.palette.primary.main }} />,
      title: 'Развитие бизнеса',
      description: 'Инструменты для анализа эффективности, управления календарем и расширения клиентской базы.'
    }
  ];

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '20%',
          left: 0,
          width: '30%',
          height: '40%',
          background: `radial-gradient(circle, ${theme.palette.secondary.main}20 0%, transparent 70%)`,
          filter: 'blur(60px)',
          zIndex: 0,
        }
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              position: 'relative',
              display: 'inline-block',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 4,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 2,
              }
            }}
          >
            Почему выбирают нас
          </Typography>
          
          <Typography 
            variant="h5" 
            sx={{ 
              maxWidth: 800, 
              mx: 'auto', 
              color: theme.palette.text.secondary,
              mb: 2
            }}
          >
            Преимущества для всех участников платформы
          </Typography>
        </Box>

        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 4, 
                height: '100%',
                borderRadius: 4,
                border: `1px solid ${theme.palette.divider}`,
                background: `linear-gradient(135deg, #ffffff 0%, ${theme.palette.background.paper} 100%)`,
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: 8,
                  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
                }
              }}
            >
              <Typography 
                variant="h3" 
                component="h3" 
                sx={{ 
                  mb: 3, 
                  fontWeight: 600,
                  textAlign: 'center',
                  color: theme.palette.primary.main
                }}
              >
                Для организаторов
              </Typography>
              
              <Divider sx={{ mb: 4 }} />
              
              <List sx={{ mb: 2 }}>
                {organizerBenefits.map((benefit, index) => (
                  <ListItem 
                    key={index} 
                    alignItems="flex-start"
                    sx={{ 
                      px: 0, 
                      py: 2,
                      '&:not(:last-child)': {
                        borderBottom: `1px solid ${theme.palette.divider}`
                      }
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 42, mt: 0.5 }}>
                      {benefit.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                          {benefit.title}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body1" color="text.secondary">
                          {benefit.description}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                  Идеально подходит для:
                </Typography>
                <List>
                  {[
                    'Корпоративных мероприятий и конференций',
                    'Свадеб и семейных торжеств',
                    'Концертов и фестивалей',
                    'Выставок и презентаций',
                    'Частных вечеринок и юбилеев'
                  ].map((item, index) => (
                    <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon sx={{ color: theme.palette.secondary.main }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 4, 
                height: '100%',
                borderRadius: 4,
                border: `1px solid ${theme.palette.divider}`,
                background: `linear-gradient(135deg, #ffffff 0%, ${theme.palette.background.paper} 100%)`,
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: 8,
                  background: `linear-gradient(90deg, ${theme.palette.secondary.main} 0%, #FFEE58 100%)`,
                }
              }}
            >
              <Typography 
                variant="h3" 
                component="h3" 
                sx={{ 
                  mb: 3, 
                  fontWeight: 600,
                  textAlign: 'center',
                  color: theme.palette.primary.main
                }}
              >
                Для исполнителей
              </Typography>
              
              <Divider sx={{ mb: 4 }} />
              
              <List sx={{ mb: 2 }}>
                {performerBenefits.map((benefit, index) => (
                  <ListItem 
                    key={index} 
                    alignItems="flex-start"
                    sx={{ 
                      px: 0, 
                      py: 2,
                      '&:not(:last-child)': {
                        borderBottom: `1px solid ${theme.palette.divider}`
                      }
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 42, mt: 0.5 }}>
                      {benefit.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                          {benefit.title}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body1" color="text.secondary">
                          {benefit.description}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                  Для профессионалов в сферах:
                </Typography>
                <List>
                  {[
                    'Музыка и развлечения',
                    'Фото и видеосъемка',
                    'Кейтеринг и обслуживание',
                    'Декор и оформление',
                    'Ведущие и аниматоры'
                  ].map((item, index) => (
                    <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon sx={{ color: theme.palette.secondary.main }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MotivationSection; 