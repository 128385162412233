import { UserData } from '../services/auth';

// Sample performers for the "catering" category
export const cateringPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "gourmet.catering@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Гурман Кейтеринг",
    phone: "+7 (495) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1555244162-803834f70033?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Премиальный кейтеринг для мероприятий высокого уровня. Команда профессиональных поваров с опытом работы в ресторанах со звездами Мишлен. Предлагаем авторское меню с использованием сезонных продуктов высочайшего качества.",
    location: "Москва",
    website: "https://gourmet-catering.ru",
    serviceCategory: "catering",
    priceRange: "5000-15000",
    skills: ["Премиум-кейтеринг", "Авторская кухня", "Банкеты", "Фуршеты", "Выездное обслуживание", "Свадебные торты"],
    portfolioLinks: [
      "https://www.instagram.com/gourmet.catering",
      "https://gourmet-catering.ru/gallery"
    ],
    certificateUrls: [
      "https://example.com/certificates/food-safety.jpg",
      "https://example.com/certificates/chef-diploma.jpg"
    ]
  },
  {
    email: "buffet.service@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Фуршет Сервис",
    phone: "+7 (495) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1579867779026-df4a19322054?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специализируемся на организации фуршетов для корпоративных мероприятий и презентаций. Разнообразие фуршетных закусок, канапе, десертов. Полный сервис с профессиональными официантами. Работаем с мероприятиями любого масштаба.",
    location: "Москва",
    website: "https://buffet-service.ru",
    serviceCategory: "catering",
    priceRange: "2500-5000",
    skills: ["Фуршеты", "Корпоративное питание", "Канапе", "Фингер-фуд", "Кофе-брейки", "Деловые завтраки"],
    portfolioLinks: [
      "https://buffet-service.ru/portfolio",
      "https://www.instagram.com/buffet.service"
    ],
    certificateUrls: [
      "https://example.com/certificates/catering-license.jpg"
    ]
  },
  {
    email: "wedding.feast@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Свадебный Пир",
    phone: "+7 (495) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1547573854-74d2a71d0826?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специализированный свадебный кейтеринг. Создаем роскошные банкеты для самого важного дня в вашей жизни. Индивидуальный подход, дегустации меню, специальные предложения для молодоженов. Богатый опыт работы со свадьбами любого масштаба.",
    location: "Москва",
    website: "https://wedding-feast.ru",
    serviceCategory: "catering",
    priceRange: "4000-8000",
    skills: ["Свадебный кейтеринг", "Банкеты", "Свадебные торты", "Candy Bar", "Работа с молодоженами", "Выездные регистрации"],
    portfolioLinks: [
      "https://wedding-feast.ru/our-weddings",
      "https://www.instagram.com/wedding.feast"
    ],
    certificateUrls: [
      "https://example.com/certificates/pastry-certificate.jpg"
    ]
  },
  {
    email: "eco.food@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "ЭкоФуд Кейтеринг",
    phone: "+7 (495) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1604908761644-48700668fc1c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Кейтеринговая компания, специализирующаяся на здоровом питании. Используем только органические и фермерские продукты. Предлагаем вегетарианское, веганское, безглютеновое, безлактозное меню. Экологичная сервировка и минимизация отходов.",
    location: "Москва",
    website: "https://eco-food.ru",
    serviceCategory: "catering",
    priceRange: "3000-7000",
    skills: ["Экологичный кейтеринг", "Здоровое питание", "Веганская кухня", "Органические продукты", "Безглютеновое меню", "Фермерские продукты"],
    portfolioLinks: [
      "https://eco-food.ru/menu",
      "https://www.instagram.com/eco.food.catering"
    ],
    certificateUrls: [
      "https://example.com/certificates/organic-food.jpg",
      "https://example.com/certificates/eco-certification.jpg"
    ]
  },
  {
    email: "bbq.masters@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "BBQ Мастера",
    phone: "+7 (495) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1529193591184-b1d58069ecdd?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специалисты по барбекю и грилю для выездных мероприятий на открытом воздухе. Готовим на различных видах грилей, мангалов и коптилен. Мясо, рыба, овощи - всё готовится прямо на месте проведения мероприятия. Зрелищная подача и незабываемый вкус.",
    location: "Москва, Московская область",
    website: "https://bbq-masters.ru",
    serviceCategory: "catering",
    priceRange: "3500-8000",
    skills: ["Барбекю", "Гриль", "Мангал", "Выездное приготовление", "Мясные блюда", "Корпоративы на природе"],
    portfolioLinks: [
      "https://bbq-masters.ru/gallery",
      "https://www.instagram.com/bbq.masters"
    ],
    certificateUrls: [
      "https://example.com/certificates/bbq-championship.jpg"
    ]
  },
  {
    email: "national.cuisines@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Мировая Кухня",
    phone: "+7 (495) 678-9012",
    profileImageUrl: "https://images.unsplash.com/photo-1601050690597-df0568f70950?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Кейтеринг национальных кухонь мира. Предлагаем тематические меню: итальянское, японское, мексиканское, греческое, индийское и другие. Аутентичные рецепты, традиционные ингредиенты и атмосфера выбранной страны. Идеально для тематических вечеринок.",
    location: "Москва",
    website: "https://world-cuisine.ru",
    serviceCategory: "catering",
    priceRange: "3000-7000",
    skills: ["Национальные кухни", "Тематические мероприятия", "Аутентичные рецепты", "Восточная кухня", "Европейская кухня", "Азиатская кухня"],
    portfolioLinks: [
      "https://world-cuisine.ru/cuisines",
      "https://www.instagram.com/world.cuisine.catering"
    ],
    certificateUrls: [
      "https://example.com/certificates/international-cooking.jpg"
    ]
  },
  {
    email: "vip.catering@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "VIP Кейтеринг",
    phone: "+7 (495) 789-0123",
    profileImageUrl: "https://images.unsplash.com/photo-1600335895229-6e75511892c8?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Эксклюзивное обслуживание частных мероприятий высочайшего уровня. Индивидуальное меню, разработанное с учетом всех пожеланий клиента. Безупречный сервис, изысканная сервировка, профессиональная команда. Гарантия конфиденциальности.",
    location: "Москва",
    website: "https://vip-catering.ru",
    serviceCategory: "catering",
    priceRange: "10000-30000",
    skills: ["VIP-обслуживание", "Частные мероприятия", "Индивидуальное меню", "Премиум-сервис", "Эксклюзивная сервировка", "Высокая кухня"],
    portfolioLinks: [
      "https://vip-catering.ru/showcase",
      "https://www.instagram.com/vip.catering"
    ],
    certificateUrls: [
      "https://example.com/certificates/premium-service.jpg",
      "https://example.com/certificates/luxury-catering.jpg"
    ]
  },
  {
    email: "dessert.bar@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Десерт Бар",
    phone: "+7 (495) 890-1234",
    profileImageUrl: "https://images.unsplash.com/photo-1542124948-dc391252a940?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специализируемся на создании сладких столов и десерт-баров для мероприятий. Авторские десерты, торты, капкейки, макаруны, конфеты ручной работы. Стильное оформление сладкого стола в соответствии с тематикой мероприятия.",
    location: "Москва",
    website: "https://dessert-bar.ru",
    serviceCategory: "catering",
    priceRange: "2000-5000",
    skills: ["Candy Bar", "Сладкий стол", "Авторские десерты", "Торты на заказ", "Шоколадный фонтан", "Фруктовые композиции"],
    portfolioLinks: [
      "https://dessert-bar.ru/gallery",
      "https://www.instagram.com/dessert.bar"
    ],
    certificateUrls: [
      "https://example.com/certificates/confectionery.jpg"
    ]
  },
  {
    email: "business.lunch@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Бизнес Ланч",
    phone: "+7 (495) 901-2345",
    profileImageUrl: "https://images.unsplash.com/photo-1520104453335-6498568e38d5?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Обслуживание деловых мероприятий и бизнес-встреч. Организация кофе-брейков, бизнес-завтраков, обедов и ужинов. Четкое соблюдение тайминга, быстрая и незаметная работа. Удобные варианты меню для встреч любого формата.",
    location: "Москва",
    website: "https://business-lunch.ru",
    serviceCategory: "catering",
    priceRange: "1500-4000",
    skills: ["Деловые мероприятия", "Кофе-брейки", "Бизнес-ланчи", "Корпоративное питание", "Семинары", "Конференции"],
    portfolioLinks: [
      "https://business-lunch.ru/services",
      "https://www.instagram.com/business.lunch"
    ],
    certificateUrls: [
      "https://example.com/certificates/corporate-catering.jpg"
    ]
  },
  {
    email: "mobile.bar@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Мобильный Бар",
    phone: "+7 (495) 012-3456",
    profileImageUrl: "https://images.unsplash.com/photo-1551024709-8f23befc6f87?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Выездной бар для мероприятий любого формата. Профессиональные бармены, авторские коктейли, премиальный алкоголь, безалкогольные напитки. Стильная барная стойка и оборудование. Бармен-шоу, мастер-классы по приготовлению коктейлей.",
    location: "Москва",
    website: "https://mobile-bar.ru",
    serviceCategory: "catering",
    priceRange: "2500-8000",
    skills: ["Выездной бар", "Бармен-шоу", "Авторские коктейли", "Мастер-классы", "Корпоративы", "Свадьбы"],
    portfolioLinks: [
      "https://mobile-bar.ru/cocktails",
      "https://www.instagram.com/mobile.bar"
    ],
    certificateUrls: [
      "https://example.com/certificates/bartender.jpg",
      "https://example.com/certificates/mixology.jpg"
    ]
  }
]; 