import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Paper, 
  Button, 
  TextField, 
  Chip, 
  CircularProgress, 
  Alert, 
  Divider, 
  Card, 
  CardContent,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  Negotiation, 
  getNegotiationById, 
  approveNegotiation, 
  rejectNegotiation,
  addMessageToNegotiation,
  listenToNegotiation
} from '../services/negotiations';
import { getEventById, Event } from '../services/event';
import { getUserData, UserData } from '../services/auth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import toast from 'react-hot-toast';
import { 
  Task, 
  addTask, 
  updateTask, 
  completeTask, 
  getTasksByNegotiationId, 
  getTaskStatusLabel 
} from '../services/tasks';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { format, isAfter, parseISO } from 'date-fns';

// Map statuses to display information
const statusInfo: Record<string, { 
  label: string, 
  color: string, 
  icon: React.ReactNode 
}> = {
  'pending_organizer': { 
    label: 'Ожидает ответа организатора', 
    color: '#ff9800', 
    icon: <CircularProgress size={16} color="warning" /> 
  },
  'pending_performer': { 
    label: 'Ожидает ответа исполнителя', 
    color: '#ff9800', 
    icon: <CircularProgress size={16} color="warning" /> 
  },
  'approved': { 
    label: 'Согласовано обеими сторонами', 
    color: '#4caf50', 
    icon: <CheckCircleIcon fontSize="small" color="success" /> 
  },
  'rejected': { 
    label: 'Отклонено', 
    color: '#f44336', 
    icon: <CancelIcon fontSize="small" color="error" /> 
  },
  'completed': { 
    label: 'Завершено', 
    color: '#2196f3', 
    icon: <CheckCircleIcon fontSize="small" color="info" /> 
  },
  'cancelled': { 
    label: 'Отменено', 
    color: '#9e9e9e', 
    icon: <CancelIcon fontSize="small" /> 
  }
};

// Service types to labels
const serviceTypeLabels: Record<string, string> = {
  'venue': 'Площадки и помещения',
  'catering': 'Кейтеринг и обслуживание',
  'photography': 'Фото',
  'videography': 'Видеосъемка',
  'music': 'Музыка/DJ',
  'decoration': 'Декор и оформление',
  'entertainment': 'Ведущие и аниматоры',
  'transport': 'Транспорт',
  'technical': 'Техническое обеспечение',
  'other': 'Другое'
};

const NegotiationDetails: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { negotiationId } = useParams<{ negotiationId: string }>();
  const { userData, currentUser } = useAuth();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  // State
  const [negotiation, setNegotiation] = useState<Negotiation | null>(null);
  const [event, setEvent] = useState<Event | null>(null);
  const [counterparty, setCounterparty] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState<'approve' | 'reject' | null>(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [taskForm, setTaskForm] = useState({
    title: '',
    description: '',
    deadline: new Date().toISOString().split('T')[0],
    isForOrganizer: false,
    isForPerformer: false
  });
  
  // Scroll to bottom of messages when they change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [negotiation?.messages]);
  
  // Fetch negotiation and related data
  useEffect(() => {
    const fetchData = async () => {
      if (!negotiationId || !userData) {
        setError('Идентификатор переговоров не найден');
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        
        // Set up real-time listener for negotiation updates
        const unsubscribe = listenToNegotiation(
          negotiationId,
          (negotiationData) => {
            setNegotiation(negotiationData);
            
            // Load event and counterparty data if we don't have them yet
            if (!event) {
              getEventById(negotiationData.eventId)
                .then(eventData => {
                  if (eventData) {
                    setEvent(eventData);
                  }
                })
                .catch(err => {
                  console.error('Error fetching event details:', err);
                });
            }
            
            if (!counterparty) {
              const counterpartyId = 
                userData.userType === 'performer' ? negotiationData.organizerId : negotiationData.performerId;
              
              getUserData(counterpartyId)
                .then(counterpartyData => {
                  if (counterpartyData) {
                    setCounterparty(counterpartyData);
                  }
                })
                .catch(err => {
                  console.error('Error fetching counterparty details:', err);
                });
            }
            
            setLoading(false);
          },
          (error) => {
            console.error('Error with negotiation listener:', error);
            setError('Ошибка при загрузке данных о переговорах');
            setLoading(false);
          }
        );
        
        // Initial check for access
        const initialNegotiation = await getNegotiationById(negotiationId);
        if (!initialNegotiation) {
          setError('Переговоры не найдены');
          setLoading(false);
          return;
        }
        
        // Check if the user is part of this negotiation
        if (
          initialNegotiation.organizerId !== userData.uid && 
          initialNegotiation.performerId !== userData.uid
        ) {
          setError('У вас нет доступа к этим переговорам');
          setLoading(false);
          
          // Unsubscribe since we don't have access
          if (unsubscribe) {
            unsubscribe();
          }
          
          return;
        }
      } catch (err: any) {
        console.error('Error setting up negotiation listener:', err);
        setError('Ошибка при загрузке данных о переговорах');
        setLoading(false);
      }
    };
    
    fetchData();
  }, [negotiationId, currentUser]);
  
  // Load tasks related to this negotiation
  useEffect(() => {
    const fetchTasks = async () => {
      if (!negotiationId) return;
      
      try {
        setLoadingTasks(true);
        const tasksList = await getTasksByNegotiationId(negotiationId);
        setTasks(tasksList);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoadingTasks(false);
      }
    };
    
    fetchTasks();
  }, [negotiationId]);
  
  const handleBackToNegotiations = () => {
    navigate('/negotiations');
  };
  
  const handleSendMessage = async () => {
    if (!message.trim() || !negotiationId || !userData) return;
    
    setSendingMessage(true);
    try {
      await addMessageToNegotiation(negotiationId, userData.uid, message.trim());
      // No need to update local state here as the listener will do it
      setMessage('');
    } catch (err) {
      console.error('Error sending message:', err);
      toast.error('Ошибка при отправке сообщения');
    } finally {
      setSendingMessage(false);
    }
  };
  
  const openConfirmDialog = (action: 'approve' | 'reject') => {
    setConfirmAction(action);
    setConfirmDialogOpen(true);
  };
  
  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setConfirmAction(null);
  };
  
  const handleConfirmAction = async () => {
    if (!confirmAction || !negotiationId || !userData) {
      closeConfirmDialog();
      return;
    }
    
    setActionLoading(true);
    try {
      if (confirmAction === 'approve') {
        await approveNegotiation(negotiationId, userData.uid);
        toast.success('Вы одобрили переговоры');
      } else if (confirmAction === 'reject') {
        await rejectNegotiation(negotiationId, userData.uid);
        toast.success('Вы отклонили переговоры');
      }
    } catch (err) {
      console.error(`Error ${confirmAction}ing negotiation:`, err);
      toast.error(`Ошибка при ${confirmAction === 'approve' ? 'одобрении' : 'отклонении'} переговоров`);
    } finally {
      setActionLoading(false);
      closeConfirmDialog();
    }
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU', {
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };
  
  // Check if user can take action on this negotiation
  const canTakeAction = () => {
    if (!negotiation || !userData) return false;
    
    // If already approved, rejected, etc., no action needed
    if (['approved', 'rejected', 'completed', 'cancelled'].includes(negotiation.status)) {
      return false;
    }
    
    // If performer and pending performer approval
    if (userData.userType === 'performer' && negotiation.status === 'pending_performer') {
      return true;
    }
    
    // If organizer and pending organizer approval
    if (userData.userType === 'organizer' && negotiation.status === 'pending_organizer') {
      return true;
    }
    
    return false;
  };
  
  // Handle task dialog open/close
  const handleOpenTaskDialog = () => {
    setTaskForm({
      title: '',
      description: '',
      deadline: new Date().toISOString().split('T')[0],
      isForOrganizer: false,
      isForPerformer: false
    });
    setOpenTaskDialog(true);
  };
  
  const handleCloseTaskDialog = () => {
    setOpenTaskDialog(false);
  };
  
  // Handle task form changes
  const handleTaskFormChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    // Extract the target properly
    const target = e.target;
    
    // Directly check if the input is a checkbox
    if (target instanceof HTMLInputElement && target.type === 'checkbox') {
      const { name, checked } = target;
      setTaskForm(prev => ({ 
        ...prev, 
        [name]: checked 
      }));
    } else {
      // For all other inputs, use the name and value attributes
      const name = target.name as string;
      const value = target.value;
      
      setTaskForm(prev => ({ 
        ...prev, 
        [name]: value
      }));
    }
  };
  
  // Create a new task
  const handleCreateTask = async () => {
    if (!negotiation || !event || !userData || !negotiationId) return;
    
    try {
      const isOrganizer = userData.uid === negotiation.organizerId;
      const assignedToId = isOrganizer 
        ? negotiation.performerId
        : negotiation.organizerId;
      
      const taskData = {
        title: taskForm.title,
        description: taskForm.description,
        deadline: new Date(taskForm.deadline).toISOString(),
        eventId: negotiation.eventId,
        negotiationId: negotiationId,
        createdBy: userData.uid,
        assignedTo: assignedToId,
        isForOrganizer: taskForm.isForOrganizer,
        isForPerformer: taskForm.isForPerformer
      };
      
      await addTask(taskData);
      
      // Refresh tasks
      const tasksList = await getTasksByNegotiationId(negotiationId);
      setTasks(tasksList);
      
      handleCloseTaskDialog();
      toast.success('Задача успешно создана');
    } catch (error: any) {
      console.error('Error creating task:', error);
      toast.error(error.message || 'Ошибка при создании задачи');
    }
  };
  
  // Complete a task
  const handleCompleteTask = async (taskId: string) => {
    if (!taskId || !negotiationId) return;
    
    try {
      await completeTask(taskId);
      
      // Refresh tasks
      const tasksList = await getTasksByNegotiationId(negotiationId);
      setTasks(tasksList);
      
      toast.success('Задача отмечена как выполненная');
    } catch (error: any) {
      console.error('Error completing task:', error);
      toast.error(error.message || 'Ошибка при выполнении задачи');
    }
  };
  
  // Get date format for display
  const formatTaskDate = (dateString: string) => {
    return format(parseISO(dateString), 'dd.MM.yyyy');
  };
  
  // Check if task is overdue
  const isTaskOverdue = (task: Task) => {
    return task.status !== 'completed' && isAfter(new Date(), parseISO(task.deadline));
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default, pt: 6, pb: 8 }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBackToNegotiations}
            sx={{ mr: 2 }}
          >
            К списку переговоров
          </Button>
          
          <Typography variant="h4" component="h1" fontWeight={700} sx={{ flexGrow: 1 }}>
            {event?.title || 'Детали переговоров'}
          </Typography>
          
          {negotiation && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {statusInfo[negotiation.status].icon}
              <Typography variant="body1" sx={{ ml: 1, color: statusInfo[negotiation.status].color }}>
                {statusInfo[negotiation.status].label}
              </Typography>
            </Box>
          )}
        </Box>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        <Grid container spacing={3}>
          {/* Left column: Event & Counterparty details */}
          <Grid item xs={12} md={4}>
            {/* Event details card */}
            {event && (
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Информация о мероприятии
                  </Typography>
                  
                  <List dense>
                    <ListItem>
                      <ListItemIcon>
                        <EventIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Тип мероприятия" 
                        secondary={event.eventType.charAt(0).toUpperCase() + event.eventType.slice(1)} 
                      />
                    </ListItem>
                    
                    <ListItem>
                      <ListItemIcon>
                        <EventIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Дата и время" 
                        secondary={`${event.date} ${event.time}`} 
                      />
                    </ListItem>
                    
                    <ListItem>
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Место проведения" 
                        secondary={event.location} 
                      />
                    </ListItem>
                    
                    {negotiation && (
                      <ListItem>
                        <ListItemIcon>
                          <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Требуемая услуга"
                          secondary={
                            <>
                              <Typography variant="body2" component="span">
                                {serviceTypeLabels[negotiation.serviceType] || negotiation.serviceType}
                              </Typography>
                              {event.services.filter(s => s.type === negotiation.serviceType).map((service, idx) => (
                                <Box key={idx} sx={{ mt: 1 }}>
                                  <Typography variant="body2">{service.description}</Typography>
                                  <Typography variant="body2" fontWeight="bold">
                                    Бюджет: {service.budget.toLocaleString()} ₽
                                  </Typography>
                                </Box>
                              ))}
                            </>
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                </CardContent>
              </Card>
            )}
            
            {/* Counterparty details */}
            {counterparty && (
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {userData?.userType === 'performer' ? 'Организатор' : 'Исполнитель'}
                  </Typography>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar 
                      src={counterparty.profileImageUrl} 
                      alt={counterparty.fullName}
                      sx={{ width: 64, height: 64, mr: 2 }}
                    />
                    <Box>
                      <Typography variant="h6">
                        {counterparty.fullName || 'Имя не указано'}
                      </Typography>
                      {counterparty.userType === 'performer' && counterparty.serviceCategory && (
                        <Chip 
                          size="small" 
                          label={serviceTypeLabels[counterparty.serviceCategory] || counterparty.serviceCategory}
                          color="primary"
                        />
                      )}
                    </Box>
                  </Box>
                  
                  <List dense>
                    {counterparty.phone && (
                      <ListItem>
                        <ListItemIcon>
                          <PhoneIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={counterparty.phone} />
                      </ListItem>
                    )}
                    
                    {counterparty.email && (
                      <ListItem>
                        <ListItemIcon>
                          <EmailIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={counterparty.email} />
                      </ListItem>
                    )}
                    
                    {counterparty.website && (
                      <ListItem>
                        <ListItemIcon>
                          <AlternateEmailIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            <a 
                              href={counterparty.website} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              style={{ color: theme.palette.primary.main }}
                            >
                              {counterparty.website}
                            </a>
                          } 
                        />
                      </ListItem>
                    )}
                    
                    {counterparty.location && (
                      <ListItem>
                        <ListItemIcon>
                          <LocationOnIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={counterparty.location} />
                      </ListItem>
                    )}
                  </List>
                  
                  {counterparty.aboutMe && (
                    <>
                      <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                        О себе:
                      </Typography>
                      <Typography variant="body2">
                        {counterparty.aboutMe}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            )}
            
            {/* Action buttons for pending negotiations */}
            {canTakeAction() && (
              <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                <Button 
                  variant="contained" 
                  color="primary"
                  fullWidth
                  onClick={() => openConfirmDialog('approve')}
                  startIcon={<CheckCircleIcon />}
                >
                  Согласовать
                </Button>
                <Button 
                  variant="outlined" 
                  color="error"
                  fullWidth
                  onClick={() => openConfirmDialog('reject')}
                  startIcon={<CancelIcon />}
                >
                  Отклонить
                </Button>
              </Box>
            )}
          </Grid>
          
          {/* Right column: Message exchange */}
          <Grid item xs={12} md={8}>
            <Paper 
              elevation={2} 
              sx={{ 
                borderRadius: 2, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '70vh', 
                minHeight: 500 
              }}
            >
              {/* Chat header */}
              <Box sx={{ p: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
                <Typography variant="h6">
                  Сообщения
                </Typography>
              </Box>
              
              {/* Messages area */}
              <Box 
                sx={{ 
                  flexGrow: 1, 
                  overflowY: 'auto', 
                  p: 2,
                  backgroundColor: theme.palette.background.default,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                {(!negotiation || !negotiation.messages || negotiation.messages.length === 0) ? (
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center', 
                      height: '100%',
                      color: theme.palette.text.secondary
                    }}
                  >
                    <Typography variant="body1">
                      Пока нет сообщений. Начните обсуждение!
                    </Typography>
                  </Box>
                ) : (
                  negotiation.messages.map((msg, index) => {
                    const isCurrentUser = msg.senderId === userData?.uid;
                    const senderName = isCurrentUser 
                      ? 'Вы' 
                      : (msg.senderId === counterparty?.uid ? counterparty.fullName : 'Собеседник');
                    
                    return (
                      <Box 
                        key={index} 
                        sx={{ 
                          alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
                          maxWidth: '70%'
                        }}
                      >
                        <Paper
                          elevation={1}
                          sx={{
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: isCurrentUser 
                              ? theme.palette.primary.main 
                              : theme.palette.background.paper,
                            color: isCurrentUser 
                              ? theme.palette.primary.contrastText 
                              : theme.palette.text.primary
                          }}
                        >
                          <Typography variant="body1">{msg.content}</Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, opacity: 0.7 }}>
                            <Typography variant="caption">{senderName}</Typography>
                            <Typography variant="caption">{formatDate(msg.timestamp)}</Typography>
                          </Box>
                        </Paper>
                      </Box>
                    );
                  })
                )}
                <div ref={messagesEndRef} />
              </Box>
              
              {/* Message input */}
              <Box 
                sx={{ 
                  p: 2, 
                  display: 'flex', 
                  gap: 1, 
                  borderTop: `1px solid ${theme.palette.divider}`,
                  backgroundColor: theme.palette.background.paper
                }}
              >
                <TextField
                  fullWidth
                  placeholder="Введите сообщение..."
                  variant="outlined"
                  size="small"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  disabled={
                    sendingMessage || 
                    !negotiation || 
                    ['rejected', 'cancelled'].includes(negotiation.status)
                  }
                />
                <IconButton 
                  color="primary" 
                  onClick={handleSendMessage}
                  disabled={
                    sendingMessage || 
                    !message.trim() || 
                    !negotiation || 
                    ['rejected', 'cancelled'].includes(negotiation.status)
                  }
                >
                  {sendingMessage ? <CircularProgress size={24} /> : <SendIcon />}
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        
        {/* Tasks section */}
        <Box sx={{ mt: 4 }}>
          <Paper sx={{ p: 3, borderRadius: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5" component="h2" fontWeight={600}>
                Задачи по переговорам
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AssignmentIcon />}
                onClick={handleOpenTaskDialog}
                disabled={!negotiation || negotiation.status !== 'approved'}
              >
                Добавить задачу
              </Button>
            </Box>
            
            {loadingTasks ? (
              <CircularProgress />
            ) : tasks.length === 0 ? (
              <Typography variant="body1" color="textSecondary" align="center" sx={{ py: 4 }}>
                По этим переговорам пока нет задач
              </Typography>
            ) : (
              <List sx={{ width: '100%' }}>
                {tasks.map((task) => {
                  const isOverdue = isTaskOverdue(task);
                  const isAssignedToMe = task.assignedTo === userData?.uid;
                  
                  return (
                    <React.Fragment key={task.id}>
                      <ListItem
                        alignItems="flex-start"
                        sx={{
                          bgcolor: isOverdue && task.status !== 'completed' 
                            ? 'error.light' 
                            : task.status === 'completed'
                            ? 'success.light'
                            : undefined,
                          borderRadius: 1,
                          mb: 1
                        }}
                        secondaryAction={
                          isAssignedToMe && task.status !== 'completed' ? (
                            <Button
                              color="success"
                              startIcon={<AssignmentTurnedInIcon />}
                              onClick={() => task.id && handleCompleteTask(task.id)}
                            >
                              Выполнить
                            </Button>
                          ) : null
                        }
                      >
                        <ListItemIcon>
                          {task.status === 'completed' ? (
                            <AssignmentTurnedInIcon color="success" />
                          ) : (
                            <AssignmentIcon color={isOverdue ? "error" : "primary"} />
                          )}
                        </ListItemIcon>
                        
                        <ListItemText
                          primary={
                            <Typography 
                              variant="body1" 
                              component="div" 
                              fontWeight={500}
                              sx={{
                                textDecoration: task.status === 'completed' ? 'line-through' : 'none'
                              }}
                            >
                              {task.title}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                sx={{ display: 'block', mt: 0.5 }}
                              >
                                {task.description}
                              </Typography>
                              
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <DateRangeIcon fontSize="small" sx={{ mr: 1 }} />
                                <Typography
                                  variant="body2"
                                  color={isOverdue && task.status !== 'completed' ? 'error' : 'text.secondary'}
                                  sx={{ mr: 2 }}
                                >
                                  Дедлайн: {formatTaskDate(task.deadline)}
                                  {isOverdue && task.status !== 'completed' && ' (просрочено)'}
                                </Typography>
                                
                                <Chip 
                                  label={getTaskStatusLabel(task.status)} 
                                  size="small"
                                  color={
                                    task.status === 'completed' ? 'success' :
                                    task.status === 'overdue' ? 'error' :
                                    task.status === 'in_progress' ? 'info' : 'warning'
                                  }
                                  sx={{ mr: 1 }}
                                />
                                
                                <Typography variant="body2" color="text.secondary">
                                  {task.isForOrganizer && task.isForPerformer 
                                    ? 'Для всех' 
                                    : task.isForOrganizer 
                                    ? 'Для организатора' 
                                    : task.isForPerformer 
                                    ? 'Для исполнителя' 
                                    : ''}
                                </Typography>
                              </Box>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider component="li" sx={{ my: 1 }} />
                    </React.Fragment>
                  );
                })}
              </List>
            )}
          </Paper>
        </Box>
        
        {/* Task creation dialog */}
        <Dialog open={openTaskDialog} onClose={handleCloseTaskDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Добавить задачу</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Название задачи"
              name="title"
              value={taskForm.title}
              onChange={(e) => {
                // Direct handler for title field to ensure correct handling
                setTaskForm(prev => ({
                  ...prev,
                  title: e.target.value
                }));
              }}
              margin="normal"
              required
            />
            
            <TextField
              fullWidth
              label="Описание"
              name="description"
              value={taskForm.description}
              onChange={handleTaskFormChange}
              margin="normal"
              multiline
              rows={3}
            />
            
            <TextField
              fullWidth
              label="Дедлайн"
              name="deadline"
              type="date"
              value={taskForm.deadline}
              onChange={handleTaskFormChange}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
            />
            
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={taskForm.isForOrganizer}
                    onChange={handleTaskFormChange}
                    name="isForOrganizer"
                  />
                }
                label="Для организатора"
              />
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={taskForm.isForPerformer}
                    onChange={handleTaskFormChange}
                    name="isForPerformer"
                  />
                }
                label="Для исполнителя"
              />
            </Box>
          </DialogContent>
          
          <DialogActions>
            <Button onClick={handleCloseTaskDialog}>Отмена</Button>
            <Button 
              onClick={handleCreateTask} 
              color="primary"
              disabled={!taskForm.title || !taskForm.deadline}
            >
              Создать
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation Dialog */}
        <Dialog
          open={!!confirmAction}
          onClose={() => setConfirmAction(null)}
        >
          <DialogTitle>
            {confirmAction === 'approve' 
              ? 'Подтверждение согласования' 
              : 'Подтверждение отклонения'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmAction === 'approve' 
                ? 'Вы действительно хотите согласовать сотрудничество? После согласования обе стороны смогут продолжить обсуждение деталей.' 
                : 'Вы действительно хотите отклонить предложение? Это действие нельзя будет отменить.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmAction(null)} disabled={actionLoading}>
              Отмена
            </Button>
            <Button 
              onClick={handleConfirmAction} 
              color={confirmAction === 'approve' ? 'primary' : 'error'}
              variant="contained"
              disabled={actionLoading}
            >
              {actionLoading 
                ? <CircularProgress size={24} /> 
                : (confirmAction === 'approve' ? 'Согласовать' : 'Отклонить')}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default NegotiationDetails; 