import { UserData } from '../services/auth';

// Sample performers for the "decoration" category
export const decorationPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "floral.design@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Цветочная Мастерская 'Флора'",
    phone: "+7 (495) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1563241527-3004b7be0ffd?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Флористическая студия, специализирующаяся на оформлении мероприятий. Создаем уникальные цветочные композиции, арки, инсталляции и букеты. Работаем с живыми и стабилизированными цветами. Индивидуальный подход к каждому проекту.",
    location: "Москва",
    website: "https://floraldesign.ru",
    serviceCategory: "decoration",
    priceRange: "50000-300000",
    skills: ["Флористика", "Цветочные арки", "Стабилизированные цветы", "Свадебное оформление", "Президиум", "Букеты"],
    portfolioLinks: [
      "https://www.instagram.com/floral.design",
      "https://floraldesign.ru/portfolio"
    ],
    certificateUrls: [
      "https://example.com/certificates/floristry-school.jpg"
    ]
  },
  {
    email: "event.decor@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Студия Декора 'Идеальный День'",
    phone: "+7 (495) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1519225421980-715cb0215aed?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Полный спектр услуг по декору мероприятий: свадьбы, корпоративы, юбилеи, презентации. Создаем концепции оформления, подбираем мебель, текстиль, свет, декоративные элементы. Воплощаем любые стилистические решения.",
    location: "Москва",
    website: "https://perfect-day.ru",
    serviceCategory: "decoration",
    priceRange: "100000-500000",
    skills: ["Комплексное оформление", "Концепция мероприятия", "Текстильное оформление", "Мебель для мероприятий", "Световое оформление", "Банкетный декор"],
    portfolioLinks: [
      "https://perfect-day.ru/gallery",
      "https://www.instagram.com/perfect.day.decor"
    ],
    certificateUrls: []
  },
  {
    email: "wedding.decor@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Свадебный Декор 'Счастливый День'",
    phone: "+7 (495) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1519741347686-c1e331bd7d04?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специализируемся на свадебном декоре. Оформляем выездные церемонии, банкетные залы, президиумы молодоженов. Создаем уникальные детали, которые сделают ваш день особенным. Опыт работы более 5 лет, более 200 успешных проектов.",
    location: "Москва",
    website: "https://wedding-decor.ru",
    serviceCategory: "decoration",
    priceRange: "80000-350000",
    skills: ["Свадебный декор", "Выездная регистрация", "Оформление президиума", "Гостевые столы", "Фотозоны", "Свадебная полиграфия"],
    portfolioLinks: [
      "https://wedding-decor.ru/projects",
      "https://www.instagram.com/wedding.decor.ru"
    ],
    certificateUrls: [
      "https://example.com/certificates/wedding-decoration.jpg"
    ]
  },
  {
    email: "balloon.design@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Воздушный Дизайн",
    phone: "+7 (495) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1559084258-2d82fe7da2d9?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Создаем яркие и запоминающиеся оформления из воздушных шаров для любых мероприятий. Арки, гирлянды, фигуры, инсталляции, композиции из шаров. Используем качественные материалы и современные технологии.",
    location: "Москва",
    website: "https://balloon-design.ru",
    serviceCategory: "decoration",
    priceRange: "15000-100000",
    skills: ["Оформление шарами", "Арки из шаров", "Фигуры из шаров", "Гелиевые шары", "Фольгированные шары", "Светящиеся шары"],
    portfolioLinks: [
      "https://balloon-design.ru/gallery",
      "https://www.instagram.com/balloon.design"
    ],
    certificateUrls: []
  },
  {
    email: "light.design@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Световой Дизайн 'Иллюминация'",
    phone: "+7 (495) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1554232682-b9ef9c92f8de?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональное световое оформление мероприятий. Архитектурная подсветка, интерьерное освещение, световые инсталляции, проекционный маппинг. Создаем уникальную атмосферу с помощью света.",
    location: "Москва",
    website: "https://light-design.ru",
    serviceCategory: "decoration",
    priceRange: "70000-350000",
    skills: ["Световое оформление", "Архитектурная подсветка", "Проекционный маппинг", "Световые инсталляции", "Лазерное шоу", "Интерьерное освещение"],
    portfolioLinks: [
      "https://light-design.ru/works",
      "https://www.instagram.com/light.design"
    ],
    certificateUrls: [
      "https://example.com/certificates/light-engineering.jpg"
    ]
  },
  {
    email: "paper.decor@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Бумажный Декор 'Оригами'",
    phone: "+7 (495) 678-9012",
    profileImageUrl: "https://images.unsplash.com/photo-1535025183041-0991a977e25b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Создаем уникальный декор из бумаги для мероприятий: бумажные цветы, объемные инсталляции, фотозоны, гирлянды. Работаем с различными техниками и материалами. Экологичный и оригинальный подход к декору.",
    location: "Санкт-Петербург",
    website: "https://paper-decor.ru",
    serviceCategory: "decoration",
    priceRange: "20000-120000",
    skills: ["Бумажный декор", "Гигантские цветы", "Объемные инсталляции", "Фотозоны", "Бумажные гирлянды", "Эко-декор"],
    portfolioLinks: [
      "https://paper-decor.ru/portfolio",
      "https://www.instagram.com/paper.decor"
    ],
    certificateUrls: []
  },
  {
    email: "thematic.decor@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Тематический Декор 'Фантазия'",
    phone: "+7 (495) 789-0123",
    profileImageUrl: "https://images.unsplash.com/photo-1563208899-baa0446fdfd4?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Специализируемся на создании тематических декораций для мероприятий: стилизованные вечеринки, костюмированные балы, исторические реконструкции, корпоративные праздники. Создаем полное погружение в выбранную тематику.",
    location: "Москва",
    website: "https://thematic-decor.ru",
    serviceCategory: "decoration",
    priceRange: "100000-500000",
    skills: ["Тематические декорации", "Стилизованные мероприятия", "Исторические реконструкции", "Корпоративные праздники", "Костюмированные балы", "Иммерсивный опыт"],
    portfolioLinks: [
      "https://thematic-decor.ru/themes",
      "https://www.instagram.com/thematic.decor"
    ],
    certificateUrls: [
      "https://example.com/certificates/stage-design.jpg"
    ]
  },
  {
    email: "textile.decor@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Текстильный Декор 'Элегант'",
    phone: "+7 (495) 890-1234",
    profileImageUrl: "https://images.unsplash.com/photo-1489599849927-2ee91cede3ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Оформление мероприятий текстилем: драпировки, скатерти, салфетки, чехлы на стулья, банты, ленты. Огромный выбор тканей различных фактур и цветов. Создаем элегантное и гармоничное пространство с помощью текстиля.",
    location: "Москва",
    website: "https://textile-decor.ru",
    serviceCategory: "decoration",
    priceRange: "30000-150000",
    skills: ["Текстильное оформление", "Драпировки", "Чехлы на стулья", "Скатерти", "Банты и ленты", "Шатры"],
    portfolioLinks: [
      "https://textile-decor.ru/works",
      "https://www.instagram.com/textile.decor"
    ],
    certificateUrls: []
  },
  {
    email: "eco.decor@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "ЭкоДекор 'Зеленый Стиль'",
    phone: "+7 (495) 901-2345",
    profileImageUrl: "https://images.unsplash.com/photo-1582794543139-8ac9cb0f7b11?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Экологичный подход к оформлению мероприятий. Используем живые растения, сухоцветы, натуральные материалы. Создаем зеленые стены, подвесные сады, растительные инсталляции. Аренда и обслуживание растений для мероприятий.",
    location: "Москва",
    website: "https://eco-decor.ru",
    serviceCategory: "decoration",
    priceRange: "40000-200000",
    skills: ["Эко-декор", "Зеленые стены", "Подвесные сады", "Растительные инсталляции", "Сухоцветы", "Натуральные материалы"],
    portfolioLinks: [
      "https://eco-decor.ru/gallery",
      "https://www.instagram.com/eco.decor"
    ],
    certificateUrls: [
      "https://example.com/certificates/landscape-design.jpg"
    ]
  },
  {
    email: "prop.rental@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Аренда Декора 'Реквизит'",
    phone: "+7 (495) 012-3456",
    profileImageUrl: "https://images.unsplash.com/photo-1517263904808-5dc91e3e7044?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Предоставляем в аренду декоративные элементы для мероприятий: винтажная мебель, предметы интерьера, декоративные конструкции, арт-объекты, фотобутафория. Огромный выбор реквизита различных стилей и эпох.",
    location: "Москва",
    website: "https://prop-rental.ru",
    serviceCategory: "decoration",
    priceRange: "10000-100000",
    skills: ["Аренда декора", "Винтажная мебель", "Декоративные элементы", "Фотобутафория", "Арт-объекты", "Предметы интерьера"],
    portfolioLinks: [
      "https://prop-rental.ru/catalog",
      "https://www.instagram.com/prop.rental"
    ],
    certificateUrls: []
  }
]; 