import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC4JPz68_IxoF_wGHHC9UE_FgqeI9BJNAQ",
  authDomain: "makeevent-15e95.firebaseapp.com",
  projectId: "makeevent-15e95",
  storageBucket: "makeevent-15e95.firebasestorage.app",
  messagingSenderId: "411883274123",
  appId: "1:411883274123:web:5246d510cc610bfd82f98e",
  measurementId: "G-MZFD95VTGV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app; 