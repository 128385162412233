import { collection, doc, setDoc, getDoc, getDocs, query, where, updateDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { UserData } from './auth';
import { Event, EventService } from './event';

// Status types for negotiations
export type NegotiationStatus = 
  'pending_organizer' |     // Performer expressed interest, waiting for organizer
  'pending_performer' |     // Organizer invited performer, waiting for performer
  'approved' |              // Both parties approved, can proceed to negotiate
  'rejected' |              // One party rejected the negotiation
  'completed' |             // Negotiation completed successfully
  'cancelled';              // Negotiation cancelled

// Interface for negotiation data
export interface Negotiation {
  id?: string;
  eventId: string;
  organizerId: string;
  performerId: string;
  serviceType: string;
  serviceId?: string; // Optional identifier for specific service within event
  status: NegotiationStatus;
  createdAt: string;
  updatedAt: string;
  createdBy: 'organizer' | 'performer'; // Who initiated the negotiation
  messages?: {
    senderId: string;
    content: string;
    timestamp: string;
  }[];
  notes?: string;
  budget?: number;
}

// Express interest as a performer
export const expressInterest = async (
  eventId: string, 
  performerId: string,
  serviceType: string,
  serviceId?: string
): Promise<string> => {
  try {
    // Check if already expressed interest
    const existingQuery = query(
      collection(db, 'negotiations'),
      where('eventId', '==', eventId),
      where('performerId', '==', performerId),
      where('serviceType', '==', serviceType)
    );
    
    const querySnapshot = await getDocs(existingQuery);
    if (!querySnapshot.empty) {
      // Already exists
      return querySnapshot.docs[0].id;
    }
    
    // Get event details to find organizer ID
    const eventDoc = await getDoc(doc(db, 'events', eventId));
    if (!eventDoc.exists()) {
      throw new Error('Event not found');
    }
    
    const event = eventDoc.data() as Event;
    
    const timestamp = new Date().toISOString();
    const negotiation: Omit<Negotiation, 'id'> = {
      eventId,
      organizerId: event.organizerId,
      performerId,
      serviceType,
      status: 'pending_organizer',
      createdAt: timestamp,
      updatedAt: timestamp,
      createdBy: 'performer'
    };
    
    // Only add serviceId if it's provided
    if (serviceId) {
      negotiation.serviceId = serviceId;
    }
    
    const docRef = doc(collection(db, 'negotiations'));
    await setDoc(docRef, negotiation);
    
    return docRef.id;
  } catch (error: any) {
    console.error('Error expressing interest:', error);
    throw new Error(error.message);
  }
};

// Invite a performer as an organizer
export const invitePerformer = async (
  eventId: string,
  organizerId: string,
  performerId: string,
  serviceType: string,
  serviceId?: string
): Promise<string> => {
  try {
    // Check if already invited
    const existingQuery = query(
      collection(db, 'negotiations'),
      where('eventId', '==', eventId),
      where('performerId', '==', performerId),
      where('serviceType', '==', serviceType)
    );
    
    const querySnapshot = await getDocs(existingQuery);
    if (!querySnapshot.empty) {
      // Already exists
      return querySnapshot.docs[0].id;
    }
    
    const timestamp = new Date().toISOString();
    const negotiation: Omit<Negotiation, 'id'> = {
      eventId,
      organizerId,
      performerId,
      serviceType,
      serviceId,
      status: 'pending_performer',
      createdAt: timestamp,
      updatedAt: timestamp,
      createdBy: 'organizer'
    };
    
    const docRef = doc(collection(db, 'negotiations'));
    await setDoc(docRef, negotiation);
    
    return docRef.id;
  } catch (error: any) {
    console.error('Error inviting performer:', error);
    throw new Error(error.message);
  }
};

// Approve a negotiation
export const approveNegotiation = async (negotiationId: string, userId: string): Promise<void> => {
  try {
    const negotiationDoc = await getDoc(doc(db, 'negotiations', negotiationId));
    if (!negotiationDoc.exists()) {
      throw new Error('Negotiation not found');
    }
    
    const negotiation = negotiationDoc.data() as Negotiation;
    
    // Determine if the user is organizer or performer
    const isOrganizer = negotiation.organizerId === userId;
    const isPerformer = negotiation.performerId === userId;
    
    if (!isOrganizer && !isPerformer) {
      throw new Error('Unauthorized to approve this negotiation');
    }
    
    let newStatus: NegotiationStatus;
    
    // If pending organizer and organizer approves, or pending performer and performer approves
    if (
      (negotiation.status === 'pending_organizer' && isOrganizer) || 
      (negotiation.status === 'pending_performer' && isPerformer)
    ) {
      newStatus = 'approved';
    } else {
      throw new Error('Invalid approval state');
    }
    
    await updateDoc(doc(db, 'negotiations', negotiationId), {
      status: newStatus,
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    console.error('Error approving negotiation:', error);
    throw new Error(error.message);
  }
};

// Reject a negotiation
export const rejectNegotiation = async (negotiationId: string, userId: string): Promise<void> => {
  try {
    const negotiationDoc = await getDoc(doc(db, 'negotiations', negotiationId));
    if (!negotiationDoc.exists()) {
      throw new Error('Negotiation not found');
    }
    
    const negotiation = negotiationDoc.data() as Negotiation;
    
    // Determine if the user is organizer or performer
    const isOrganizer = negotiation.organizerId === userId;
    const isPerformer = negotiation.performerId === userId;
    
    if (!isOrganizer && !isPerformer) {
      throw new Error('Unauthorized to reject this negotiation');
    }
    
    await updateDoc(doc(db, 'negotiations', negotiationId), {
      status: 'rejected',
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    console.error('Error rejecting negotiation:', error);
    throw new Error(error.message);
  }
};

// Get negotiations for a performer
export const getPerformerNegotiations = async (performerId: string): Promise<Negotiation[]> => {
  try {
    const negotiationsQuery = query(
      collection(db, 'negotiations'),
      where('performerId', '==', performerId)
    );
    
    const querySnapshot = await getDocs(negotiationsQuery);
    const negotiations: Negotiation[] = [];
    
    querySnapshot.forEach((doc) => {
      negotiations.push({ id: doc.id, ...doc.data() } as Negotiation);
    });
    
    return negotiations.sort((a, b) => 
      new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
  } catch (error: any) {
    console.error('Error getting performer negotiations:', error);
    throw new Error(error.message);
  }
};

// Get negotiations for an organizer
export const getOrganizerNegotiations = async (organizerId: string): Promise<Negotiation[]> => {
  try {
    const negotiationsQuery = query(
      collection(db, 'negotiations'),
      where('organizerId', '==', organizerId)
    );
    
    const querySnapshot = await getDocs(negotiationsQuery);
    const negotiations: Negotiation[] = [];
    
    querySnapshot.forEach((doc) => {
      negotiations.push({ id: doc.id, ...doc.data() } as Negotiation);
    });
    
    return negotiations.sort((a, b) => 
      new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
  } catch (error: any) {
    console.error('Error getting organizer negotiations:', error);
    throw new Error(error.message);
  }
};

// Get negotiations for a specific event
export const getEventNegotiations = async (eventId: string): Promise<Negotiation[]> => {
  try {
    const negotiationsQuery = query(
      collection(db, 'negotiations'),
      where('eventId', '==', eventId)
    );
    
    const querySnapshot = await getDocs(negotiationsQuery);
    const negotiations: Negotiation[] = [];
    
    querySnapshot.forEach((doc) => {
      negotiations.push({ id: doc.id, ...doc.data() } as Negotiation);
    });
    
    return negotiations.sort((a, b) => 
      new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
  } catch (error: any) {
    console.error('Error getting event negotiations:', error);
    throw new Error(error.message);
  }
};

// Get a specific negotiation
export const getNegotiationById = async (negotiationId: string): Promise<Negotiation | null> => {
  try {
    const negotiationDoc = await getDoc(doc(db, 'negotiations', negotiationId));
    if (!negotiationDoc.exists()) {
      return null;
    }
    
    return { id: negotiationDoc.id, ...negotiationDoc.data() } as Negotiation;
  } catch (error: any) {
    console.error('Error getting negotiation:', error);
    throw new Error(error.message);
  }
};

// Add message to negotiation
export const addMessageToNegotiation = async (
  negotiationId: string, 
  senderId: string, 
  content: string
): Promise<void> => {
  try {
    const negotiationRef = doc(db, 'negotiations', negotiationId);
    const negotiationDoc = await getDoc(negotiationRef);
    
    if (!negotiationDoc.exists()) {
      throw new Error('Negotiation not found');
    }
    
    const negotiation = negotiationDoc.data() as Negotiation;
    
    // Check if user is part of the negotiation
    if (negotiation.organizerId !== senderId && negotiation.performerId !== senderId) {
      throw new Error('Unauthorized to send message in this negotiation');
    }
    
    const messages = negotiation.messages || [];
    const newMessage = {
      senderId,
      content,
      timestamp: new Date().toISOString()
    };
    
    await updateDoc(negotiationRef, {
      messages: [...messages, newMessage],
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    console.error('Error adding message:', error);
    throw new Error(error.message);
  }
};

// Add a function for real-time listening to negotiation updates
export const listenToNegotiation = (
  negotiationId: string, 
  callback: (negotiation: Negotiation) => void,
  errorCallback: (error: Error) => void
): (() => void) => {
  try {
    const negotiationRef = doc(db, 'negotiations', negotiationId);
    
    // Set up real-time listener
    const unsubscribe = onSnapshot(
      negotiationRef, 
      (doc) => {
        if (doc.exists()) {
          const negotiation = { id: doc.id, ...doc.data() } as Negotiation;
          callback(negotiation);
        }
      },
      (error) => {
        console.error('Error listening to negotiation:', error);
        errorCallback(error);
      }
    );
    
    // Return the unsubscribe function
    return unsubscribe;
  } catch (error: any) {
    console.error('Error setting up negotiation listener:', error);
    errorCallback(error);
    // Return a no-op function in case of setup error
    return () => {};
  }
}; 