import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Button, 
  Fab, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  CircularProgress, 
  Alert, 
  Chip,
  IconButton,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Tab,
  Tabs,
  Autocomplete
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Calendar as BigCalendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse, startOfWeek, getDay, addDays } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import EventIcon from '@mui/icons-material/Event';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { SelectChangeEvent } from '@mui/material/Select';

import { 
  CalendarItem, 
  CalendarItemType, 
  addCalendarItem, 
  getAllCalendarItems,
  updateCalendarItem,
  deleteCalendarItem,
  completeCalendarItem
} from '../services/calendar';

import {
  Task,
  addTask,
  updateTask,
  completeTask,
  getTaskById,
  getTasksByEventId,
  getTasksByNegotiationId,
  getTaskStatusLabel
} from '../services/tasks';

import { getNegotiationById } from '../services/negotiations';
import { getEventById } from '../services/event';
import { getUserData } from '../services/auth';
import toast from 'react-hot-toast';

// Define localization for BigCalendar
const locales = {
  'ru': ru,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// Interface for the form data
interface CalendarFormData {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  allDay: boolean;
  type: CalendarItemType;
  // Task-specific fields
  eventId?: string;
  negotiationId?: string;
  assignedTo?: string;
  isForOrganizer?: boolean;
  isForPerformer?: boolean;
}

// Custom toolbar component
const CustomToolbar = ({ onNavigate, label, onView, view }: any) => {
  const theme = useTheme();
  
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2,
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          '& > *': { mb: 1 }
        }
      }}
    >
      <Box>
        <Button 
          onClick={() => onNavigate('TODAY')}
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
        >
          Сегодня
        </Button>
        <Button 
          onClick={() => onNavigate('PREV')}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          Назад
        </Button>
        <Button 
          onClick={() => onNavigate('NEXT')}
          variant="outlined"
        >
          Вперед
        </Button>
      </Box>
      
      <Typography variant="h5" component="span">
        {label}
      </Typography>
      
      <Box>
        <Button 
          onClick={() => onView(Views.MONTH)}
          variant={view === Views.MONTH ? "contained" : "outlined"}
          color="secondary"
          sx={{ mr: 1 }}
        >
          Месяц
        </Button>
        <Button 
          onClick={() => onView(Views.WEEK)}
          variant={view === Views.WEEK ? "contained" : "outlined"}
          color="secondary"
          sx={{ mr: 1 }}
        >
          Неделя
        </Button>
        <Button 
          onClick={() => onView(Views.DAY)}
          variant={view === Views.DAY ? "contained" : "outlined"}
          color="secondary"
        >
          День
        </Button>
      </Box>
    </Box>
  );
};

// Types tab panel
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`calendar-tabpanel-${index}`}
      aria-labelledby={`calendar-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const Calendar: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  
  // State variables
  const [calendarItems, setCalendarItems] = useState<CalendarItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [tabValue, setTabValue] = useState(0);
  
  // Dialog states
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState<'add' | 'edit' | 'view'>('add');
  const [selectedItem, setSelectedItem] = useState<CalendarItem | null>(null);
  
  // Form data
  const [formData, setFormData] = useState<CalendarFormData>({
    title: '',
    description: '',
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    allDay: true,
    type: 'reminder'
  });
  
  // For task assignments
  const [availableUsers, setAvailableUsers] = useState<{id: string, name: string}[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectedNegotiation, setSelectedNegotiation] = useState<any>(null);
  const [loadingRelatedData, setLoadingRelatedData] = useState(false);
  
  // Fetch calendar items
  useEffect(() => {
    const fetchCalendarItems = async () => {
      if (!currentUser || !userData) return;
      
      try {
        setLoading(true);
        const items = await getAllCalendarItems(currentUser.uid, userData.userType);
        setCalendarItems(items);
      } catch (err: any) {
        console.error('Error fetching calendar items:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchCalendarItems();
  }, [currentUser, userData]);
  
  // Format calendar items for BigCalendar
  const events = useMemo(() => {
    return calendarItems.map(item => ({
      id: item.id,
      title: item.title,
      start: new Date(item.startDate),
      end: item.endDate ? new Date(item.endDate) : new Date(item.startDate),
      allDay: item.allDay,
      resource: item
    }));
  }, [calendarItems]);
  
  // Handle navigation back to dashboard
  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };
  
  // Dialog handlers
  const handleOpenAddDialog = () => {
    setDialogType('add');
    setFormData({
      title: '',
      description: '',
      startDate: new Date().toISOString().split('T')[0],
      endDate: new Date().toISOString().split('T')[0],
      allDay: true,
      type: 'reminder'
    });
    setSelectedEvent(null);
    setSelectedNegotiation(null);
    setOpenDialog(true);
  };
  
  const handleOpenEditDialog = (item: CalendarItem) => {
    setDialogType('edit');
    setSelectedItem(item);
    
    const formDataBase = {
      title: item.title,
      description: item.description || '',
      startDate: item.startDate.split('T')[0],
      endDate: item.endDate ? item.endDate.split('T')[0] : item.startDate.split('T')[0],
      allDay: item.allDay,
      type: item.type
    };
    
    setFormData(formDataBase);
    
    // For tasks, load additional data
    if (item.type === 'task' && item.relatedTaskId) {
      loadTaskDetails(item.relatedTaskId);
    }
    
    setOpenDialog(true);
  };
  
  const handleOpenViewDialog = (event: any) => {
    const item = event.resource as CalendarItem;
    setSelectedItem(item);
    setDialogType('view');
    
    // For tasks, load additional data
    if (item.type === 'task' && item.relatedTaskId) {
      loadTaskDetails(item.relatedTaskId);
    }
    
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };
  
  // Load task details
  const loadTaskDetails = async (taskId: string) => {
    try {
      setLoadingRelatedData(true);
      const task = await getTaskById(taskId.replace('task-', ''));
      
      if (task) {
        setFormData(prev => ({
          ...prev,
          eventId: task.eventId,
          negotiationId: task.negotiationId,
          assignedTo: task.assignedTo,
          isForOrganizer: task.isForOrganizer,
          isForPerformer: task.isForPerformer
        }));
        
        // Load related event
        const event = await getEventById(task.eventId);
        if (event) {
          setSelectedEvent(event);
        }
        
        // Load related negotiation if exists
        if (task.negotiationId) {
          const negotiation = await getNegotiationById(task.negotiationId);
          if (negotiation) {
            setSelectedNegotiation(negotiation);
            
            // Prepare available users for assignment
            const users = [];
            if (negotiation.organizerId) {
              const organizerData = await getUserData(negotiation.organizerId);
              if (organizerData) {
                users.push({
                  id: organizerData.uid,
                  name: `${organizerData.fullName} (Организатор)`
                });
              }
            }
            
            if (negotiation.performerId) {
              const performerData = await getUserData(negotiation.performerId);
              if (performerData) {
                users.push({
                  id: performerData.uid,
                  name: `${performerData.fullName} (Исполнитель)`
                });
              }
            }
            
            setAvailableUsers(users);
          }
        }
      }
    } catch (err) {
      console.error('Error loading task details:', err);
    } finally {
      setLoadingRelatedData(false);
    }
  };
  
  // Form handlers
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }> | SelectChangeEvent<CalendarItemType | string>) => {
    // Extract target properly
    const target = e.target;
    
    // Handle Select component separately
    if ('name' in target && 'value' in target) {
      const name = target.name as string;
      const value = target.value;
      
      // Special handling for task type selection
      if (name === 'type' && value === 'task') {
        // If switching to task type, initialize task-specific fields
        setFormData(prev => ({
          ...prev,
          [name]: value,
          eventId: '',
          negotiationId: '',
          assignedTo: '',
          isForOrganizer: false,
          isForPerformer: false
        }));
        
        // If changing the type, reset related data
        setSelectedEvent(null);
        setSelectedNegotiation(null);
        setAvailableUsers([]);
      } 
      // Handle checkbox inputs
      else if (target instanceof HTMLInputElement && target.type === 'checkbox') {
        setFormData(prev => ({
          ...prev,
          [name]: target.checked
        }));
      } 
      // Handle all other inputs (text, date, etc.)
      else {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    }
  };
  
  // Handle negotiation selection
  const handleNegotiationChange = async (negotiationId: string) => {
    if (!negotiationId) {
      setSelectedNegotiation(null);
      setAvailableUsers([]);
      return;
    }
    
    try {
      setLoadingRelatedData(true);
      const negotiation = await getNegotiationById(negotiationId);
      
      if (negotiation) {
        setSelectedNegotiation(negotiation);
        setFormData(prev => ({
          ...prev,
          negotiationId: negotiationId,
          eventId: negotiation.eventId
        }));
        
        // Also load the event
        const event = await getEventById(negotiation.eventId);
        if (event) {
          setSelectedEvent(event);
        }
        
        // Prepare available users for assignment
        const users = [];
        if (negotiation.organizerId) {
          const organizerData = await getUserData(negotiation.organizerId);
          if (organizerData) {
            users.push({
              id: organizerData.uid,
              name: `${organizerData.fullName} (Организатор)`
            });
          }
        }
        
        if (negotiation.performerId) {
          const performerData = await getUserData(negotiation.performerId);
          if (performerData) {
            users.push({
              id: performerData.uid,
              name: `${performerData.fullName} (Исполнитель)`
            });
          }
        }
        
        setAvailableUsers(users);
      }
    } catch (err) {
      console.error('Error loading negotiation details:', err);
    } finally {
      setLoadingRelatedData(false);
    }
  };
  
  // Handle event selection
  const handleEventChange = async (eventId: string) => {
    if (!eventId) {
      setSelectedEvent(null);
      // Don't clear negotiations/users if we have a negotiation selected
      if (!selectedNegotiation) {
        setAvailableUsers([]);
      }
      return;
    }
    
    try {
      setLoadingRelatedData(true);
      const event = await getEventById(eventId);
      
      if (event) {
        setSelectedEvent(event);
        setFormData(prev => ({
          ...prev,
          eventId: eventId
        }));
        
        // If no negotiation is selected, get the organizer for available users
        if (!selectedNegotiation && event.organizerId) {
          const organizerData = await getUserData(event.organizerId);
          if (organizerData) {
            setAvailableUsers([{
              id: organizerData.uid,
              name: `${organizerData.fullName} (Организатор)`
            }]);
          }
        }
      }
    } catch (err) {
      console.error('Error loading event details:', err);
    } finally {
      setLoadingRelatedData(false);
    }
  };
  
  // Add/Edit calendar item
  const handleSaveCalendarItem = async () => {
    if (!currentUser) return;
    
    try {
      if (formData.type === 'task') {
        // Handle saving a task
        if (!formData.eventId) {
          throw new Error('Необходимо выбрать мероприятие для задачи');
        }
        
        if (!formData.assignedTo) {
          throw new Error('Необходимо назначить задачу пользователю');
        }
        
        const taskData = {
          title: formData.title,
          description: formData.description,
          deadline: new Date(formData.startDate).toISOString(),
          eventId: formData.eventId,
          negotiationId: formData.negotiationId,
          createdBy: currentUser.uid,
          assignedTo: formData.assignedTo,
          isForOrganizer: Boolean(formData.isForOrganizer),
          isForPerformer: Boolean(formData.isForPerformer)
        };
        
        if (dialogType === 'add') {
          const taskId = await addTask(taskData);
          toast.success('Задача успешно создана');
          
          // Refresh calendar items
          const items = await getAllCalendarItems(currentUser.uid, userData?.userType || '');
          setCalendarItems(items);
        } else if (dialogType === 'edit' && selectedItem) {
          const taskId = selectedItem.relatedTaskId?.replace('task-', '');
          if (taskId) {
            await updateTask(taskId, taskData);
            toast.success('Задача успешно обновлена');
            
            // Refresh calendar items
            const items = await getAllCalendarItems(currentUser.uid, userData?.userType || '');
            setCalendarItems(items);
          }
        }
      } else {
        // Handle regular calendar items
        const itemData = {
          title: formData.title,
          description: formData.description,
          startDate: new Date(formData.startDate).toISOString(),
          endDate: formData.allDay ? undefined : new Date(formData.endDate).toISOString(),
          allDay: formData.allDay,
          type: formData.type,
          userId: currentUser.uid,
          color: getTypeColor(formData.type)
        };
        
        if (dialogType === 'add') {
          const id = await addCalendarItem(itemData as Omit<CalendarItem, 'id'>);
          setCalendarItems(prev => [...prev, { id, ...itemData } as CalendarItem]);
        } else if (dialogType === 'edit' && selectedItem) {
          await updateCalendarItem(selectedItem.id, itemData);
          setCalendarItems(prev => 
            prev.map(item => item.id === selectedItem.id ? { ...item, ...itemData } : item)
          );
        }
      }
      
      handleCloseDialog();
    } catch (err: any) {
      console.error('Error saving calendar item:', err);
      toast.error(err.message || 'Ошибка при сохранении');
      setError(err.message);
    }
  };
  
  // Delete calendar item
  const handleDeleteCalendarItem = async () => {
    if (!selectedItem) return;
    
    try {
      // Only allow deletion of custom items
      if (!selectedItem.id.includes('event-') && !selectedItem.id.includes('negotiation-')) {
        await deleteCalendarItem(selectedItem.id);
        setCalendarItems(prev => prev.filter(item => item.id !== selectedItem.id));
      }
      
      handleCloseDialog();
    } catch (err: any) {
      console.error('Error deleting calendar item:', err);
      setError(err.message);
    }
  };
  
  // Mark item as completed
  const handleCompleteItem = async () => {
    if (!selectedItem) return;
    
    try {
      // Only allow completion of custom items
      if (!selectedItem.id.includes('event-') && !selectedItem.id.includes('negotiation-')) {
        await completeCalendarItem(selectedItem.id);
        setCalendarItems(prev => 
          prev.map(item => item.id === selectedItem.id ? { ...item, completed: true } : item)
        );
      }
      
      handleCloseDialog();
    } catch (err: any) {
      console.error('Error completing calendar item:', err);
      setError(err.message);
    }
  };
  
  // Complete task
  const handleCompleteTask = async () => {
    if (!selectedItem || !selectedItem.relatedTaskId) return;
    
    try {
      const taskId = selectedItem.relatedTaskId.replace('task-', '');
      await completeTask(taskId);
      toast.success('Задача отмечена как выполненная');
      
      // Refresh calendar items
      const items = await getAllCalendarItems(currentUser?.uid || '', userData?.userType || '');
      setCalendarItems(items);
      
      handleCloseDialog();
    } catch (err: any) {
      console.error('Error completing task:', err);
      toast.error(err.message || 'Ошибка при выполнении задачи');
    }
  };
  
  // Filter items by type for tabs
  const upcomingItems = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    return calendarItems
      .filter(item => new Date(item.startDate) >= today && !item.completed)
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
  }, [calendarItems]);
  
  const completedItems = useMemo(() => {
    return calendarItems.filter(item => item.completed);
  }, [calendarItems]);
  
  // Helper function to get color based on type
  const getTypeColor = (type: CalendarItemType): string => {
    switch (type) {
      case 'event': return '#4caf50'; // Green
      case 'preparation': return '#ff9800'; // Orange
      case 'reminder': return '#2196f3'; // Blue
      case 'negotiation': return '#9c27b0'; // Purple
      case 'task': return '#fd8d3c'; // Orange
      default: return '#9e9e9e'; // Grey
    }
  };
  
  // Handle tab change
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  // Handle click on event or negotiation
  const handleItemClick = (item: CalendarItem) => {
    if (item.type === 'event' && item.relatedEventId) {
      navigate(`/events/${item.relatedEventId}`);
    } else if (item.type === 'negotiation' && item.relatedNegotiationId) {
      navigate(`/negotiations/${item.relatedNegotiationId}`);
    }
  };
  
  // Get type icon
  const getTypeIcon = (type: CalendarItemType) => {
    switch (type) {
      case 'event': return <EventIcon />;
      case 'preparation': return <CheckIcon />;
      case 'reminder': return <NotificationsIcon />;
      case 'negotiation': return <ChatIcon />;
      case 'task': return <AssignmentIcon />;
      default: return <EventIcon />;
    }
  };
  
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        pt: 6,
        pb: 8
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <IconButton
            onClick={handleBackToDashboard}
            color="inherit"
            sx={{ mr: 1 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1" fontWeight={700}>
            Календарь событий
          </Typography>
        </Box>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        <Paper sx={{ p: 2, mb: 4, borderRadius: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange}
              variant="fullWidth"
            >
              <Tab label="Календарь" />
              <Tab label="Предстоящие события" />
              <Tab label="Завершенные" />
            </Tabs>
          </Box>
          
          <TabPanel value={tabValue} index={0}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ height: 700, mt: 2 }}>
                <BigCalendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: '100%' }}
                  culture="ru"
                  views={[Views.MONTH, Views.WEEK, Views.DAY]}
                  defaultView={Views.MONTH}
                  onSelectEvent={handleOpenViewDialog}
                  components={{
                    toolbar: CustomToolbar
                  }}
                  eventPropGetter={(event) => {
                    const backgroundColor = event.resource?.color || '#9e9e9e';
                    const style = {
                      backgroundColor,
                      borderRadius: '4px',
                      opacity: 0.8,
                      color: '#fff',
                      border: '0px',
                      display: 'block',
                      textDecoration: event.resource?.completed ? 'line-through' : 'none'
                    };
                    return { style };
                  }}
                />
              </Box>
            )}
          </TabPanel>
          
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2}>
              {loading ? (
                <CircularProgress />
              ) : upcomingItems.length === 0 ? (
                <Box sx={{ width: '100%', p: 4, textAlign: 'center' }}>
                  <Typography color="textSecondary">
                    У вас нет предстоящих событий
                  </Typography>
                </Box>
              ) : (
                upcomingItems.map(item => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Paper
                      sx={{
                        p: 2,
                        cursor: (item.type === 'event' || item.type === 'negotiation') ? 'pointer' : 'default',
                        borderLeft: `4px solid ${item.color || getTypeColor(item.type)}`,
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: (item.type === 'event' || item.type === 'negotiation') ? 'translateY(-3px)' : 'none',
                          boxShadow: (item.type === 'event' || item.type === 'negotiation') ? 4 : 1
                        }
                      }}
                      onClick={() => 
                        (item.type === 'event' || item.type === 'negotiation') ? 
                        handleItemClick(item) : 
                        undefined
                      }
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {getTypeIcon(item.type)}
                          <Typography variant="h6" sx={{ ml: 1 }}>
                            {item.title}
                          </Typography>
                        </Box>
                        <Chip 
                          label={format(new Date(item.startDate), 'dd MMM', { locale: ru })} 
                          size="small" 
                          color="primary"
                        />
                      </Box>
                      
                      {item.description && (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                          {item.description}
                        </Typography>
                      )}
                      
                      {!item.id.includes('event-') && !item.id.includes('negotiation-') && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                          <IconButton 
                            size="small" 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenEditDialog(item);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      )}
                    </Paper>
                  </Grid>
                ))
              )}
            </Grid>
          </TabPanel>
          
          <TabPanel value={tabValue} index={2}>
            <Grid container spacing={2}>
              {loading ? (
                <CircularProgress />
              ) : completedItems.length === 0 ? (
                <Box sx={{ width: '100%', p: 4, textAlign: 'center' }}>
                  <Typography color="textSecondary">
                    У вас нет завершенных событий
                  </Typography>
                </Box>
              ) : (
                completedItems.map(item => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Paper
                      sx={{
                        p: 2,
                        borderLeft: `4px solid ${item.color || getTypeColor(item.type)}`,
                        opacity: 0.7
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {getTypeIcon(item.type)}
                          <Typography variant="h6" sx={{ ml: 1, textDecoration: 'line-through' }}>
                            {item.title}
                          </Typography>
                        </Box>
                        <Chip 
                          label={format(new Date(item.startDate), 'dd MMM', { locale: ru })} 
                          size="small" 
                          color="default"
                        />
                      </Box>
                      
                      {item.description && (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                          {item.description}
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                ))
              )}
            </Grid>
          </TabPanel>
        </Paper>
        
        {/* Floating action button for adding new items */}
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: 'fixed',
            bottom: 24,
            right: 24,
          }}
          onClick={handleOpenAddDialog}
        >
          <AddIcon />
        </Fab>
        
        {/* Add/Edit Dialog */}
        <Dialog 
          open={openDialog} 
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {dialogType === 'add' ? 'Добавить событие' : 
             dialogType === 'edit' ? 'Редактировать событие' : 
             'Детали события'}
          </DialogTitle>
          
          <DialogContent>
            {dialogType === 'view' ? (
              <Box sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {selectedItem && getTypeIcon(selectedItem.type)}
                  <Typography variant="h6" sx={{ ml: 1 }}>
                    {selectedItem?.title}
                  </Typography>
                </Box>
                
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Тип:
                  </Typography>
                  <Chip 
                    label={
                      selectedItem?.type === 'event' ? 'Мероприятие' :
                      selectedItem?.type === 'preparation' ? 'Подготовка' :
                      selectedItem?.type === 'reminder' ? 'Напоминание' :
                      selectedItem?.type === 'negotiation' ? 'Переговоры' :
                      selectedItem?.type === 'task' ? 'Задача' : ''
                    } 
                    color="primary" 
                    size="small"
                    sx={{ mt: 0.5 }}
                  />
                </Box>
                
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Дата:
                  </Typography>
                  <Typography>
                    {selectedItem && format(new Date(selectedItem.startDate), 'dd MMMM yyyy', { locale: ru })}
                  </Typography>
                </Box>
                
                {selectedItem?.description && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Описание:
                    </Typography>
                    <Typography>
                      {selectedItem.description}
                    </Typography>
                  </Box>
                )}

                {/* Task-specific information */}
                {selectedItem?.type === 'task' && (
                  <>
                    {selectedItem.status && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Статус:
                        </Typography>
                        <Chip 
                          label={getTaskStatusLabel(selectedItem.status as any)} 
                          color={
                            selectedItem.status === 'completed' ? 'success' :
                            selectedItem.status === 'overdue' ? 'error' :
                            selectedItem.status === 'in_progress' ? 'info' : 'warning'
                          }
                          size="small"
                          sx={{ mt: 0.5 }}
                        />
                      </Box>
                    )}
                    
                    {selectedEvent && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Мероприятие:
                        </Typography>
                        <Typography>
                          {selectedEvent.title}
                        </Typography>
                      </Box>
                    )}
                    
                    {selectedNegotiation && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Переговоры:
                        </Typography>
                        <Typography>
                          {`ID: ${selectedNegotiation.id}`}
                        </Typography>
                      </Box>
                    )}
                    
                    {availableUsers.length > 0 && selectedItem.assignedTo && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Назначено:
                        </Typography>
                        <Typography>
                          {availableUsers.find(u => u.id === selectedItem.assignedTo)?.name || selectedItem.assignedTo}
                        </Typography>
                      </Box>
                    )}
                    
                    <Box sx={{ display: 'flex', mt: 3 }}>
                      {selectedItem.status !== 'completed' && (
                        <Button 
                          variant="contained" 
                          color="success"
                          onClick={handleCompleteTask}
                          startIcon={<CheckIcon />}
                          sx={{ mr: 1 }}
                        >
                          Отметить как выполненное
                        </Button>
                      )}
                      
                      {selectedItem.relatedEventId && (
                        <Button 
                          variant="outlined" 
                          color="primary"
                          onClick={() => {
                            navigate(`/events/${selectedItem.relatedEventId}`);
                          }}
                          sx={{ mr: 1 }}
                        >
                          К мероприятию
                        </Button>
                      )}
                      
                      {selectedItem.relatedNegotiationId && (
                        <Button 
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            navigate(`/negotiations/${selectedItem.relatedNegotiationId}`);
                          }}
                        >
                          К переговорам
                        </Button>
                      )}
                    </Box>
                  </>
                )}
                
                {selectedItem?.relatedEventId && !selectedItem?.type.includes('task') && (
                  <Box sx={{ mt: 2 }}>
                    <Button 
                      variant="outlined" 
                      color="primary"
                      onClick={() => {
                        if (selectedItem.relatedEventId) {
                          navigate(`/events/${selectedItem.relatedEventId}`);
                        }
                      }}
                    >
                      Перейти к мероприятию
                    </Button>
                  </Box>
                )}
                
                {selectedItem?.relatedNegotiationId && !selectedItem?.type.includes('task') && (
                  <Box sx={{ mt: 2 }}>
                    <Button 
                      variant="outlined" 
                      color="primary"
                      onClick={() => {
                        if (selectedItem.relatedNegotiationId) {
                          navigate(`/negotiations/${selectedItem.relatedNegotiationId}`);
                        }
                      }}
                    >
                      Перейти к переговорам
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Box sx={{ mt: 1 }}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Тип</InputLabel>
                  <Select
                    name="type"
                    value={formData.type}
                    onChange={handleFormChange}
                    label="Тип"
                  >
                    <MenuItem value="reminder">Напоминание</MenuItem>
                    <MenuItem value="preparation">Подготовка</MenuItem>
                    <MenuItem value="task">Задача</MenuItem>
                  </Select>
                </FormControl>
                
                <TextField
                  fullWidth
                  label="Название"
                  name="title"
                  value={formData.title}
                  onChange={(e) => {
                    // Direct handler for title field to ensure correct handling
                    setFormData(prev => ({
                      ...prev,
                      title: e.target.value
                    }));
                  }}
                  margin="normal"
                  required
                />
                
                <TextField
                  fullWidth
                  label="Описание"
                  name="description"
                  value={formData.description}
                  onChange={handleFormChange}
                  margin="normal"
                  multiline
                  rows={3}
                />
                
                {formData.type === 'task' ? (
                  // Task-specific fields
                  <>
                    <TextField
                      fullWidth
                      label="Дедлайн"
                      name="startDate"
                      type="date"
                      value={formData.startDate}
                      onChange={handleFormChange}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                    
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Связанное мероприятие</InputLabel>
                      <Select
                        name="eventId"
                        value={formData.eventId || ''}
                        onChange={(e) => {
                          handleFormChange(e);
                          handleEventChange(e.target.value as string);
                        }}
                        label="Связанное мероприятие"
                        required
                      >
                        {events
                          .filter(e => e.resource?.type === 'event')
                          .map(e => (
                            <MenuItem key={e.resource?.relatedEventId} value={e.resource?.relatedEventId}>
                              {e.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Связанные переговоры</InputLabel>
                      <Select
                        name="negotiationId"
                        value={formData.negotiationId || ''}
                        onChange={(e) => {
                          handleFormChange(e);
                          handleNegotiationChange(e.target.value as string);
                        }}
                        label="Связанные переговоры"
                        disabled={!formData.eventId}
                      >
                        <MenuItem value="">Не выбрано</MenuItem>
                        {events
                          .filter(e => 
                            e.resource?.type === 'negotiation' && 
                            e.resource?.relatedEventId === formData.eventId
                          )
                          .map(e => (
                            <MenuItem key={e.resource?.relatedNegotiationId} value={e.resource?.relatedNegotiationId}>
                              {e.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    
                    {availableUsers.length > 0 && (
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Назначить пользователю</InputLabel>
                        <Select
                          name="assignedTo"
                          value={formData.assignedTo || ''}
                          onChange={handleFormChange}
                          label="Назначить пользователю"
                          required
                        >
                          {availableUsers.map(user => (
                            <MenuItem key={user.id} value={user.id}>
                              {user.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    
                    <Box sx={{ mt: 2 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formData.isForOrganizer)}
                            onChange={handleFormChange}
                            name="isForOrganizer"
                          />
                        }
                        label="Для организатора"
                      />
                      
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formData.isForPerformer)}
                            onChange={handleFormChange}
                            name="isForPerformer"
                          />
                        }
                        label="Для исполнителя"
                      />
                    </Box>
                  </>
                ) : (
                  // Regular calendar item fields
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.allDay}
                          onChange={handleFormChange}
                          name="allDay"
                        />
                      }
                      label="Весь день"
                      sx={{ mt: 1 }}
                    />
                    
                    <TextField
                      fullWidth
                      label="Дата начала"
                      name="startDate"
                      type="date"
                      value={formData.startDate}
                      onChange={handleFormChange}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                    
                    {!formData.allDay && (
                      <TextField
                        fullWidth
                        label="Дата окончания"
                        name="endDate"
                        type="date"
                        value={formData.endDate}
                        onChange={handleFormChange}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          
          <DialogActions>
            {dialogType === 'view' ? (
              <>
                {!selectedItem?.id.includes('event-') && 
                 !selectedItem?.id.includes('negotiation-') && 
                 !selectedItem?.relatedTaskId && (
                  <>
                    <Button 
                      onClick={handleCompleteItem} 
                      color="success"
                      disabled={selectedItem?.completed}
                    >
                      {selectedItem?.completed ? 'Завершено' : 'Завершить'}
                    </Button>
                    <Button 
                      onClick={handleDeleteCalendarItem} 
                      color="error"
                    >
                      Удалить
                    </Button>
                    <Button 
                      onClick={() => {
                        if (selectedItem) {
                          handleOpenEditDialog(selectedItem);
                        }
                      }} 
                      color="primary"
                    >
                      Редактировать
                    </Button>
                  </>
                )}
                <Button onClick={handleCloseDialog}>
                  Закрыть
                </Button>
              </>
            ) : (
              <>
                <Button onClick={handleCloseDialog}>
                  Отмена
                </Button>
                {dialogType === 'edit' && 
                 !selectedItem?.id.includes('event-') && 
                 !selectedItem?.id.includes('negotiation-') && 
                 !selectedItem?.relatedTaskId && (
                  <Button onClick={handleDeleteCalendarItem} color="error">
                    Удалить
                  </Button>
                )}
                <Button 
                  onClick={handleSaveCalendarItem} 
                  color="primary"
                  disabled={!formData.title || 
                            !formData.startDate || 
                            (formData.type === 'task' && (!formData.eventId || !formData.assignedTo))}
                >
                  Сохранить
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default Calendar; 