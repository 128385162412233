import { UserData } from '../services/auth';

// Sample performers for the "venue" category
export const venuePerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "grand.palace@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Гранд Палас",
    phone: "+7 (495) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1519167758481-83f550bb49b3?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Роскошный банкетный зал в центре Москвы. Площадь 1000 кв.м, вместимость до 400 гостей. Отличное расположение, собственная парковка, профессиональное оборудование и команда специалистов для организации любого мероприятия.",
    location: "Москва, ул. Тверская, 15",
    website: "https://grand-palace.ru",
    serviceCategory: "venue",
    priceRange: "200000-500000",
    skills: ["Банкетный зал", "Свадьбы", "Корпоративы", "Конференции", "Концерты", "Выставки"],
    portfolioLinks: [
      "https://www.instagram.com/grand.palace",
      "https://vk.com/grand_palace"
    ],
    certificateUrls: [
      "https://example.com/certificates/safety-certificate.jpg"
    ]
  },
  {
    email: "loft.space@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Лофт Пространство 'Свобода'",
    phone: "+7 (495) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1566737236500-c8ac43014a67?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Современное лофт-пространство в бывшем промышленном здании. Площадь 600 кв.м, высокие потолки, кирпичные стены, большие окна. Идеально для креативных мероприятий, фотосессий, презентаций и вечеринок.",
    location: "Москва, ул. Нижняя Сыромятническая, 10",
    website: "https://loft-svoboda.ru",
    serviceCategory: "venue",
    priceRange: "100000-250000",
    skills: ["Лофт", "Креативное пространство", "Фотосессии", "Вечеринки", "Презентации", "Мастер-классы"],
    portfolioLinks: [
      "https://www.instagram.com/loft.svoboda",
      "https://loft-svoboda.ru/gallery"
    ],
    certificateUrls: []
  },
  {
    email: "country.club@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Загородный Клуб 'Зеленый Бор'",
    phone: "+7 (495) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1464366400600-7168b8af9bc3?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Загородный комплекс в 30 км от Москвы. Территория 5 га, окруженная лесом. Банкетный зал, коттеджи для гостей, открытые беседки, озеро. Идеально для свадеб, корпоративов на природе и семейных мероприятий.",
    location: "Московская область, Истринский район",
    website: "https://zeleniy-bor.ru",
    serviceCategory: "venue",
    priceRange: "150000-400000",
    skills: ["Загородный клуб", "Свадьбы на природе", "Размещение гостей", "Корпоративный отдых", "Банкеты", "Активный отдых"],
    portfolioLinks: [
      "https://zeleniy-bor.ru/gallery",
      "https://www.instagram.com/zeleniy.bor"
    ],
    certificateUrls: [
      "https://example.com/certificates/ecological-certificate.jpg"
    ]
  },
  {
    email: "concert.hall@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Концертный Зал 'Гармония'",
    phone: "+7 (495) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный концертный зал с превосходной акустикой. Вместимость 600 человек, современное звуковое и световое оборудование. Подходит для концертов, спектаклей, презентаций и конференций.",
    location: "Санкт-Петербург, Невский проспект, 40",
    website: "https://harmony-hall.ru",
    serviceCategory: "venue",
    priceRange: "150000-350000",
    skills: ["Концертный зал", "Профессиональная акустика", "Концерты", "Спектакли", "Конференции", "Презентации"],
    portfolioLinks: [
      "https://harmony-hall.ru/events",
      "https://vk.com/harmony_hall"
    ],
    certificateUrls: [
      "https://example.com/certificates/acoustic-certificate.jpg"
    ]
  },
  {
    email: "restaurant.events@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Ресторан 'Белый Сад'",
    phone: "+7 (495) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Элегантный ресторан с европейской кухней и просторным банкетным залом. Вместимость до 120 гостей. Изысканный интерьер, панорамные окна с видом на парк, профессиональный сервис. Идеально для свадеб и торжественных мероприятий.",
    location: "Москва, ул. Покровка, 25",
    website: "https://white-garden.ru",
    serviceCategory: "venue",
    priceRange: "100000-300000",
    skills: ["Ресторан", "Банкетный зал", "Свадьбы", "Юбилеи", "Корпоративы", "Гастрономия"],
    portfolioLinks: [
      "https://white-garden.ru/banquet",
      "https://www.instagram.com/white.garden"
    ],
    certificateUrls: []
  },
  {
    email: "yacht.club@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Яхт-Клуб 'Бригантина'",
    phone: "+7 (495) 678-9012",
    profileImageUrl: "https://images.unsplash.com/photo-1534073828943-f801091bb18c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Престижный яхт-клуб с собственным причалом и рестораном. Предлагаем организацию мероприятий на берегу или на яхтах разной вместимости. Уникальные впечатления и незабываемые фотографии гарантированы.",
    location: "Москва, Ленинградское шоссе, 39",
    website: "https://brigantine-club.ru",
    serviceCategory: "venue",
    priceRange: "200000-600000",
    skills: ["Яхт-клуб", "Мероприятия на воде", "Свадьбы", "VIP-мероприятия", "Презентации", "Фотосессии"],
    portfolioLinks: [
      "https://brigantine-club.ru/events",
      "https://www.instagram.com/brigantine.club"
    ],
    certificateUrls: [
      "https://example.com/certificates/water-safety.jpg"
    ]
  },
  {
    email: "art.gallery@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Арт-Галерея 'Модерн'",
    phone: "+7 (495) 789-0123",
    profileImageUrl: "https://images.unsplash.com/photo-1577720580479-e7e918ea5b14?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Современное выставочное пространство в самом центре города. 300 кв.м свободного пространства с профессиональным освещением. Идеально для выставок, презентаций, лекций и камерных мероприятий.",
    location: "Москва, ул. Пятницкая, 20",
    website: "https://modern-gallery.ru",
    serviceCategory: "venue",
    priceRange: "80000-200000",
    skills: ["Галерея", "Выставки", "Презентации", "Лекции", "Мастер-классы", "Фуршеты"],
    portfolioLinks: [
      "https://modern-gallery.ru/space",
      "https://www.instagram.com/modern.gallery"
    ],
    certificateUrls: []
  },
  {
    email: "open.air@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Площадка 'Открытое Небо'",
    phone: "+7 (495) 890-1234",
    profileImageUrl: "https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Уникальная площадка на крыше небоскреба с панорамным видом на город. Площадь 500 кв.м, возможность установки шатров. Организуем мероприятия с марта по октябрь. Незабываемые впечатления для ваших гостей.",
    location: "Москва, Пресненская набережная, 12",
    website: "https://open-sky.ru",
    serviceCategory: "venue",
    priceRange: "300000-700000",
    skills: ["Площадка на крыше", "Панорамный вид", "Свадьбы", "Корпоративы", "Презентации", "Фотосессии"],
    portfolioLinks: [
      "https://open-sky.ru/gallery",
      "https://www.instagram.com/open.sky.moscow"
    ],
    certificateUrls: [
      "https://example.com/certificates/safety-height.jpg"
    ]
  },
  {
    email: "historical.mansion@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Исторический Особняк 'Усадьба'",
    phone: "+7 (495) 901-2345",
    profileImageUrl: "https://images.unsplash.com/photo-1542853647-47ad77242390?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Старинный особняк XIX века с аутентичными интерьерами и мебелью. Несколько залов различной вместимости, красивый сад. Идеально для тематических мероприятий, классических свадеб и торжественных приемов.",
    location: "Санкт-Петербург, наб. реки Фонтанки, 46",
    website: "https://usadba-mansion.ru",
    serviceCategory: "venue",
    priceRange: "250000-600000",
    skills: ["Исторический особняк", "Классический стиль", "Свадьбы", "Официальные приемы", "Тематические вечера", "Фотосессии"],
    portfolioLinks: [
      "https://usadba-mansion.ru/interiors",
      "https://www.instagram.com/usadba.spb"
    ],
    certificateUrls: [
      "https://example.com/certificates/heritage-building.jpg"
    ]
  },
  {
    email: "conference.center@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Конференц-Центр 'Бизнес Форум'",
    phone: "+7 (495) 012-3456",
    profileImageUrl: "https://images.unsplash.com/photo-1517457373958-b7bdd4587205?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Современный конференц-центр с полным техническим оснащением. 5 залов различной вместимости (от 20 до 500 человек), лобби для кофе-брейков, бизнес-кейтеринг. Профессиональное оборудование для проведения мероприятий любого формата.",
    location: "Москва, Кутузовский проспект, 32",
    website: "https://business-forum.ru",
    serviceCategory: "venue",
    priceRange: "100000-400000",
    skills: ["Конференц-центр", "Деловые мероприятия", "Конференции", "Семинары", "Презентации", "Тренинги"],
    portfolioLinks: [
      "https://business-forum.ru/halls",
      "https://www.instagram.com/business.forum"
    ],
    certificateUrls: [
      "https://example.com/certificates/conference-equipment.jpg"
    ]
  }
]; 