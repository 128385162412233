import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Paper, 
  List, 
  ListItem, 
  ListItemText, 
  Divider, 
  Button, 
  Chip, 
  CircularProgress, 
  Alert, 
  Tab, 
  Tabs,
  useTheme,
  Badge,
  Card,
  CardContent,
  CardActions,
  Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Negotiation, getPerformerNegotiations, getOrganizerNegotiations, getNegotiationById } from '../services/negotiations';
import { getEventById, Event } from '../services/event';
import { getUserData, UserData } from '../services/auth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';

// Map negotiation statuses to displays
const statusLabels: Record<string, { label: string, color: "default" | "success" | "error" | "warning" | "info" | "primary" | "secondary" }> = {
  'pending_organizer': { label: 'Ожидает ответа организатора', color: 'warning' },
  'pending_performer': { label: 'Ожидает вашего ответа', color: 'warning' },
  'approved': { label: 'Согласовано', color: 'success' },
  'rejected': { label: 'Отклонено', color: 'error' },
  'completed': { label: 'Завершено', color: 'info' },
  'cancelled': { label: 'Отменено', color: 'default' }
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`negotiations-tabpanel-${index}`}
      aria-labelledby={`negotiations-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Combined data for negotiations with event and user details
interface NegotiationWithDetails extends Negotiation {
  event?: Event;
  counterparty?: UserData; // Organizer if user is performer, or performer if user is organizer
}

const Negotiations: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userData } = useAuth();
  
  // State for negotiations
  const [negotiations, setNegotiations] = useState<NegotiationWithDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [tabValue, setTabValue] = useState(0);
  
  // Fetch the current user's negotiations
  useEffect(() => {
    const fetchNegotiations = async () => {
      if (!userData) {
        setLoading(false);
        setError('Пользователь не авторизован');
        return;
      }
      
      try {
        let fetchedNegotiations: Negotiation[] = [];
        
        if (userData.userType === 'performer') {
          fetchedNegotiations = await getPerformerNegotiations(userData.uid);
        } else {
          fetchedNegotiations = await getOrganizerNegotiations(userData.uid);
        }
        
        // Enrich with event and counterparty details
        const enrichedNegotiations: NegotiationWithDetails[] = [];
        
        for (const negotiation of fetchedNegotiations) {
          try {
            const event = await getEventById(negotiation.eventId);
            
            // Get counterparty details
            const counterpartyId = userData.userType === 'performer' 
              ? negotiation.organizerId 
              : negotiation.performerId;
            
            const counterparty = await getUserData(counterpartyId);
            
            enrichedNegotiations.push({
              ...negotiation,
              event: event || undefined,
              counterparty: counterparty || undefined
            });
          } catch (err) {
            console.error(`Error fetching details for negotiation ${negotiation.id}:`, err);
            // Still add the negotiation even if we couldn't get all details
            enrichedNegotiations.push(negotiation);
          }
        }
        
        setNegotiations(enrichedNegotiations);
      } catch (err) {
        console.error('Error fetching negotiations:', err);
        setError('Ошибка при загрузке переговоров');
      } finally {
        setLoading(false);
      }
    };
    
    fetchNegotiations();
  }, [userData]);
  
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };
  
  const handleViewNegotiation = (negotiationId: string) => {
    navigate(`/negotiations/${negotiationId}`);
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU', {
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };
  
  // Get active negotiations (pending or approved)
  const activeNegotiations = negotiations.filter(
    n => n.status === 'pending_organizer' || 
         n.status === 'pending_performer' || 
         n.status === 'approved'
  );
  
  // Get closed negotiations (rejected, completed, cancelled)
  const closedNegotiations = negotiations.filter(
    n => n.status === 'rejected' || 
         n.status === 'completed' || 
         n.status === 'cancelled'
  );
  
  // Filter based on user's role
  const pendingActionNegotiations = negotiations.filter(n => 
    (userData?.userType === 'performer' && n.status === 'pending_performer') ||
    (userData?.userType === 'organizer' && n.status === 'pending_organizer')
  );
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ minHeight: '100vh', py: 4, backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBackToDashboard}
            sx={{ mr: 2 }}
          >
            Вернуться
          </Button>
          <Typography variant="h4" component="h1" fontWeight={700}>
            Мои переговоры
          </Typography>
        </Box>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {!loading && negotiations.length === 0 ? (
          <Paper 
            elevation={2} 
            sx={{ p: 4, textAlign: 'center', borderRadius: 2 }}
          >
            <Typography variant="h6" gutterBottom>
              У вас пока нет активных переговоров
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {userData?.userType === 'performer' 
                ? 'Найдите интересные мероприятия и откликнитесь на них'
                : 'Найдите исполнителей и пригласите их к сотрудничеству'}
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => navigate(userData?.userType === 'performer' ? '/orders' : '/performers')}
              >
                {userData?.userType === 'performer' ? 'Найти мероприятия' : 'Найти исполнителей'}
              </Button>
            </Box>
          </Paper>
        ) : (
          <>
            <Paper sx={{ width: '100%', borderRadius: 2 }}>
              <Tabs 
                value={tabValue} 
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab 
                  label={
                    <Badge 
                      color="error" 
                      badgeContent={pendingActionNegotiations.length} 
                      showZero={false}
                    >
                      Требуют внимания
                    </Badge>
                  } 
                />
                <Tab 
                  label={
                    <Badge 
                      color="primary" 
                      badgeContent={activeNegotiations.length} 
                      showZero={false}
                    >
                      Активные
                    </Badge>
                  } 
                />
                <Tab label="Завершенные" />
              </Tabs>
              
              <TabPanel value={tabValue} index={0}>
                {pendingActionNegotiations.length === 0 ? (
                  <Box sx={{ py: 2, textAlign: 'center' }}>
                    <Typography variant="body1" color="textSecondary">
                      Нет переговоров, требующих вашего внимания
                    </Typography>
                  </Box>
                ) : (
                  <Grid container spacing={3}>
                    {pendingActionNegotiations.map((negotiation) => (
                      <Grid item xs={12} md={6} key={negotiation.id}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                              <Chip 
                                icon={<PendingIcon />}
                                label={statusLabels[negotiation.status].label}
                                color={statusLabels[negotiation.status].color}
                                size="small"
                              />
                              <Typography variant="caption" color="textSecondary">
                                {formatDate(negotiation.updatedAt)}
                              </Typography>
                            </Box>
                            
                            <Typography variant="h6" gutterBottom>
                              {negotiation.event?.title || 'Мероприятие'}
                            </Typography>
                            
                            <List dense>
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <EventIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.event?.eventType ? 
                                          (negotiation.event.eventType.charAt(0).toUpperCase() + negotiation.event.eventType.slice(1)) : 
                                          'Тип не указан'}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <PersonIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.counterparty?.fullName || 'Имя не указано'}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <GroupsIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.serviceType}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                            </List>
                          </CardContent>
                          
                          <CardActions>
                            <Button 
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => handleViewNegotiation(negotiation.id as string)}
                            >
                              Перейти к обсуждению
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </TabPanel>
              
              <TabPanel value={tabValue} index={1}>
                {activeNegotiations.length === 0 ? (
                  <Box sx={{ py: 2, textAlign: 'center' }}>
                    <Typography variant="body1" color="textSecondary">
                      Нет активных переговоров
                    </Typography>
                  </Box>
                ) : (
                  <Grid container spacing={3}>
                    {activeNegotiations.map((negotiation) => (
                      <Grid item xs={12} md={6} key={negotiation.id}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                              <Chip 
                                icon={negotiation.status === 'approved' ? <CheckCircleIcon /> : <PendingIcon />}
                                label={statusLabels[negotiation.status].label}
                                color={statusLabels[negotiation.status].color}
                                size="small"
                              />
                              <Typography variant="caption" color="textSecondary">
                                {formatDate(negotiation.updatedAt)}
                              </Typography>
                            </Box>
                            
                            <Typography variant="h6" gutterBottom>
                              {negotiation.event?.title || 'Мероприятие'}
                            </Typography>
                            
                            <List dense>
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <EventIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.event?.eventType ? 
                                          (negotiation.event.eventType.charAt(0).toUpperCase() + negotiation.event.eventType.slice(1)) : 
                                          'Тип не указан'}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <PersonIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.counterparty?.fullName || 'Имя не указано'}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <GroupsIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.serviceType}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                            </List>
                          </CardContent>
                          
                          <CardActions>
                            <Button 
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => handleViewNegotiation(negotiation.id as string)}
                            >
                              Перейти к обсуждению
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </TabPanel>
              
              <TabPanel value={tabValue} index={2}>
                {closedNegotiations.length === 0 ? (
                  <Box sx={{ py: 2, textAlign: 'center' }}>
                    <Typography variant="body1" color="textSecondary">
                      Нет завершенных переговоров
                    </Typography>
                  </Box>
                ) : (
                  <Grid container spacing={3}>
                    {closedNegotiations.map((negotiation) => (
                      <Grid item xs={12} md={6} key={negotiation.id}>
                        <Card sx={{ height: '100%' }}>
                          <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                              <Chip 
                                icon={
                                  negotiation.status === 'completed' ? <CheckCircleIcon /> : 
                                  negotiation.status === 'rejected' ? <CancelIcon /> : 
                                  <CancelIcon />
                                }
                                label={statusLabels[negotiation.status].label}
                                color={statusLabels[negotiation.status].color}
                                size="small"
                              />
                              <Typography variant="caption" color="textSecondary">
                                {formatDate(negotiation.updatedAt)}
                              </Typography>
                            </Box>
                            
                            <Typography variant="h6" gutterBottom>
                              {negotiation.event?.title || 'Мероприятие'}
                            </Typography>
                            
                            <List dense>
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <EventIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.event?.eventType ? 
                                          (negotiation.event.eventType.charAt(0).toUpperCase() + negotiation.event.eventType.slice(1)) : 
                                          'Тип не указан'}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <PersonIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.counterparty?.fullName || 'Имя не указано'}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              
                              <ListItem sx={{ px: 0 }}>
                                <ListItemText 
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <GroupsIcon fontSize="small" sx={{ mr: 1 }} />
                                      <Typography variant="body2">
                                        {negotiation.serviceType}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                            </List>
                          </CardContent>
                          
                          <CardActions>
                            <Button 
                              fullWidth
                              variant="outlined"
                              onClick={() => handleViewNegotiation(negotiation.id as string)}
                            >
                              Просмотреть детали
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </TabPanel>
            </Paper>
          </>
        )}
      </Container>
    </Box>
  );
};

export default Negotiations; 