import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { UserData } from './auth';
import { ServiceType, EventService } from './event';

// Interface for recommended performers
export interface RecommendedPerformer extends UserData {
  matchScore: number;
  reasons: string[];
}

// Default weights for different factors
const DEFAULT_WEIGHTS = {
  priceCompatibility: 0.4,
  locationMatch: 0.2,
  skillsMatch: 0.3,
  profileCompleteness: 0.1
};

/**
 * Fetch performers from Firestore based on service type
 * Modified to handle permission issues and optimize queries
 */
const fetchPerformersByServiceType = async (serviceType: ServiceType): Promise<UserData[]> => {
  try {
    // First, use a simpler query to avoid permission issues
    // Just query for performers with the correct userType, then filter in memory
    const performersQuery = query(
      collection(db, 'users'),
      where('userType', '==', 'performer'),
      // Set a reasonable limit to avoid large queries
      limit(50)
    );
    
    const querySnapshot = await getDocs(performersQuery);
    const performers: UserData[] = [];
    
    // Filter in memory to avoid complex queries that might cause permission issues
    querySnapshot.forEach((doc) => {
      const performer = doc.data() as UserData;
      if (performer.serviceCategory === serviceType) {
        performers.push(performer);
      }
    });
    
    return performers;
  } catch (error) {
    console.error(`Error fetching performers for ${serviceType}:`, error);
    return [];
  }
};

/**
 * Calculate price compatibility score (0-1)
 * Higher score means closer match to budget
 */
const calculatePriceCompatibilityScore = (
  performerPriceRange: string,
  serviceBudget: number
): number => {
  if (!performerPriceRange) return 0;
  
  // Parse price range (format: "min-max")
  const [minStr, maxStr] = performerPriceRange.split('-');
  const min = parseInt(minStr, 10);
  const max = parseInt(maxStr, 10);
  
  if (isNaN(min) || isNaN(max)) return 0;

  // Perfect match: budget is within price range
  if (serviceBudget >= min && serviceBudget <= max) {
    return 1;
  }
  
  // Budget is higher than max: still good but not perfect
  if (serviceBudget > max) {
    const percentOver = (serviceBudget - max) / max;
    return Math.max(0, 1 - (percentOver * 0.5)); // Small penalty for being over
  }
  
  // Budget is lower than min: bigger penalty
  const percentUnder = (min - serviceBudget) / min;
  return Math.max(0, 1 - percentUnder);
};

/**
 * Calculate location match score (0-1)
 * Currently simple exact match, could be enhanced with distance calculation
 */
const calculateLocationMatchScore = (
  performerLocation: string | undefined, 
  eventLocation: string
): number => {
  if (!performerLocation || !eventLocation) return 0;
  
  // Simple location matching - could be enhanced with geocoding and distance calculation
  if (performerLocation.toLowerCase().includes(eventLocation.toLowerCase()) || 
      eventLocation.toLowerCase().includes(performerLocation.toLowerCase())) {
    return 1;
  }
  
  // Check for city/region match (simple approach)
  const performerCityMatch = extractLocationParts(performerLocation);
  const eventCityMatch = extractLocationParts(eventLocation);
  
  if (performerCityMatch.some(part => eventCityMatch.includes(part))) {
    return 0.8;
  }
  
  return 0;
};

/**
 * Helper function to extract location parts for matching
 */
const extractLocationParts = (location: string): string[] => {
  // Split by common separators and clean
  return location
    .split(/[,\s]/)
    .map(part => part.toLowerCase().trim())
    .filter(part => part.length > 2); // Filter out short words
};

/**
 * Calculate skills match score (0-1)
 * Based on keyword matching between service description and performer skills
 */
const calculateSkillsMatchScore = (
  performerSkills: string[] | undefined,
  serviceDescription: string
): number => {
  if (!performerSkills || !performerSkills.length || !serviceDescription) return 0;
  
  const descriptionLower = serviceDescription.toLowerCase();
  let matchCount = 0;
  
  performerSkills.forEach(skill => {
    if (skill && descriptionLower.includes(skill.toLowerCase())) {
      matchCount++;
    }
  });
  
  if (matchCount === 0) return 0.1; // Base score
  if (matchCount > 3) return 1;  // Max score for 3+ matches
  
  return 0.1 + (0.3 * matchCount); // Graduated score
};

/**
 * Calculate profile completeness score (0-1)
 */
const calculateProfileCompletenessScore = (performer: UserData): number => {
  if (!performer) return 0;
  
  const importantFields = [
    'fullName',
    'profileImageUrl',
    'aboutMe',
    'priceRange',
    'skills',
    'location',
    'phone',
    'portfolioLinks'
  ];
  
  let completedCount = 0;
  importantFields.forEach(field => {
    const value = performer[field as keyof UserData];
    if (value && (
      typeof value === 'string' ? value.trim().length > 0 : 
      Array.isArray(value) ? value.length > 0 : true
    )) {
      completedCount++;
    }
  });
  
  return completedCount / importantFields.length;
};

/**
 * Generate human-readable reasons for recommendation
 */
const generateRecommendationReasons = (
  performer: UserData,
  scores: {
    priceCompatibility: number,
    locationMatch: number,
    skillsMatch: number,
    profileCompleteness: number
  }
): string[] => {
  const reasons: string[] = [];
  
  if (scores.priceCompatibility > 0.7) {
    reasons.push('Подходит по бюджету');
  }
  
  if (scores.locationMatch > 0) {
    reasons.push('Работает в требуемом регионе');
  }
  
  if (scores.skillsMatch > 0.3) {
    reasons.push('Обладает необходимыми навыками');
  }
  
  if (performer.portfolioLinks && performer.portfolioLinks.length > 0) {
    reasons.push('Имеет портфолио работ');
  }
  
  return reasons;
};

/**
 * Get recommended performers for a specific service
 */
export const getRecommendedPerformers = async (
  service: EventService,
  eventLocation: string,
  customWeights = DEFAULT_WEIGHTS
): Promise<RecommendedPerformer[]> => {
  try {
    // Validate input
    if (!service || !service.type || !eventLocation) {
      console.error('Invalid inputs for getRecommendedPerformers', { service, eventLocation });
      return [];
    }

    // Fetch performers
    const performers = await fetchPerformersByServiceType(service.type);
    
    if (!performers.length) {
      return [];
    }

    // Calculate scores for each performer
    const recommendedPerformers = performers.map(performer => {
      // Calculate individual scores
      const priceCompatibilityScore = calculatePriceCompatibilityScore(
        performer.priceRange || '',
        service.budget
      );
      
      const locationMatchScore = calculateLocationMatchScore(
        performer.location,
        eventLocation
      );
      
      const skillsMatchScore = calculateSkillsMatchScore(
        performer.skills,
        service.description
      );
      
      const profileCompletenessScore = calculateProfileCompletenessScore(
        performer
      );

      // Calculate weighted score
      const weightedScore = 
        priceCompatibilityScore * customWeights.priceCompatibility +
        locationMatchScore * customWeights.locationMatch +
        skillsMatchScore * customWeights.skillsMatch +
        profileCompletenessScore * customWeights.profileCompleteness;

      // Generate reasons based on scores
      const reasons = generateRecommendationReasons(performer, {
        priceCompatibility: priceCompatibilityScore,
        locationMatch: locationMatchScore,
        skillsMatch: skillsMatchScore,
        profileCompleteness: profileCompletenessScore
      });

      // Return performer with score and reasons
      return {
        ...performer,
        matchScore: weightedScore,
        reasons: reasons
      };
    });

    // Sort by score (highest first) and return top 5
    return recommendedPerformers
      .sort((a, b) => b.matchScore - a.matchScore)
      .slice(0, 5);
  } catch (error) {
    console.error('Error getting recommended performers:', error);
    return [];
  }
};

/**
 * Get recommendations for all services in an event
 */
export const getRecommendationsForEvent = async (
  services: EventService[],
  eventLocation: string
): Promise<Record<string, RecommendedPerformer[]>> => {
  if (!services || !Array.isArray(services)) return {};
  
  const recommendationsMap: Record<string, RecommendedPerformer[]> = {};
  
  for (const service of services) {
    if (service && service.type) {
      try {
        const recommendations = await getRecommendedPerformers(service, eventLocation);
        recommendationsMap[service.type] = recommendations;
      } catch (error) {
        console.error(`Error getting recommendations for service type ${service.type}:`, error);
        recommendationsMap[service.type] = [];
      }
    }
  }
  
  return recommendationsMap;
}; 