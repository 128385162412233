import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent,
  CardMedia,
  useTheme
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import PaymentIcon from '@mui/icons-material/Payment';
import StarIcon from '@mui/icons-material/Star';

const ProductSection: React.FC = () => {
  const theme = useTheme();

  const features = [
    {
      icon: <EventIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />,
      title: 'Удобное планирование',
      description: 'Интуитивный интерфейс для создания и управления мероприятиями любой сложности — от небольших встреч до масштабных фестивалей.'
    },
    {
      icon: <GroupIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />,
      title: 'Проверенные исполнители',
      description: 'Доступ к базе проверенных профессионалов — музыкантов, фотографов, ведущих, кейтеринговых служб и других специалистов.'
    },
    {
      icon: <PaymentIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />,
      title: 'Безопасные платежи',
      description: 'Защищенная система бронирования и оплаты с гарантией возврата средств в случае непредвиденных обстоятельств.'
    },
    {
      icon: <StarIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />,
      title: 'Рейтинги и отзывы',
      description: 'Прозрачная система рейтингов и отзывов помогает выбрать лучших исполнителей для вашего мероприятия.'
    }
  ];

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '40%',
          height: '40%',
          background: `radial-gradient(circle, ${theme.palette.secondary.main}30 0%, transparent 70%)`,
          filter: 'blur(60px)',
          zIndex: 0,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '30%',
          height: '30%',
          background: `radial-gradient(circle, ${theme.palette.primary.main}20 0%, transparent 70%)`,
          filter: 'blur(60px)',
          zIndex: 0,
        }
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              position: 'relative',
              display: 'inline-block',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 4,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 2,
              }
            }}
          >
            Платформа для идеальных мероприятий
          </Typography>
          
          <Typography 
            variant="h5" 
            sx={{ 
              maxWidth: 800, 
              mx: 'auto', 
              color: theme.palette.text.secondary,
              mb: 6
            }}
          >
            Мы соединяем организаторов мероприятий с талантливыми исполнителями, 
            делая процесс планирования простым и эффективным
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 12px 28px rgba(0, 0, 0, 0.15)',
                  }
                }}
              >
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    p: 3,
                    backgroundColor: `${theme.palette.secondary.main}20`,
                  }}
                >
                  {feature.icon}
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h3" sx={{ fontWeight: 600 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 10 }}>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" component="h3" sx={{ fontWeight: 600, mb: 3 }}>
                Все необходимое для успешного мероприятия
              </Typography>
              <Typography variant="body1" paragraph>
                Наша платформа предоставляет полный набор инструментов для организации мероприятий любого масштаба. 
                От планирования бюджета до выбора исполнителей — мы упрощаем каждый этап процесса.
              </Typography>
              <Typography variant="body1" paragraph>
                Благодаря интуитивно понятному интерфейсу и умным алгоритмам подбора, 
                вы сможете быстро найти идеальных исполнителей, соответствующих вашим требованиям и бюджету.
              </Typography>
              <Typography variant="body1">
                Система управления проектами помогает контролировать все аспекты организации, 
                а встроенные инструменты коммуникации обеспечивают эффективное взаимодействие между всеми участниками.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  overflow: 'hidden',
                  boxShadow: '0 15px 35px rgba(0,0,0,0.1)',
                }}
              >
                <CardMedia
                  component="img"
                  height="400"
                  image="https://images.unsplash.com/photo-1505236858219-8359eb29e329?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80"
                  alt="Платформа для организации мероприятий"
                  sx={{
                    transition: 'transform 0.5s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    }
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ProductSection; 