import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box, 
  Container,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';

const Header: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" color="default" elevation={0} sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      backdropFilter: 'blur(8px)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
    }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={RouterLink}
            to="/"
            sx={{ 
              flexGrow: 1, 
              fontWeight: 700,
              background: 'linear-gradient(45deg, #2E3B55 30%, #3F5175 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textDecoration: 'none'
            }}
          >
            MakeEvent
          </Typography>

          {isMobile ? (
            <>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>О нас</MenuItem>
                <MenuItem onClick={handleClose}>Контакты</MenuItem>
                <MenuItem onClick={handleClose}>Поддержка</MenuItem>
                <MenuItem 
                  component={RouterLink} 
                  to="/login"
                  onClick={handleClose}
                >
                  Войти
                </MenuItem>
                <MenuItem 
                  component={RouterLink} 
                  to="/register"
                  onClick={handleClose}
                >
                  Регистрация
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button color="inherit" sx={{ mx: 1 }}>О нас</Button>
              <Button color="inherit" sx={{ mx: 1 }}>Контакты</Button>
              <Button color="inherit" sx={{ mx: 1 }}>Поддержка</Button>
              <Button 
                component={RouterLink}
                to="/login"
                variant="outlined" 
                color="primary" 
                sx={{ mx: 1 }}
              >
                Войти
              </Button>
              <Button 
                component={RouterLink}
                to="/register"
                variant="contained" 
                color="primary" 
                sx={{ mx: 1 }}
              >
                Регистрация
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header; 