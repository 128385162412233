import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  useTheme,
  SelectChangeEvent,
  Alert,
  Paper,
  Snackbar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Event, EventType, getPerformerEvents, ServiceType } from '../services/event';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { expressInterest } from '../services/negotiations';
import { getPerformerNegotiations } from '../services/negotiations';

const eventTypeLabels: Record<string, string> = {
  'wedding': 'Свадьба',
  'corporate': 'Корпоратив',
  'concert': 'Концерт',
  'conference': 'Конференция',
  'party': 'Вечеринка',
  'other': 'Другое'
};

const PerformerOrders: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userData } = useAuth();
  
  // State for events and loading
  const [events, setEvents] = useState<Event[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  // State for filters
  const [searchQuery, setSearchQuery] = useState('');
  const [eventType, setEventType] = useState<EventType | ''>('');
  const [location, setLocation] = useState('');
  const [minBudget, setMinBudget] = useState<number>(0);
  const [maxBudget, setMaxBudget] = useState<number>(500000);
  
  // State for event details dialog
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  // Add new state for snackbar notifications
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [expressedInterestEvents, setExpressedInterestEvents] = useState<string[]>([]);

  // Add state for snackbar severity
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');

  useEffect(() => {
    const fetchEvents = async () => {
      if (!userData?.serviceCategory) {
        setError('Категория услуг не указана в профиле');
        setLoading(false);
        return;
      }

      try {
        const fetchedEvents = await getPerformerEvents(userData.serviceCategory as ServiceType);
        setEvents(fetchedEvents);
        setFilteredEvents(fetchedEvents);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Ошибка при загрузке мероприятий');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [userData]);

  // Fetch user's existing negotiations at component mount to identify events they've already expressed interest in
  useEffect(() => {
    const fetchExistingNegotiations = async () => {
      if (!userData?.uid) return;
      
      try {
        const negotiations = await getPerformerNegotiations(userData.uid);
        
        // Extract event IDs from negotiations where the performer has expressed interest
        const expressedInterestEventIds = negotiations
          .filter(n => n.createdBy === 'performer')
          .map(n => n.eventId);
        
        // Set these event IDs in the state
        setExpressedInterestEvents(expressedInterestEventIds);
      } catch (error) {
        console.error('Error fetching existing negotiations:', error);
      }
    };
    
    fetchExistingNegotiations();
  }, [userData]);

  // Apply filters to events
  const applyFilters = () => {
    let filtered = [...events];

    // Filter by search query (title or description)
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(event => 
        event.title.toLowerCase().includes(query) ||
        event.description.toLowerCase().includes(query)
      );
    }

    // Filter by event type
    if (eventType) {
      filtered = filtered.filter(event => event.eventType === eventType);
    }

    // Filter by location
    if (location) {
      filtered = filtered.filter(event => 
        event.location.toLowerCase().includes(location.toLowerCase())
      );
    }

    // Filter by budget
    filtered = filtered.filter(event => {
      const relevantService = event.services.find(
        service => service.type === userData?.serviceCategory
      );
      if (!relevantService) return false;
      return relevantService.budget >= minBudget && relevantService.budget <= maxBudget;
    });

    setFilteredEvents(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [searchQuery, eventType, location, minBudget, maxBudget]);

  // Handle filter changes
  const handleEventTypeChange = (event: SelectChangeEvent) => {
    setEventType(event.target.value as EventType | '');
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const resetFilters = () => {
    setEventType('');
    setLocation('');
    setMinBudget(0);
    setMaxBudget(500000);
    setSearchQuery('');
  };

  // Handle details dialog
  const openDetails = (event: Event) => {
    setSelectedEvent(event);
    setDetailsOpen(true);
  };

  const closeDetails = () => {
    setDetailsOpen(false);
  };

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  // Format date for display
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Handle express interest
  const handleExpressInterest = async (event: Event) => {
    if (!userData?.uid) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Необходимо авторизоваться');
      setSnackbarOpen(true);
      return;
    }

    try {
      const serviceType = userData.serviceCategory as ServiceType;
      const service = event.services.find(s => s.type === serviceType);
      
      if (!service) {
        setSnackbarSeverity('warning');
        setSnackbarMessage('Соответствующая услуга не найдена');
        setSnackbarOpen(true);
        return;
      }
      
      await expressInterest(
        event.id as string, 
        userData.uid, 
        serviceType
      );
      
      // Update local state to reflect expressed interest
      setExpressedInterestEvents(prev => [...prev, event.id as string]);
      
      setSnackbarSeverity('success');
      setSnackbarMessage('Вы успешно откликнулись на это мероприятие');
      setSnackbarOpen(true);
      closeDetails();
    } catch (error) {
      console.error('Error expressing interest:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Ошибка при отклике на мероприятие');
      setSnackbarOpen(true);
    }
  };
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '100vh', py: 4, backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <IconButton onClick={handleBackToDashboard} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1">
            Доступные мероприятия
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 4 }}>
            {error}
          </Alert>
        )}

        <Paper sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Поиск"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <SearchIcon color="action" sx={{ mr: 1 }} />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>Тип мероприятия</InputLabel>
                <Select
                  value={eventType}
                  onChange={handleEventTypeChange}
                  label="Тип мероприятия"
                >
                  <MenuItem value="">Все</MenuItem>
                  {Object.entries(eventTypeLabels).map(([value, label]) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Город"
                value={location}
                onChange={handleLocationChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                startIcon={<FilterAltOffIcon />}
                onClick={resetFilters}
                variant="outlined"
              >
                Сбросить
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Box sx={{ mb: 4 }}>
          {filteredEvents.length === 0 ? (
            <Alert severity="info">
              Мероприятий, соответствующих вашим критериям, не найдено
            </Alert>
          ) : (
            <Grid container spacing={3}>
              {filteredEvents.map((event) => (
                <Grid item xs={12} md={6} key={event.id}>
                  <Card 
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 6,
                      }
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h5" gutterBottom>
                        {event.title}
                      </Typography>
                      
                      <Box sx={{ mb: 2 }}>
                        <Chip 
                          label={eventTypeLabels[event.eventType]} 
                          size="small" 
                          color="primary" 
                        />
                      </Box>

                      <Typography variant="body2" color="text.secondary" paragraph>
                        {event.description}
                      </Typography>

                      <List dense>
                        <ListItem disablePadding>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <EventIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={formatDate(event.date)} />
                        </ListItem>
                        
                        <ListItem disablePadding>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <AccessTimeIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={event.time} />
                        </ListItem>

                        <ListItem disablePadding>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <LocationOnIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={event.location} />
                        </ListItem>

                        {event.attendees && (
                          <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                              <PeopleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={`${event.attendees} гостей`} />
                          </ListItem>
                        )}

                        <ListItem disablePadding>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <AttachMoneyIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText 
                            primary={`Бюджет: ${event.services.find(
                              s => s.type === userData?.serviceCategory
                            )?.budget.toLocaleString()} ₽`} 
                          />
                        </ListItem>
                      </List>
                    </CardContent>

                    <CardActions>
                      <Button 
                        size="small" 
                        onClick={() => openDetails(event)}
                      >
                        Подробная информация
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>

      {/* Event Details Dialog */}
      <Dialog 
        open={detailsOpen} 
        onClose={closeDetails}
        maxWidth="md"
        fullWidth
      >
        {selectedEvent && (
          <>
            <DialogTitle>
              <Typography variant="h5" fontWeight={600}>
                {selectedEvent.title}
              </Typography>
              <Chip 
                label={eventTypeLabels[selectedEvent.eventType]} 
                size="small" 
                color="primary" 
                sx={{ mt: 1 }} 
              />
            </DialogTitle>
            
            <DialogContent dividers>
              <Typography variant="body1" paragraph>
                {selectedEvent.description}
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Детали мероприятия
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <EventIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Дата"
                        secondary={formatDate(selectedEvent.date)}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <AccessTimeIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Время"
                        secondary={selectedEvent.time}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>
                      <ListItemText 
                        primary="Место проведения"
                        secondary={selectedEvent.location}
                      />
                    </ListItem>
                    {selectedEvent.attendees && (
                      <ListItem>
                        <ListItemIcon>
                          <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText 
                          primary="Количество гостей"
                          secondary={selectedEvent.attendees}
                        />
                      </ListItem>
                    )}
                  </List>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Требования к услуге
                  </Typography>
                  {selectedEvent.services
                    .filter(service => service.type === userData?.serviceCategory)
                    .map((service, index) => (
                      <Box key={index}>
                        <Typography variant="body1" paragraph>
                          {service.description}
                        </Typography>
                        <Typography variant="h6" color="primary">
                          Бюджет: {service.budget.toLocaleString()} ₽
                        </Typography>
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </DialogContent>
            
            <DialogActions>
              <Button onClick={closeDetails}>Закрыть</Button>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleExpressInterest(selectedEvent)}
                disabled={expressedInterestEvents.includes(selectedEvent.id as string)}
              >
                {expressedInterestEvents.includes(selectedEvent.id as string)
                  ? 'Вы уже откликнулись'
                  : 'Откликнуться'}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PerformerOrders; 