import React from 'react';
import { Box, Container, Typography, Button, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NotFound: React.FC = () => {
  const theme = useTheme();
  
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default
      }}
    >
      <Container maxWidth="md" sx={{ textAlign: 'center', py: 8 }}>
        <ErrorOutlineIcon sx={{ fontSize: 100, color: theme.palette.primary.main, mb: 4 }} />
        
        <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
          404
        </Typography>
        
        <Typography variant="h4" component="h2" gutterBottom>
          Страница не найдена
        </Typography>
        
        <Typography variant="body1" color="textSecondary" sx={{ mb: 4, maxWidth: 500, mx: 'auto' }}>
          Извините, страница, которую вы ищете, не существует или была перемещена.
        </Typography>
        
        <Button 
          component={Link} 
          to="/" 
          variant="contained" 
          size="large"
          sx={{ 
            py: 1.5, 
            px: 4,
            mt: 2
          }}
        >
          Вернуться на главную
        </Button>
      </Container>
    </Box>
  );
};

export default NotFound; 