import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  User
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

export type UserType = 'organizer' | 'performer';

export interface UserData {
  uid: string;
  email: string;
  userType: UserType;
  profileCompleted: boolean;
  createdAt: string;
  // Fields for both organizer and performer
  fullName?: string;
  phone?: string;
  profileImageUrl?: string;
  aboutMe?: string;
  location?: string;
  website?: string;
  // Fields specific to performers
  serviceCategory?: string;
  priceRange?: string;
  skills?: string[];
  portfolioLinks?: string[];
  certificateUrls?: string[];
  // Fields specific to organizers
  companyName?: string;
}

// Регистрация нового пользователя
export const registerUser = async (email: string, password: string, userType: UserType): Promise<UserData> => {
  try {
    console.log(`Registering user with email: ${email}, type: ${userType}`);
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    
    // Создаем запись пользователя в Firestore
    const userData: UserData = {
      uid: user.uid,
      email: user.email || email,
      userType: userType, // Ensure userType is explicitly set
      profileCompleted: false,
      createdAt: new Date().toISOString()
    };
    
    console.log("Saving user data to Firestore:", userData);
    await setDoc(doc(db, 'users', user.uid), userData);
    
    return userData;
  } catch (error: any) {
    console.error("Error registering user:", error);
    throw new Error(error.message);
  }
};

// Вход пользователя
export const signIn = async (email: string, password: string): Promise<User> => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Выход пользователя
export const signOut = async (): Promise<void> => {
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Получение данных пользователя из Firestore
export const getUserData = async (uid: string): Promise<UserData | null> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      return userDoc.data() as UserData;
    }
    return null;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Обновление данных профиля пользователя
export const updateUserProfile = async (uid: string, profileData: any): Promise<void> => {
  try {
    const userRef = doc(db, 'users', uid);
    await setDoc(userRef, { ...profileData, profileCompleted: true }, { merge: true });
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Установка слушателя для изменения состояния аутентификации
export const onAuthStateChange = (callback: (user: User | null) => void): (() => void) => {
  return onAuthStateChanged(auth, callback);
};

// Fetch all performers with optional filtering
export const getAllPerformers = async (filters?: {
  serviceCategory?: string;
  location?: string;
  priceRange?: {min: number, max: number};
}): Promise<UserData[]> => {
  try {
    // Start with base query for performers
    let performersQuery = query(
      collection(db, 'users'),
      where('userType', '==', 'performer')
    );
    
    // Execute the query
    const querySnapshot = await getDocs(performersQuery);
    let performers: UserData[] = [];
    
    // Get all performers from the snapshot
    querySnapshot.forEach((doc) => {
      performers.push({ ...doc.data(), uid: doc.id } as UserData);
    });
    
    // Apply filters in memory (we do this because Firestore has limitations on complex queries)
    if (filters) {
      performers = performers.filter(performer => {
        // Filter by service category if specified
        if (filters.serviceCategory && performer.serviceCategory !== filters.serviceCategory) {
          return false;
        }
        
        // Filter by location if specified (simple contains match)
        if (filters.location && performer.location && 
            !performer.location.toLowerCase().includes(filters.location.toLowerCase())) {
          return false;
        }
        
        // Filter by price range if specified
        if (filters.priceRange && performer.priceRange) {
          const [minStr, maxStr] = performer.priceRange.split('-');
          const performerMin = parseInt(minStr, 10);
          const performerMax = parseInt(maxStr, 10);
          
          if (!isNaN(performerMin) && !isNaN(performerMax)) {
            // Check if price ranges overlap
            if (performerMax < filters.priceRange.min || performerMin > filters.priceRange.max) {
              return false;
            }
          }
        }
        
        return true;
      });
    }
    
    return performers;
  } catch (error: any) {
    console.error("Error fetching performers:", error);
    throw new Error(error.message);
  }
}; 