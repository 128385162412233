import { UserData } from '../services/auth';

// Sample performers for the "music" category (DJ/musicians)
export const musicPerformers: Omit<UserData, 'uid'>[] = [
  {
    email: "dj.alexey@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Алексей Петров",
    phone: "+7 (905) 123-4567",
    profileImageUrl: "https://images.unsplash.com/photo-1499364615650-ec38552f4f34?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный DJ с 10-летним опытом работы. Специализируюсь на электронной и поп-музыке. Имею собственное оборудование высокого класса.",
    location: "Москва",
    website: "https://dj-alexey.ru",
    serviceCategory: "music",
    priceRange: "30000-50000",
    skills: ["DJ", "Электронная музыка", "Поп-музыка", "Проведение мероприятий", "Работа со звуком"],
    portfolioLinks: [
      "https://soundcloud.com/dj-alexey",
      "https://www.instagram.com/dj.alexey"
    ],
    certificateUrls: [
      "https://example.com/certificates/dj-school-certificate.jpg"
    ]
  },
  {
    email: "marina.sound@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Марина Звукова",
    phone: "+7 (916) 234-5678",
    profileImageUrl: "https://images.unsplash.com/photo-1605722243979-fe0be8cbb4b9?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Виртуозная скрипачка и DJ. Сочетаю классическую скрипку с электронной музыкой для создания незабываемой атмосферы на вашем мероприятии.",
    location: "Санкт-Петербург",
    website: "https://marina-sound.ru",
    serviceCategory: "music",
    priceRange: "40000-70000",
    skills: ["Скрипка", "DJ", "Live выступления", "Электронная музыка", "Классическая музыка"],
    portfolioLinks: [
      "https://www.youtube.com/channel/marina-sound",
      "https://vk.com/marina.sound"
    ],
    certificateUrls: [
      "https://example.com/certificates/conservatory-diploma.jpg"
    ]
  },
  {
    email: "rock.band@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Rock Wave Band",
    phone: "+7 (925) 345-6789",
    profileImageUrl: "https://images.unsplash.com/photo-1598387993281-cecf8b71a8f8?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Энергичная рок-группа из 5 человек. Исполняем как популярные хиты, так и авторские композиции. Имеем опыт выступлений на корпоративах, свадьбах и фестивалях.",
    location: "Москва",
    website: "https://rockwave.ru",
    serviceCategory: "music",
    priceRange: "70000-120000",
    skills: ["Живая музыка", "Рок", "Поп-рок", "Кавер-группа", "Авторские песни"],
    portfolioLinks: [
      "https://www.youtube.com/channel/rockwave",
      "https://vk.com/rockwave"
    ],
    certificateUrls: []
  },
  {
    email: "jazz.trio@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Jazz Time Trio",
    phone: "+7 (903) 456-7890",
    profileImageUrl: "https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Джазовое трио (пианино, контрабас, ударные). Создаем элегантную атмосферу на мероприятиях высокого класса. Специализируемся на классическом джазе и лаунж-музыке.",
    location: "Санкт-Петербург",
    website: "https://jazztime.ru",
    serviceCategory: "music",
    priceRange: "60000-90000",
    skills: ["Джаз", "Лаунж", "Живая музыка", "Инструментальная музыка", "Фоновая музыка"],
    portfolioLinks: [
      "https://soundcloud.com/jazztime",
      "https://www.instagram.com/jazztimetrio"
    ],
    certificateUrls: []
  },
  {
    email: "dj.mix@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Дмитрий Миксов",
    phone: "+7 (915) 567-8901",
    profileImageUrl: "https://images.unsplash.com/photo-1571266028271-345464e44e47?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "DJ с опытом работы более 15 лет. Работаю в различных жанрах от хауса до хип-хопа. Гарантирую качественный звук и зажигательную атмосферу.",
    location: "Москва",
    website: "https://djmix.ru",
    serviceCategory: "music",
    priceRange: "35000-60000",
    skills: ["DJ", "Хаус", "Техно", "Хип-хоп", "R&B", "Поп-музыка"],
    portfolioLinks: [
      "https://mixcloud.com/djmix",
      "https://vk.com/dj.mix"
    ],
    certificateUrls: [
      "https://example.com/certificates/professional-dj.jpg"
    ]
  },
  {
    email: "folk.ensemble@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Ансамбль 'Русская Душа'",
    phone: "+7 (926) 678-9012",
    profileImageUrl: "https://images.unsplash.com/photo-1528489496900-d841974f5290?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Фольклорный ансамбль из 7 человек. Исполняем традиционные русские песни и танцы. Выступаем в аутентичных костюмах. Идеальный выбор для тематических мероприятий.",
    location: "Москва",
    website: "https://russoul.ru",
    serviceCategory: "music",
    priceRange: "50000-100000",
    skills: ["Народная музыка", "Фольклор", "Живая музыка", "Хоровое пение", "Народные инструменты"],
    portfolioLinks: [
      "https://www.youtube.com/channel/russoul",
      "https://www.instagram.com/russoul"
    ],
    certificateUrls: []
  },
  {
    email: "violin.duo@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Дуэт 'Струны Сердца'",
    phone: "+7 (917) 789-0123",
    profileImageUrl: "https://images.unsplash.com/photo-1608057192060-a698b488f142?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Дуэт скрипачей с высшим музыкальным образованием. Исполняем классическую музыку и современные хиты в классической обработке. Создаем романтическую атмосферу.",
    location: "Санкт-Петербург",
    website: "https://heart-strings.ru",
    serviceCategory: "music",
    priceRange: "25000-45000",
    skills: ["Скрипка", "Классическая музыка", "Современная классика", "Дуэт", "Фоновая музыка"],
    portfolioLinks: [
      "https://soundcloud.com/heart-strings",
      "https://vk.com/heart.strings"
    ],
    certificateUrls: [
      "https://example.com/certificates/conservatory-diploma1.jpg",
      "https://example.com/certificates/conservatory-diploma2.jpg"
    ]
  },
  {
    email: "saxophone.player@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Игорь Саксонов",
    phone: "+7 (919) 890-1234",
    profileImageUrl: "https://images.unsplash.com/photo-1541689592655-f5f52825a3b8?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональный саксофонист с опытом выступлений на международных площадках. Играю джаз, соул, фанк и поп-музыку. Возможно сольное выступление или в сопровождении DJ.",
    location: "Москва",
    website: "https://igor-sax.ru",
    serviceCategory: "music",
    priceRange: "30000-50000",
    skills: ["Саксофон", "Джаз", "Соул", "Фанк", "Поп-музыка", "Сольное выступление"],
    portfolioLinks: [
      "https://www.youtube.com/channel/igor-sax",
      "https://www.instagram.com/igor.sax"
    ],
    certificateUrls: [
      "https://example.com/certificates/music-academy.jpg"
    ]
  },
  {
    email: "piano.master@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Елена Роялева",
    phone: "+7 (920) 901-2345",
    profileImageUrl: "https://images.unsplash.com/photo-1520523839897-bd0b52f945a0?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Пианистка с классическим образованием и 20-летним опытом. Исполняю классику, джаз и современные композиции. Создаю элегантную атмосферу на мероприятиях любого формата.",
    location: "Москва",
    website: "https://elena-piano.ru",
    serviceCategory: "music",
    priceRange: "25000-45000",
    skills: ["Фортепиано", "Классическая музыка", "Джаз", "Фоновая музыка", "Сольное выступление"],
    portfolioLinks: [
      "https://soundcloud.com/elena-piano",
      "https://www.instagram.com/elena.piano"
    ],
    certificateUrls: [
      "https://example.com/certificates/piano-master.jpg"
    ]
  },
  {
    email: "singer@example.com",
    userType: "performer",
    profileCompleted: true,
    createdAt: new Date().toISOString(),
    fullName: "Анна Голосова",
    phone: "+7 (921) 012-3456",
    profileImageUrl: "https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    aboutMe: "Профессиональная вокалистка с уникальным голосом. Исполняю песни на русском и английском языках в различных жанрах. Могу выступать сольно или с музыкантами.",
    location: "Санкт-Петербург",
    website: "https://anna-voice.ru",
    serviceCategory: "music",
    priceRange: "30000-60000",
    skills: ["Вокал", "Джаз", "Поп-музыка", "Соул", "Лирические песни", "Мультиязычный репертуар"],
    portfolioLinks: [
      "https://www.youtube.com/channel/anna-voice",
      "https://vk.com/anna.voice"
    ],
    certificateUrls: [
      "https://example.com/certificates/vocal-academy.jpg"
    ]
  }
]; 