import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Divider,
  CircularProgress,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  useTheme,
  alpha
} from '@mui/material';
import { RecommendedPerformer } from '../../services/recommendation';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PortraitIcon from '@mui/icons-material/Portrait';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

// Props interface for the component
interface PerformerRecommendationsProps {
  recommendations: RecommendedPerformer[];
  serviceType: string;
  onSelectPerformer: (performer: RecommendedPerformer) => void;
  loading: boolean;
}

// Map of service types to readable Russian names
const serviceTypeLabels: Record<string, string> = {
  'venue': 'Площадки и помещения',
  'catering': 'Кейтеринг и обслуживание',
  'photography': 'Фото',
  'videography': 'Видеосъемка',
  'music': 'Музыка/DJ',
  'decoration': 'Декор и оформление',
  'entertainment': 'Ведущие и аниматоры',
  'transport': 'Транспорт',
  'technical': 'Техническое обеспечение',
  'other': 'Другое'
};

const PerformerRecommendations: React.FC<PerformerRecommendationsProps> = ({
  recommendations,
  serviceType,
  onSelectPerformer,
  loading
}) => {
  const theme = useTheme();
  const serviceLabel = serviceTypeLabels[serviceType] || serviceType;
  
  // State for performer details modal
  const [selectedPerformer, setSelectedPerformer] = useState<RecommendedPerformer | null>(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  
  // Handle details dialog
  const openDetails = (performer: RecommendedPerformer) => {
    setSelectedPerformer(performer);
    setDetailsOpen(true);
  };
  
  const closeDetails = () => {
    setDetailsOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <CircularProgress color="primary" />
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
          Подбираем для вас лучших исполнителей...
        </Typography>
      </Box>
    );
  }

  if (!recommendations || recommendations.length === 0) {
    return (
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          textAlign: 'center',
          border: '1px dashed',
          borderColor: 'divider',
          borderRadius: 2,
          bgcolor: alpha(theme.palette.primary.main, 0.05)
        }}
      >
        <NoPhotographyIcon sx={{ fontSize: 40, color: 'text.secondary', mb: 1 }} />
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Нет рекомендаций для {serviceLabel.toLowerCase()}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          К сожалению, мы не смогли найти подходящих исполнителей для данной услуги.
          Попробуйте изменить параметры поиска или проверить позже.
        </Typography>
      </Paper>
    );
  }

  // Format price for display
  const formatPrice = (price: string | undefined) => {
    if (!price) return 'Цена не указана';
    return `${price} ₽`;
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom sx={{ 
        display: 'flex', 
        alignItems: 'center'
      }}>
        Рекомендации для услуги: {serviceLabel}
        <Chip 
          label={`${recommendations.length}`} 
          color="primary" 
          size="small" 
          sx={{ ml: 1 }} 
        />
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        Предлагаем вам исполнителей, подобранных по критериям вашего мероприятия
      </Typography>

      <List sx={{ width: '100%' }}>
        {recommendations.map((performer, index) => (
          <React.Fragment key={performer.uid || index}>
            <Card 
              sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                mb: 2,
                overflow: 'hidden',
                borderRadius: 2,
                border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                '&:hover': {
                  boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`
                },
                transition: 'all 0.2s ease-in-out'
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: { xs: '100%', sm: 150 },
                  height: { xs: 140, sm: 150 },
                  objectFit: 'cover'
                }}
                image={performer.profileImageUrl || 'https://via.placeholder.com/150?text=No+Image'}
                alt={performer.fullName || 'Исполнитель'}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <CardContent sx={{ 
                  flex: '1 0 auto', 
                  pb: 1,
                  // Fix width issues
                  width: '100%',
                  boxSizing: 'border-box'
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'flex-start',
                    flexWrap: 'wrap'
                  }}>
                    <Box sx={{ mr: 1, flexShrink: 1, minWidth: '40%' }}>
                      <Typography component="h3" variant="h6" sx={{ wordBreak: 'break-word' }}>
                        {performer.fullName || 'Имя не указано'}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        {performer.priceRange ? `${performer.priceRange} ₽` : 'Цена не указана'}
                      </Typography>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'flex-end',
                      flexShrink: 0
                    }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          bgcolor: alpha(theme.palette.success.main, 0.1),
                          color: 'success.main',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        }}
                      >
                        <Typography component="span" variant="caption" fontWeight="bold">
                          Совпадение: {Math.round((performer.matchScore || 0) * 100)}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    gap: 0.5, 
                    my: 1,
                    maxWidth: '100%'
                  }}>
                    {performer.reasons && performer.reasons.map((reason, i) => (
                      <Chip
                        key={i}
                        label={reason}
                        size="small"
                        icon={
                          reason.includes('бюджету') ? <PriceCheckIcon /> :
                          reason.includes('регионе') ? <LocationOnIcon /> :
                          reason.includes('навыками') ? <CheckCircleIcon /> :
                          <PortraitIcon />
                        }
                        variant="outlined"
                        sx={{ fontSize: '0.75rem' }}
                      />
                    ))}
                  </Box>

                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    noWrap
                    sx={{ maxWidth: '100%' }}
                  >
                    {performer.aboutMe 
                      ? (performer.aboutMe.length > 100 
                          ? `${performer.aboutMe.slice(0, 100)}...` 
                          : performer.aboutMe) 
                      : 'Информация отсутствует'}
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 2 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => onSelectPerformer(performer)}
                    sx={{ mr: 1 }}
                  >
                    Выбрать
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<InfoIcon />}
                    onClick={() => openDetails(performer)}
                  >
                    Подробная информация
                  </Button>
                </Box>
              </Box>
            </Card>
            {index < recommendations.length - 1 && <Divider sx={{ my: 1 }} />}
          </React.Fragment>
        ))}
      </List>
      
      {/* Performer Details Dialog */}
      <Dialog 
        open={detailsOpen} 
        onClose={closeDetails}
        maxWidth="md"
        fullWidth
      >
        {selectedPerformer && (
          <>
            <DialogTitle>
              <Typography variant="h5" fontWeight={600}>
                {selectedPerformer.fullName || 'Исполнитель'}
              </Typography>
              <Chip 
                label={serviceTypeLabels[selectedPerformer.serviceCategory as keyof typeof serviceTypeLabels] || selectedPerformer.serviceCategory || 'Категория не указана'} 
                size="small" 
                color="primary" 
                sx={{ mt: 1 }} 
              />
            </DialogTitle>
            
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <img 
                    src={selectedPerformer.profileImageUrl || 'https://via.placeholder.com/300x300?text=No+Image'} 
                    alt={selectedPerformer.fullName || 'Исполнитель'} 
                    style={{ 
                      width: '100%', 
                      borderRadius: '8px', 
                      maxHeight: '300px',
                      objectFit: 'cover' 
                    }} 
                  />
                  
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" color="primary" gutterBottom>
                      {formatPrice(selectedPerformer.priceRange)}
                    </Typography>
                    
                    <List dense disablePadding>
                      {selectedPerformer.phone && (
                        <ListItem disablePadding sx={{ mt: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <LocalPhoneIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={selectedPerformer.phone} />
                        </ListItem>
                      )}
                      
                      {selectedPerformer.email && (
                        <ListItem disablePadding sx={{ mt: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <EmailIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={selectedPerformer.email} />
                        </ListItem>
                      )}
                      
                      {selectedPerformer.location && (
                        <ListItem disablePadding sx={{ mt: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <LocationOnIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={selectedPerformer.location} />
                        </ListItem>
                      )}
                      
                      {selectedPerformer.website && (
                        <ListItem disablePadding sx={{ mt: 1 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <LanguageIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText 
                            primary={
                              <a 
                                href={selectedPerformer.website} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: theme.palette.primary.main }}
                              >
                                {selectedPerformer.website}
                              </a>
                            } 
                          />
                        </ListItem>
                      )}
                    </List>
                  </Box>
                </Grid>
                
                <Grid item xs={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    О исполнителе
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {selectedPerformer.aboutMe || 'Информация отсутствует'}
                  </Typography>
                  
                  {selectedPerformer.skills && selectedPerformer.skills.length > 0 && (
                    <>
                      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Навыки
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selectedPerformer.skills.map((skill, index) => (
                          <Chip key={index} label={skill} size="small" />
                        ))}
                      </Box>
                    </>
                  )}
                  
                  {selectedPerformer.portfolioLinks && selectedPerformer.portfolioLinks.length > 0 && (
                    <>
                      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Портфолио
                      </Typography>
                      <List dense>
                        {selectedPerformer.portfolioLinks.map((link, index) => (
                          <ListItem key={index} disablePadding>
                            <Button 
                              href={link} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              style={{ textTransform: 'none' }}
                            >
                              {link}
                            </Button>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                  
                  {selectedPerformer.certificateUrls && selectedPerformer.certificateUrls.length > 0 && (
                    <>
                      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Сертификаты
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {selectedPerformer.certificateUrls.map((url, index) => (
                          <a 
                            key={index} 
                            href={url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                          >
                            <img 
                              src={url} 
                              alt={`Сертификат ${index + 1}`}
                              style={{ 
                                width: '100px', 
                                height: '75px', 
                                objectFit: 'cover',
                                borderRadius: '4px'
                              }} 
                            />
                          </a>
                        ))}
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            
            <DialogActions>
              <Button onClick={closeDetails}>Закрыть</Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => {
                  onSelectPerformer(selectedPerformer);
                  closeDetails();
                }}
              >
                Выбрать исполнителя
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default PerformerRecommendations; 